import React, { useContext } from 'react';
import { Button, Grid } from '../../../styled';
import {
    DescriptionOutlined,
    HeadsetMicOutlined,
    InfoOutlined,
    InsertDriveFileOutlined
} from '@mui/icons-material';
import { DriverRecordsContext } from '../../../hooks';
import { get } from 'lodash';

const buttonStyle = { marginBottom: '8px', fontWeight: '600', fontSize: '16px', lineHeight: '12px', color: '#FFFFFF', textAlign: 'start', position: 'relative' };
const lineComponent = () => <div item xs={2} style={{ position: 'absolute', top: 5, left: 30, borderLeft: '2px solid #fff', height: '20px' }} />


function RowContent() {
    const buttons = [
        { id: 1, label: 'INFO / DO', icon: <InfoOutlined />, type: 'info' },
        { id: 2, label: 'DOCUMENTS', icon: <DescriptionOutlined />, type: 'docs' },
        // { id: 4, label: 'ASK ?', icon: <HeadsetMicOutlined />, type: 'ask' }
    ];

    if(localStorage.getItem("role") === "customer") {
        buttons.push({ id: 3, label: 'BILLING', icon: <InsertDriveFileOutlined />, type: 'bill' })
    }

    const {
        handleOpenDialog,
        activeButtons,
        handleActiveButtons,
        open,
    } = useContext(DriverRecordsContext);

    
    const hasInvoices = (get(open.invoice, "prefix") === "INV" || get(open.invoice, "prefix") === "BES");
    const hasCP = (get(open, "consignmentNotes", []).length > 0);

    const disabled = hasInvoices || hasCP;

    return (
        <Grid container style={{ alignContent: 'start', height: 150}}>
            {buttons.map(element => <Grid item xs={11} key={element.id}>
                <Button 
                    bgColor={!disabled && element.id === 3 ? "gray": null} 
                    style={buttonStyle}  
                    disabled={!disabled && element.id === 3 ? true : false}
                    onClick={()=>handleOpenDialog(element.type)}
                >
                    <Grid container justifyContent="flex-start">
                        <Grid item xs={2}>
                            {element.icon}
                        </Grid>
                        {lineComponent()}
                        <Grid className="buttonText" item xs style={{ alignSelf: 'center', paddingLeft: '10px' }}>
                            {element.label}
                        </Grid>
                    </Grid>
                </Button>
            </Grid>)}
        </Grid>
    );
}

export default RowContent;