import { Grid } from '@mui/material';
import React from 'react';
import { helperGetFileData } from './hooks/helpers';
import ImageZoomScroll from './image';
import { get } from 'lodash';

function FileViewer({ height, file, index, url }) {
    // FOR NOW IT JUST TAKES INDEX TO DETERMINE WETHER TO SHOW AN IMAGE OR PDF VIEW
    // const { type, url } = helperGetFileData(index);

    if (url && url.endsWith('.pdf')) {
        
        return (
            <Grid container>
                <embed src={url} type="application/pdf" width="800px" height="400px" />
            </Grid>
        );
    }
    
    return(
        <ImageZoomScroll 
            alt=""
            src={url}
        />
    )
}

export default FileViewer;