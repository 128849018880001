import React from 'react';
import {
    Grid,
} from "@mui/material";
import { TextBoxDoc2, IconArchivo1, IconArchivo2, IconButton, TabButton } from '../../../styled';
import FileViewer from '../../../../../../../../components/FileViewer';

function Row({
    element,
    index,
    fileOpen,
    handleFileOpen,
    handleFileClose }) {
    console.log(element);
    return (<Grid item >
        <Grid container spacing={1} style={{ width: '810px' }} alignItems={'flex-end'}>
            <Grid item xs={3}>
                <Grid container alignItems={'end'}>
                    <Grid item>
                        {element.icon}
                    </Grid>
                    <Grid item xs>
                        <TextBoxDoc2
                            first={index === 0}
                            label={index === 0 && "NAME:"}
                            defaultValue={element.name}
                            fullWidth
                            InputProps={{ readOnly: true }}
                        />
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs>
                <Grid container alignItems={'end'} spacing={1}>
                    <Grid item xs={9}>
                        <TextBoxDoc2
                            first={index === 0}
                            label={index === 0 && "DRIVER MOVE:"}
                            defaultValue={element.driver_move}
                            fullWidth
                            InputProps={{ readOnly: true }}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <TextBoxDoc2
                            first={index === 0}
                            label={index === 0 && "DATE:"}
                            defaultValue={element.date}
                            fullWidth
                            InputProps={{ readOnly: true }}
                        />
                    </Grid>
                </Grid>
            </Grid>
            <Grid item style={{ width: '35px' }}>
                <Grid container justifyContent={'space-between'} alignItems={'flex-end'}>
                    {/*<Grid item>
                        <IconButton>
                            <IconArchivo1 />
                        </IconButton>
                    </Grid>*/}
                    <Grid item>
                        <IconButton
                            disabled={element.status !== "VALIDATED"}
                            onClick={() => handleFileOpen('file', index)}>
                            <IconArchivo2 style={{color: element.status !== "VALIDATED" ? "#898989" : "#026DF2"}}/>
                        </IconButton>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
        {fileOpen.index === index && <Grid container spacing={1} style={{ width: '810px' }} alignItems={'flex-end'} justifyContent={'center'}>
            <Grid item xs={12}>
                <Grid
                    container
                    justifyContent={'space-between'}
                    style={{ marginTop: '10px', borderTop: '1px solid #AFAFAF' }}
                >
                    <Grid item>
                        <Grid container justifyContent={'start'}>
                            <Grid item>
                                <TabButton
                                    active={fileOpen.view === 'file'}
                                    onClick={() => handleFileOpen('file')}
                                >
                                    FILE VIEWER
                                </TabButton>
                            </Grid>
                            {/*<Grid item>
                                <TabButton
                                    active={fileOpen.view === 'note'}
                                    onClick={() => handleFileOpen('note')}
                                >
                                    NOTES
                                </TabButton>
                            </Grid>*/}
                        </Grid>
                    </Grid>
                    <Grid item>
                    </Grid>
                </Grid>
            </Grid>
            {fileOpen.view === 'file' && <Grid container spacing={1} style={{padding: '0 9px'}}>
                <Grid item >
                    <FileViewer index={index} url={`https://storage.googleapis.com/${element.url}`} />
                </Grid>
            </Grid>}
            <Grid item style={{ paddingBottom: '5px', borderBottom: '1px solid #AFAFAF', width: '810px', marginLeft: '10px' }}/>
        </Grid>}
    </Grid>);
}

export default Row;