import { useState, createContext, useEffect } from 'react';
import { useQuery } from '@apollo/client';
// import { GET_LIST_DATA_RECORDS } from '../../../../graphql/queries/drivers';
import { helperMapData, helperWhereClause } from './helpers';
import { get } from 'lodash';

// CREATE WHOLE COMPONENT STATE
const DriverRecordsContext = createContext();

function DriverRecordsProvider(props) {
 
  const [openSettings, setOpenSettings] = useState(false);

  const role = localStorage.getItem('role')

  // HEADER FILTER COLUMNS
  const [searchArgs, setsearchArgs] = useState({
    reference: '',
    status: '',
    container: '',
    type: '',
    moveType: '',
    routeStatus: '',
    consignee: '',
    te: '',
    invoiceNumber: '',
    invoiceDate: '',
    invoiceTotal: '',
    dueDate: '',
    from: '',
    to: '',
    size: '',
    lfd: '',
    emptyNotify: '',
    inbondVia: '',
    mbl: '',
    apptDate: '',
    apptNumber: '',
    driver: '',
    plate: '',
    folio: '',
    scale: '',
    eta: '',
    days: '',
    remarks: '',
  });

  const [columns, setColumns] = useState({
    reference: true,
    status: true,
    container: true,
    type: true,
    moveType: true,
    routeStatus: true,
    consignee: role === "consignee" ? false :true,
    te: false,
    from: role === "consignee" ? true : false,
    to: role === "consignee" ? true : false,
    size: false,
    lfd: false,
    emptyNotify: false,
    inbondVia: false,
    mbl: false,
    apptDate: false,
    apptNumber: false,
    driver: false,
    plate: false,
    folio: false,
    scale: false,
    eta: false,
    days: false,
    remarks: false,
  });

  const handleColumnsArgs = (data, label) => {
    setoffset(0);
    const aux = { ...columns };
    aux[label] = data;
    setColumns(aux);
  };

  const handlesearchArgs = (data, label, type) => {
    setoffset(0);
    const aux = { ...searchArgs };
    if(type === 'date'){
      aux[label] = data;
    }else{
      aux[label] = data;
    }
    setsearchArgs(aux);
  };

  // NAVIGATION
  function handleGoTo(option, id) {
    switch (option) {
      case 1:
        // history.push(`drivers/`);
        break;
      case 2:
        // history.push(`driver/${id}`);
        break;
      default:
        break;
    }
  }

  // PAGINATION
  const [offset, setoffset] = useState(0);
  const [limitSelect, setlimitSelect] = useState(12);
  const [limit, setlimit] = useState(12);
  
  // QUERY VARIABLES
  const whereClause = helperWhereClause(searchArgs);
  const variables = {
    whereClause,
    limit: limitSelect,
    offset,
  };

  const [loadingList, setLoading] = useState(true);
  const [queryList, setData] = useState([]);
  const jwt = localStorage.getItem('token')
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
    },
    body: JSON.stringify({
      jwt,
      consignee: role === 'consignee',
      where: variables,
    }),
  };

  const [timer, setTimer] = useState(null);

  const handleGetData = () => {
    fetch(
      "https://us-central1-bali-lbth.cloudfunctions.net/search_by_work_order_cp",
      // "https://us-central1-dexone.cloudfunctions.net/search_by_work_order_cp",
      requestOptions,
    )
    .then((response) => response.json())
    .then((data) => {
      setData(data.data);
      setLoading(false);
    });
  }

  useEffect(() => {
    if (timer === 0) {
      handleGetData()
    }
  }, [timer]);

  const handleInputChange = () => {
    clearTimeout(timer);
    setTimer(setTimeout(() => setTimer(0), 1000));
  }

  useEffect(() => {
    setLoading(true)
    handleInputChange()
  }, [searchArgs, offset, limitSelect, limit]);
  
  // MAP DATA
  const dataList = loadingList ? [] : helperMapData(get(queryList, "rows", []));
  const dataLength = loadingList ? 0 : get(queryList, 'count.aggregate.count', 0);

  // HANDLE ROW ON OPEN
  const [open, setOpen] = useState({id: ''});
  const handleOpen = e => setOpen(e.id === open.id ? {id: ''} : e);

  //HANDLE DIALOGS
  const [openDialog, setOpenDialog] = useState('');
  const handleOpenDialog = type => setOpenDialog(type);
  const handleCloseDialog = type => setOpenDialog('');

  const [activeButtons, setActiveButtons] = useState({
    infoDo: true,
    documents: true,
    billing: true,
    ask: true
  })

  const handleActiveButtons = (data, label) => {
    const aux = { ...activeButtons };
    
    aux[label] = data;

    setActiveButtons(aux);
  };

  return (
    <DriverRecordsContext.Provider value={{
      searchArgs,
      handlesearchArgs,
      handleGoTo,
      offset,
      setoffset,
      limit,
      setlimit,
      data: dataList,
      loading: loadingList,
      length: dataLength,
      limitSelect,
      setlimitSelect,
      open,
      handleOpen,
      openDialog,
      handleOpenDialog,
      handleCloseDialog,
      columns,
      handleColumnsArgs,
      activeButtons,
      handleActiveButtons,
      openSettings,
      setOpenSettings,
    }}>
    {props.children}
  </DriverRecordsContext.Provider>
  );
}

export {DriverRecordsContext, DriverRecordsProvider}