import { Button } from '@mui/material';
import React from 'react';

function ImageZoomScroll({ src, alt }) {
  const [scale, setScale] = React.useState(1);
  const [position, setPosition] = React.useState({ x: 0, y: 0 });
  const [dragging, setDragging] = React.useState(false);
  const [lastPosition, setLastPosition] = React.useState(null);
  const imgRef = React.useRef(null);

  const handleZoomIn = () => {
    setScale(scale => Math.min(4, scale + 0.5));
  };

  const handleZoomOut = () => {
    setScale(scale => Math.max(1, scale - 0.5));
  };

  const handleReset = () => {
    setScale(1);
  };

  const handleMouseDown = event => {
    setDragging(true);
    setLastPosition({ x: event.clientX, y: event.clientY });
  };

  const handleMouseMove = event => {
    if (!dragging || !lastPosition) {
      return;
    }
    const deltaX = event.clientX - lastPosition.x;
    const deltaY = event.clientY - lastPosition.y;
    setPosition(position => ({
      x: position.x + deltaX,
      y: position.y + deltaY
    }));
    setLastPosition({ x: event.clientX, y: event.clientY });
  };

  const handleMouseUp = () => {
    setDragging(false);
    setLastPosition(null);
  };

  const transformStyle = `scale(${scale})`;

  return (
    <div>
      <div style={{ 
        width: '800px', 
        height: '400px', 
        overflow: 'auto',
        maxWidth: '100%'
      }}>
        <img
          ref={imgRef}
          src={src}
          alt={alt}
          width={'800px'}
          style={{ 
            transform: transformStyle, 
            transformOrigin: '0 0',
            position: 'sticky',
            maxWidth: '100%'
          }}
          // onMouseDown={handleMouseDown}
          // onMouseMove={handleMouseMove}
          // onMouseUp={handleMouseUp}
        />
      </div>
      <div style={{ 
        position: 'sticky', 
      }}>
        <Button style={{ marginRight: '10px', background: '#026df2', color: '#FFFFFF' }} onClick={handleZoomIn}>Zoom In</Button>
        <Button style={{ marginRight: '10px', background: '#026df2', color: '#FFFFFF' }} onClick={handleZoomOut}>Zoom Out</Button>
        <Button style={{ background: '#026df2', color: '#FFFFFF' }} onClick={handleReset}>Reset</Button>
      </div>
    </div>
  );
}

export default ImageZoomScroll;
