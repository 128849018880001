import { useState, useEffect, useContext } from 'react';
import { DriverRecordsContext } from '../../../../hooks';
import { get } from 'lodash';

export default function useDialogHooks({ handleClose }) {
    // JALAR DATA PRINCIPAL DE LA ROW
    const {
        open,
    } = useContext(DriverRecordsContext);

    useEffect(()=> {
        if((get(open.invoice, "prefix") !== "INV" && get(open.invoice, "prefix") !== "BES")) {
            setDialogTab('1');
        } else {
            setDialogTab('0');
        }
    }, [open])

    // DIALOG TAB CONSTANTS
    const dialogTabOptions = [
        { id: '0', name: 'INVOICES' },
        { id: '1', name: 'CONSIGNMENT NOTE' },
        //{ id: '2', name: 'CREDIT NOTES' }
    ];

    // FILE NOTE REQUEST TABS
    const [openDetail, setOpenDetail] = useState(false);
    const handleOpenDetail = () => setOpenDetail(!openDetail)
    const [detailTab, setDetailTab] = useState('0');
    const handleDetailTab = value => { if (value !== detailTab) setDetailTab(value) }
    

    //HANDLE CLOSE - RESET TAB VALUES FOR NEXT OPEN
    const handleCloseDialog = () => {
        if((get(open.invoice, "prefix") !== "INV" && get(open.invoice, "prefix") !== "BES")) {
            setDialogTab('1');
        } else {
            setDialogTab('0');
        }
        setDetailTab('0');
        setOpenDetail(false);
        // USE THE HANDLE CLOSE FROM OUTSIDE DIALOG TO CLOSE THE DIALOG
        handleClose();
    };

    // DIALOG TABS
    const [dialogTab, setDialogTab] = useState('0');
    const handleDialogTab = value => {
        if (value !== dialogTab) {
            setDialogTab(value);
            setDetailTab('0');
            setOpenDetail(false);
        }
    };

    // MIMIC LOADING STATE
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        /*setLoading(true);
        const timer = setTimeout(() => {
            setLoading(false);
        }, 1500);

        return () => clearTimeout(timer);*/
    }, [dialogTab]);
    return {
        // TABS
        dialogTabOptions,
        dialogTab,
        setDialogTab,
        handleDialogTab,
        openDetail,
        handleOpenDetail,
        detailTab,
        handleDetailTab,
        handleCloseDialog,
        // DATA
        loading,
        open
    };
}