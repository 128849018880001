import { Grid } from '@mui/material';
import React from 'react';
import FileViewer from '../FileViewer';
import useHooks from './hooks';
import { TabButton } from './styled';
import { get } from 'lodash';

function FileNoteRequest({
    file = true,
    note = false,
    request = false,
    fileData,
    noteData,
    requestData,
    detailTab,
    handleDetailTab,
    invoice,
    cp
}) {
    const {
        tabOptions
    } = useHooks({ file, note, request });

    console.log()

    return (
        <Grid container spacing={1} style={{ width: '810px' }} alignItems={'flex-end'} justifyContent={'center'}>
            <Grid item xs={12}>
                <Grid
                    container
                    justifyContent={'space-between'}
                    style={{ marginTop: '10px', borderTop: '1px solid #AFAFAF' }}
                >
                    <Grid item>
                        <Grid container justifyContent={'start'}>
                            {tabOptions.map(element => <Grid item>
                                <TabButton
                                    key={element.id}
                                    active={detailTab === element.id}
                                    onClick={() => handleDetailTab(element.id)}
                                >
                                    {element.name}
                                </TabButton>
                            </Grid>)}
                        </Grid>
                    </Grid>
                    <Grid item>
                    </Grid>
                </Grid>
            </Grid>
            {detailTab === '0' && <Grid container spacing={1} style={{ padding: '0 9px' }}>
                <Grid item >
                    <FileViewer index={2} url={get(invoice, 'paper.documents[0].url')}/>
                </Grid>
            </Grid>}
            {detailTab === '1' && <Grid container spacing={1} style={{ padding: '0 9px' }}>
                <Grid item >
                    <FileViewer index={1} url={get(cp[0], 'documents[0].url')}/>
                </Grid>
            </Grid>}
            {detailTab === '2' && <Grid container spacing={1} style={{ padding: '0 9px' }}>
                <Grid item >
                    <FileViewer index={2} url={get(cp[0], 'documents[0].url')}/>
                </Grid>
            </Grid>}
        </Grid>);
}

export default FileNoteRequest;