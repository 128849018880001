import { Grid } from '@mui/material';
import React from 'react';
import { IconArchivo1, IconArchivo2, IconButton, TextBoxDoc2 } from '../../../styled';

function Header({ handleOpenDetail, canOpenDetail = false, data}) {
    return (
        data.raw.work_order.consignment_notes.map(cn => 
            <Grid item >
                <Grid container spacing={1} style={{ width: '810px' }} alignItems={'flex-end'}>
                    <Grid item xs>
                        <TextBoxDoc2
                            label="NUMBER:"
                            defaultValue={cn.folio.folio_prefix.replace("-", "")}
                            InputProps={{ readOnly: true }}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={2}>
                        <TextBoxDoc2
                            label="DATE:"
                            defaultValue="---"
                            InputProps={{ readOnly: true }}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={2}>
                        <TextBoxDoc2
                            label="DUE DATE:"
                            defaultValue="---"
                            InputProps={{ readOnly: true }}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={2}>
                        <TextBoxDoc2
                            label="TOTAL:"
                            defaultValue="---"
                            InputProps={{ readOnly: true }}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={2}>
                        <TextBoxDoc2
                            label="STATUS:"
                            defaultValue="---"
                            InputProps={{ readOnly: true }}
                            fullWidth
                        />
                    </Grid>
                    <Grid item style={{ width: '75px' }}>
                        <Grid container justifyContent={'space-between'} alignItems={'flex-end'}>
                            <Grid item>
                                <IconButton>
                                    <IconArchivo1 />
                                </IconButton>
                            </Grid>
                            <Grid item>
                                <IconButton
                                    disabled={!canOpenDetail}
                                    onClick={() => handleOpenDetail()}>
                                    <IconArchivo2 />
                                </IconButton>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        )
    );
}

export default Header;