import React from "react";
import {
    CheckCircle,
    Report,
    ArrowCircleRight,
    AccessTimeFilled,
    CheckBoxOutlineBlank,
} from '@mui/icons-material';
import moment from "moment";
import { isEmpty, get } from "lodash";

// GET ICON FROM CODE_NAME
export const getIcon = value => {
    if (value === 'VALIDATED' || value === 'active') {
        return <CheckCircle style={{
            color: '#02B521',
            fontSize: "30px"
        }} />;
    }
    if (value === 'REJECTED' || value === 'EXPIRED' || value === 'inactive') {
        return <Report style={{
            color: '#FF0100',
            fontSize: "30px"
        }} />;
    }
    if (value === 'TO_BE_VALIDATED') {
        return <ArrowCircleRight style={{
            color: '#026DF2',
            fontSize: "30px"
        }} />;
    }
    if (value === 'EXPIRING' || value === 'PENDING' || value === 'ARCHIVED') {
        return <AccessTimeFilled style={{
            color: '#FF7A00',
            fontSize: "30px"
        }} />;
    }
    return <CheckBoxOutlineBlank style={{
        color: '#026DF2',
        fontSize: "30px"
    }} />;
};

//MAP DOCUMENT LIST
export const helperMapData = data => {
    const documents = [];
    if (!isEmpty(data)) {
        data.history.map(a => {
            return a.document_logs.map(b => {
                documents.push({
                    id: b.id,
                    icon: getIcon(b.status.code_name),
                    name: b.document.name.toUpperCase(),
                    status: b.status.name.toUpperCase(),
                    driver_move: `${get(b, 'driver_move.from_zone.zone_name', "")} - ${get(b, 'driver_move.to_zone.zone_name', "")}`,
                    date: moment(a.created_at).format('MM/DD/YYYY'),
                    url: get(b, 'file.files_details[0].file_location')
                });
                return null;
            });
        });
    }
    return documents;
};