import GridMUI from '@mui/material/Grid';
import styled from 'styled-components';
import TypographyMUI from '@mui/material/Typography';

export const Typography = styled(TypographyMUI)`
  font-size: 12px;
`;
export const Grid = styled(GridMUI)`
  ${props => (props.width ? `width: ${props.width} !important;` : '')}
  ${props => (props.padding ? `padding: ${props.padding} !important;` : '')}
`;
export const Card = styled(GridMUI)`
  background-color: ${props => (props.background ? 'none' : '#fff')};
`;
