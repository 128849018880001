import styled from 'styled-components';
import Paper from '@mui/material/Paper';
import StepConnector from '@mui/material/StepConnector';
import StepLabelMUI from '@mui/material/StepLabel';
import GridMUI from '@mui/material/Grid';

export const StatusCard = styled(GridMUI)`
  position: absolute;
  height: 55px;
  width: 375px !important;
  padding: ${props => props.positionTop ? '0px 5px 5px 5px' : '5px'} !important;
  align-content: flex-start;
  ${props => props.positionTop ? `
      top: -74px;
      left: 19px;
      border-left: 2px solid ${props.color};
    ` : `
      top: 43px;
      left: 19px;
      border-left: 2px solid ${props.color};
    `}
`;

export const StepLabel = styled(StepLabelMUI)(({ theme, color }) => ({
  '& .MuiStepIcon-root': {
    color: color,
    border: 'none', // customize the border of the icon
    borderRadius: '50%', // customize the border radius of the icon
  },
  '& .MuiStepIcon-root.Mui-completed': {
    color: color,
  },
  '& .MuiStepIcon-root.Mui-active': {
    color: color,
  },
  '& .MuiStepIcon-root .MuiStepIcon-text': {
    fill: color,
  }
}));
export const ColoredStepConnector = styled(StepConnector)(({ theme, color }) => ({
  marginLeft: '-160px',
  width: '170px',
  '& .MuiStepConnector-line': {
    borderColor: color || '#02b120',
  },
  '& .MuiStepConnector-root': {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  '& .MuiStepConnector-lineHorizontal': {
    borderTopWidth: 5, // customize the line thickness as needed
    transition: 'border-color 0.2s ease-out', // add a transition for a smoother effect
  },
  '& .MuiStepConnector-lineHorizontal:before': {
    display: 'none',
  },
  '& .MuiStepConnector-icon': {
    color: 'red', // customize the color of the SVG icon
  },
}));

export const StatusLabel = styled.div`
  display: inline-block;
  padding: 5px 0 0 0;
  width: 150px;
  height: 30px;
  font-weight: 900;
  text-transform: uppercase;
  color: ${props => props.color || 'white'};
  background-color: ${props => props.backgroundColor || '#8602b5'};
  border: none;
  border-radius: 6px;
  text-align: center;
  font-size: 15px;
  ${props => props.small && `
    padding: 1px 4px; 
    display: inline-table;
    width: 80px;
    height: 15px;
    font-weight: 900;
    font-size: 12px;
    position: absolute;
    white-space: nowrap;
    left: -24px;
    ${props.positionTop ? `
      top: -19px;
    ` : ``}
  `}
`;

export const Container = styled.div`
  padding: 20px 20px 0 30px;
  align-self: center;
`;

export const Element = styled(Paper)`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`;
export const GridScroll = styled(GridMUI)`
  ::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }
  ::-webkit-scrollbar-thumb {
    background-color: #1976d250;
    border-radius: 5px;
  }
  ::-webkit-scrollbar-track {
    background-color: #f1f1f1;
  }
  ::-webkit-scrollbar-button {
    display: none;
  }
`;