import React from 'react';
import { BrowserRouter as Router, Navigate, Route, Routes } from 'react-router-dom';
import { Layout } from './Layout';
import Drivers from './Tabs';
import DriversCurrent from './Tabs/current';
import DriversRecords from './Tabs/records';
import SignIn from "./SignIn";
import { Auth } from "./../service/Auth"
import { AuthProvider } from '../AppContext/AuthContext';

function AppUI() {
  return (
    <React.Fragment>
        <Router>
          <AuthProvider>
            <Routes>
              <Route path='/sign-in' element={<SignIn />} />
              <Route path='/' element={<Auth> <Layout><Drivers /></Layout></Auth>} >
                <Route path='current' element={<DriversCurrent />} />
                <Route path='records' element={<DriversRecords />} />
                <Route path='create' element={<></>} />
                <Route path='settings' element={<></>} />
              </Route>
            </Routes>
        </AuthProvider>
      </Router>
    </React.Fragment>
  );
}

export default AppUI;
