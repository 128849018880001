import styled from 'styled-components';
import ButtonMUI from '@mui/material/Button';

export const TabButton = styled(ButtonMUI)`
&& {
    font-size: 14px;
    color: ${props => props.active ? '#026DF2' : '#757575'};
    background-color: transparent;
    border-bottom: ${props => props.active ? '2px solid #026DF2' : 'none'};
    border-radius: 0;
    padding: 0px 5px;
  }
`;
