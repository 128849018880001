import { styled } from "@mui/material/styles";
import DialogTitleMUI from '@mui/material/DialogTitle';
import IconButtonMUI from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
import { Assignment, FindInPage } from '@mui/icons-material';
import { Button } from "@mui/material";
import DownloadMUI from '@mui/icons-material/Download';

export const DialogTitle = styled(DialogTitleMUI)((props)=>({
    background: '#026DF2',
    color: 'white',
    fontWeight: '900',
    fontSize: 14,
    lineHeight: '8px',
    height: '40px',
    padding: props.padding || 'default-padding-value',
}));

export const TextBoxInfo = styled(TextField)({
    backgroundColor: "#F3F1F1",
    "& .MuiOutlinedInput-notchedOutline": {
        border: "none",
    },
    "&.Mui-focused": {
        "& .MuiOutlinedInput-notchedOutline": {
            border: "none",
        },
    },
    "& .MuiInputBase-input-MuiOutlinedInput-input": {
        fontSize: 14,
    },
    input: {
        fontSize: 14,
        padding: "8px"
    },
    label: {
        fontSize: 14,
    },
    fontSize: 14,
    size: "small",
    height: "30px",
    background: "#F3F1F1",
    borderRadius: "5px",
    justifyContent: "center",
    marginLeft: "8px",
    marginTop: '35px',
    "& label": {
        top: "-8px",
        left: "-0.7rem",
        transformOrigin: "left",
    },
});

export const TextBoxDoc = styled(TextField)({
    backgroundColor: "#F3F1F1",
    "& .MuiOutlinedInput-notchedOutline": {
        border: "none",
    },
    "&.Mui-focused": {
        "& .MuiOutlinedInput-notchedOutline": {
            border: "none",
        },
    },
    size: "small",
    height: "30px",
    background: "#F3F1F1",
    borderRadius: "5px",
    justifyContent: "center",
    "& label": {
        top: "-12px",
        left: "-0.7rem",
        transformOrigin: "left",
    },
});
export const TextBoxDoc2 = styled(TextField)(({first})=>({
    backgroundColor: "#F3F1F1",
    "& .MuiOutlinedInput-notchedOutline": {
        border: "none",
    },
    "&.Mui-focused": {
        "& .MuiOutlinedInput-notchedOutline": {
            border: "none",
        },
    },
    size: "small",
    height: "30px",
    background: "#F3F1F1",
    borderRadius: "5px",
    justifyContent: "center",
    marginLeft: "5px",
    marginBottom: '0px',
    marginTop: first ? '30px' : '10px',
    "& label": {
        top: "-12px",
        left: "-0.7rem",
        transformOrigin: "left",
    },
}));
export const IconArchivo1 = styled(Assignment)({
    color: "#026DF2",
    fontSize: "30px",
});
export const IconArchivo2 = styled(FindInPage)({
    color: "#026DF2",
    fontSize: "30px",
});
export const DownloadIcon = styled(DownloadMUI)({
    color: "#026DF2",
    fontSize: "30px",
});
 export const IconButton = styled(IconButtonMUI)({
    padding: 1,
    borderRadius: 5
 });
 export const TabButton = styled(Button)((props)=>({
    background: 'none',
    color: props.active ? '#026DF2' : '#666667',
 }));
 export const DialogTabButton = styled(Button)((props)=>({
    background: 'none',
    color: props.active ? '#fff' : '#92C2FF',
 }));