import React, { useContext } from 'react';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    Grid,
    InputAdornment
} from "@mui/material";
import PropTypes from 'prop-types';
import {
    Upload,
} from '@mui/icons-material';
import { TextBoxDoc, DialogTitle } from '../styled';
import { DriversCurrentContext } from '../../../hooks';
import useDialogHooks from './hooks';
import LoadingIndicator from '../../../../../../components/Loading';
import Row from './containers/row';



function DocumentsDialog({ openDialog, label, handleClose }) {
    const {
        // Open is row open
        open,
    } = useContext(DriversCurrentContext);
    const {
        dataDialog,
        loading,
        fileOpen,
        handleFileOpen,
        handleFileClose
    } = useDialogHooks(open.container, open.id);
    return (
        <Dialog
            open={openDialog === label}
            onClose={handleClose}
            maxWidth="md"
            fullWidth
        >
            <DialogTitle>
                DOCUMENTS
            </DialogTitle>
            {loading && <DialogContent style={{ padding: '0 0 10px' }}>
                <LoadingIndicator />
            </DialogContent>}
            {!loading && <DialogContent style={{ padding: '10px' }}>
                {/*<Grid container justifyContent={'center'} style={{
                    borderBottom: '1px solid #676666',
                    padding: '0 0 16px',
                }}>
                    <Grid container spacing={1} style={{ width: '800px' }} alignItems={'flex-end'}>
                        <Grid item xs>
                            <TextBoxDoc
                                label="NAME:"
                                focused
                                placeholder='ENTER THE NAME'
                                defaultValue=''
                                fullWidth
                                InputProps={{ readOnly: true }}
                            />
                        </Grid>
                        <Grid item xs={5}>
                            <TextBoxDoc
                                label="FILE:"
                                fullWidth
                                focused
                                placeholder='UPLOAD'
                                defaultValue=''
                                InputProps={{
                                    endAdornment:
                                        (<InputAdornment position="end">
                                            <Upload style={{ color: "#757575" }}
                                                onClick={handleClose}
                                            />
                                        </InputAdornment>),
                                    readOnly: true
                                }}
                            />
                        </Grid>
                        <Grid item style={{ width: '70px' }}>
                            <Button
                                variant="contained"
                                style={{
                                    fontWeight: "900",
                                    height: "30px",
                                }}>
                                ADD
                            </Button>
                        </Grid>

                    </Grid>
                </Grid>*/}
                <Grid container justifyContent={'center'} >
                    {dataDialog.map((element, index) => <Row
                        element={element}
                        index={index}
                        fileOpen={fileOpen}
                        handleFileOpen={handleFileOpen}
                        handleFileClose={handleFileClose}
                    />)}
                </Grid>
            </DialogContent>}
            <DialogActions style={{ borderTop: '1px solid #676666' }}>
                <Button
                    variant='contained'
                    onClick={handleClose}
                    style={{
                        fontWeight: "900",
                        height: "30px",
                    }}
                >
                    Close
                </Button>
            </DialogActions>
        </Dialog>);
}

DocumentsDialog.propTypes = {
    openDialog: PropTypes.string,
    label: PropTypes.string,
    handleClose: PropTypes.func,

};
export default DocumentsDialog;