import styled from 'styled-components';
import TextFieldMUI from '@mui/material/TextField';

export const TextFieldSmall = styled(TextFieldMUI)`
  && {
    input {
      font-size: 12px;
      color: ${props => props.value  ? '#000'  : 'transparent'};
    }
    label {
      font-size: 12px;
      color: ${props => props.textColor || '#000'};
    }
    calendar {
      border: none;
    }
    & .MuiInput-underline:before {
      border-bottom: none;
    }
    & .MuiInput-underline:after {
      border-bottom: none;
    }
    & .MuiInput-underline:after {
      border-bottom: 1px solid #026DF2;
    }
    & .MuiInput-underline:hover:not(.Mui-disabled):before {
      border-bottom: 1px solid #026DF2 !important;
    }
    @media (max-width: 1499px) {
      input {
        font-size: 11px;
      }
      label {
        font-size: 11px;
      }
    }
    @media (max-width: 1299px) {
      input {
        font-size: 10px;
      }
      label {
        font-size: 10px;
      }
    }
  }
`;