export const formFields = [
    {
        label: 'WORK ORDER CONTAINER:',
        defaultValue: '',
        name:"wo.container",
        width: '19%'
    },
    {
        label: 'BILL TO:',
        defaultValue: '',
        name:"wo.billTo",
        width: '19.5%'
    },
    {
        label: 'CUSTOMER REFERENCE:',
        defaultValue: '',
        name:"wo.customer_reference",
        width: '19.75%'
    },
    {
        label: 'ORDER MOVE TYPE:',
        defaultValue: '',
        name:"wo.moveType",
        width: '19%'
    },
    {
        label: 'LOAD TYPE:',
        defaultValue: '',
        name:"wo.loadType",
        width: '19%'
    },
    {
        label: 'MBL:',
        defaultValue: '0',
        name:"wo.mbl",
        width: '11.5%'
    },
    {
        label: 'INBOND VIA:',
        name:"wo.inbond_via_id",
        defaultValue: '0',
        width: '24%'
    },
    {
        label: 'T&E:',
        name:"wo.te",
        defaultValue: '0',
        width: '11.5%'
    },
    {
        label: 'T&E EXPIRATION:',
        name:"wo.te_expiration",
        defaultValue: '0',
        width: '11.5%'
    },
    {
        label: 'VESSEL:',
        name:"wo.vessel_name",
        defaultValue: '0',
        width: '11.5%'
    },
    {
        label: 'WEIGHT:',
        name:"wo.weight",
        defaultValue: '0',
        width: '6%'
    },
    {
        label: 'UNIT:',
        name:"wo.unit_type_name",
        defaultValue: '0',
        width: '6%'
    },
    {
        label: 'SIZE:',
        name:"equipment.equipment_size",
        defaultValue: '0',
        width: '6%'
    },
    {
        label: 'SCALE:',
        name:"wo.scale",
        defaultValue: '0',
        width: '6%'
    },
    {
        label: 'CHASSIS PLATE:',
        name:"wo.chassis_plate",
        defaultValue: '0',
        width: '11.5%'
    },
    {
        label: 'CHASSIS PLATE STATE:',
        name:"wo.chassis_plate_state_code_name",
        defaultValue: '0',
        width: '11.5%'
    },
    {
        label: 'CHASSIS LINE:',
        name:"wo.chassis_line_code_name",
        defaultValue: '0',
        width: '11.9%'
    },
    {
        label: 'CHASSIS NUMBER:',
        name:"wo.chassis_number",
        defaultValue: '0',
        width: '11.5%'
    },
    {
        label: 'CHASSIS SAT TYPE:',
        name:"wo.chassis_sat_type_code_name",
        defaultValue: '0',
        width: '11.6%'
    },
    {
        label: 'SEAL:',
        name:"wo.seal",
        defaultValue: '0',
        width: '11.5%'
    },
    {
        label: 'GREEN LIGHT:',
        name:"wo.mxc_dispatch",
        defaultValue: '0',
        width: '6%'
    },
    {
        label: 'REQUEST DELIVERY DATE:',
        name:"wo.request_delivery_date",
        defaultValue: '0',
        width: '12.5%'
    },
    {
        label: 'ETA:',
        name:"wo.eta",
        defaultValue: '0',
        width: '6%'
    },
    {
        label: 'LAST FREE DAY:',
        name:"wo.last_free_day",
        defaultValue: '0',
        width: '11.5%'
    },
    {
        label: 'AVAILABLE DATE:',
        name:"wo.available_date",
        defaultValue: '0',
        width: '11.5%'
    },
    {
        label: 'DELIVERY DATE:',
        name:"wo.delivery_date",
        defaultValue: '0',
        width: '11.9%'
    },
    {
        label: 'FREE EMPTY RETURN UNTILL:',
        name:"wo.free_empty_return_until",
        defaultValue: '0',
        width: '11.5%'
    },
    {
        label: 'CHASSIS LFD:',
        name:"wo.chassis_lfd",
        defaultValue: '0',
        width: '11.5%'
    },
    {
        label: 'EMPTY NOTIFY:',
        name:"wo.empty_notify",
        defaultValue: '0',
        width: '11.5%'
    },
    {
        label: 'REMARKS:',
        name:"wo.remark",
        defaultValue: '0',
        width: '25.5%'
    }
];