import React, { useContext } from 'react';
import { DriverRecordsContext } from '../../hooks';
import { useMediaQuery } from '@mui/material';
import { Grid, TextFieldSmall, Typography } from '../../styled';
import DatePicker from '../../../../../components/DatePicker';
import FilterListIcon from '@mui/icons-material/FilterList';
import "@fontsource/nunito";
import "@fontsource/roboto";

export default function Header() {
  const {
    searchArgs,
    handlesearchArgs,
    columns,
  } = useContext(DriverRecordsContext);
  
  const xs = useMediaQuery('(min-width:1300px)');
  const sm = useMediaQuery('(min-width:1500px)');
  const role = localStorage.getItem('role')

  const filterIcon = color => xs && <FilterListIcon style={{ color: color || '#676666', fontSize: '16px' }} />;
  return (
    <div>    
    <Grid
      container
      alignItems="flex-end"
      borderBottom
      columns={13}
      justifyContent="space-between"
      padding={xs ? '8px 22px 8px 24px' : '5px 14px 5px 15px'}
    >
      {columns.reference && (<Grid item xs={1} padding="0 10px 0 0">
        <TextFieldSmall
          label="REFERENCE"
          textColor="#676666"
          variant="standard"
          value={searchArgs.reference}
          onChange={e => handlesearchArgs(e.target.value, 'reference')}
          InputProps={{
            endAdornment: filterIcon(),
          }}
        />
      </Grid>)}
      {columns.status && (<Grid item xs={1} padding="0 10px 0 0">
        <TextFieldSmall
          label="STATUS"
          textColor="#676666"
          variant="standard"
          value={searchArgs.status}
          onChange={e => handlesearchArgs(e.target.value, 'status')}
          InputProps={{
            endAdornment: filterIcon(),
          }}
        />
      </Grid>)}
      {columns.container && (<Grid item xs={1} padding="0 10px 0 0">
        <TextFieldSmall
          label="CONTAINER"
          textColor="#676666"
          variant="standard"
          value={searchArgs.container}
          onChange={e => handlesearchArgs(e.target.value, 'container')}
          InputProps={{
            endAdornment: filterIcon(),
          }}
        />
      </Grid>)}
      {columns.type && (<Grid item xs={1} padding="0 10px 0 0">
        <TextFieldSmall
          label="TYPE"
          textColor="#676666"
          variant="standard"
          value={searchArgs.type}
          onChange={e => handlesearchArgs(e.target.value, 'type')}
          InputProps={{
            endAdornment: filterIcon(),
          }}
        />
      </Grid>)}
      {columns.moveType && (<Grid item xs={1} padding="0 15px 0 0">
        <TextFieldSmall
          label="MOVE TYPE"
          textColor="#676666"
          variant="standard"
          value={searchArgs.moveType}
          onChange={e => handlesearchArgs(e.target.value, 'moveType')}
          InputProps={{
            endAdornment: filterIcon(),
          }}
        />
      </Grid>)}
      {columns.routeStatus && (<Grid item xs={1} padding="0 10px 0 0">
        <TextFieldSmall
          fullWidth
          label="ROUTE STATUS"
          textColor="#676666"
          variant="standard"
          value={searchArgs.routeStatus}
          onChange={e => handlesearchArgs(e.target.value, 'routeStatus')}
          InputProps={{
            endAdornment: filterIcon(),
          }}
        />
        </Grid>)}
      {columns.consignee && (<Grid item xs={2} padding="0 10px 0 0">
        <TextFieldSmall
          fullWidth
          label="CONSIGNEE"
          textColor="#676666"
          variant="standard"
          value={searchArgs.consignee}
          onChange={e => handlesearchArgs(e.target.value, 'consignee')}
          InputProps={{
            endAdornment: filterIcon(),
          }}
        />
      </Grid>)}
      {columns.te && (<Grid item xs={1} padding="0 10px 0 0">
        <TextFieldSmall
          fullWidth
          label="T&E"
          textColor="#676666"
          variant="standard"
          value={searchArgs.te}
          onChange={e => handlesearchArgs(e.target.value, 'te')}
          InputProps={{
            endAdornment: filterIcon(),
          }}
        />
      </Grid>)}
      {columns.from && (<Grid item xs={1} padding="0 10px 0 0">
        <TextFieldSmall
          fullWidth
          label="FROM"
          textColor="#676666"
          variant="standard"
          value={searchArgs.from}
          onChange={e => handlesearchArgs(e.target.value, 'from')}
          InputProps={{
            endAdornment: filterIcon(),
          }}
        />
      </Grid>)}
      {columns.to && (<Grid item xs={1} padding="0 10px 0 0">
        <TextFieldSmall
          fullWidth
          label="TO"
          textColor="#676666"
          variant="standard"
          value={searchArgs.to}
          onChange={e => handlesearchArgs(e.target.value, 'to')}
          InputProps={{
            endAdornment: filterIcon(),
          }}
        />
      </Grid>)}
      {columns.size && (<Grid item xs={1} padding="0 10px 0 0">
        <TextFieldSmall
          fullWidth
          label="SIZE"
          textColor="#676666"
          variant="standard"
          value={searchArgs.size}
          onChange={e => handlesearchArgs(e.target.value, 'size')}
          InputProps={{
            endAdornment: filterIcon(),
          }}
        />
      </Grid>)}
      {columns.lfd && (<Grid item xs={1} padding="0 10px 0 0">
        <DatePicker
          textColor="#026DF2"
          defaultIcon
          emptyLabel="LFD"
          value={searchArgs.lfd}
          onChange={e => {
            handlesearchArgs(e.target.value, 'lfd', 'date');
          }}
        />
      </Grid>)}
      {columns.emptyNotify && (<Grid item xs={1} padding="0 10px 0 0">
        <DatePicker
          textColor="#026DF2"
          defaultIcon
          emptyLabel="EMPTY NOTIFY"
          value={searchArgs.emptyNotify}
          onChange={e => {
            handlesearchArgs(e.target.value, 'emptyNotify', 'date');
          }}
        />
      </Grid>)}
      {columns.inbondVia && (<Grid item xs={1} padding="0 10px 0 0">
        <TextFieldSmall
          fullWidth
          label="INBOND VIA"
          textColor="#676666"
          variant="standard"
          value={searchArgs.inbondVia}
          onChange={e => handlesearchArgs(e.target.value, 'inbondVia')}
          InputProps={{
            endAdornment: filterIcon(),
          }}
        />
      </Grid>)}
      {columns.mbl && (<Grid item xs={1} padding="0 10px 0 0">
        <TextFieldSmall
          fullWidth
          label="MBL"
          textColor="#676666"
          variant="standard"
          value={searchArgs.mbl}
          onChange={e => handlesearchArgs(e.target.value, 'mbl')}
          InputProps={{
            endAdornment: filterIcon(),
          }}
        />
      </Grid>)}
      {columns.apptDate && (<Grid item xs={1} padding="0 10px 0 0">
        <DatePicker
            textColor="#026DF2"
            defaultIcon
            emptyLabel="APPT. DATE"
            value={searchArgs.apptDate}
            onChange={e => {
              handlesearchArgs(e.target.value, 'apptDate', 'date');
            }}
          />
      </Grid>)}
      {columns.apptNumber && (<Grid item xs={1} padding="0 10px 0 0">
        <TextFieldSmall
          fullWidth
          label="APPT. NUMBER"
          textColor="#676666"
          variant="standard"
          value={searchArgs.apptNumber}
          onChange={e => handlesearchArgs(e.target.value, 'apptNumber')}
          InputProps={{
            endAdornment: filterIcon(),
          }}
        />
      </Grid>)}
      {columns.driver && (<Grid item xs={2} padding="0 10px 0 0">
        <TextFieldSmall
          fullWidth
          label="DRIVER"
          textColor="#676666"
          variant="standard"
          value={searchArgs.driver}
          onChange={e => handlesearchArgs(e.target.value, 'driver')}
          InputProps={{
            endAdornment: filterIcon(),
          }}
        />
      </Grid>)}
      {columns.plate && (<Grid item xs={1} padding="0 10px 0 0">
        <TextFieldSmall
          fullWidth
          label="PLATE"
          textColor="#676666"
          variant="standard"
          value={searchArgs.plate}
          onChange={e => handlesearchArgs(e.target.value, 'plate')}
          InputProps={{
            endAdornment: filterIcon(),
          }}
        />
      </Grid>)}
      {columns.folio && (<Grid item xs={1} padding="0 10px 0 0">
        <TextFieldSmall
          fullWidth
          label="FOLIO"
          textColor="#676666"
          variant="standard"
          value={searchArgs.folio}
          onChange={e => handlesearchArgs(e.target.value, 'folio')}
          InputProps={{
            endAdornment: filterIcon(),
          }}
        />
      </Grid>)}
      {columns.scale && (<Grid item xs={1} padding="0 10px 0 0">
        <TextFieldSmall
          fullWidth
          label="SCALE"
          textColor="#676666"
          variant="standard"
          value={searchArgs.scale}
          onChange={e => handlesearchArgs(e.target.value, 'scale')}
          InputProps={{
            endAdornment: filterIcon(),
          }}
        />
      </Grid>)}
      {columns.eta && (<Grid item xs={1} padding="0 10px 0 0">
        <DatePicker
            textColor="#026DF2"
            defaultIcon
            emptyLabel="ETA"
            value={searchArgs.eta}
            onChange={e => {
              handlesearchArgs(e.target.value, 'eta', 'date');
            }}
          />
      </Grid>)}
      {columns.days && (<Grid item xs={1} padding="0 10px 0 0">
        <TextFieldSmall
          fullWidth
          label="DAYS"
          textColor="#676666"
          variant="standard"
          value={searchArgs.days}
          onChange={e => handlesearchArgs(e.target.value, 'days')}
          InputProps={{
            endAdornment: filterIcon(),
          }}
        />
      </Grid>)}
      {columns.remarks && (<Grid item xs={1} padding="0 10px 0 0">
        <TextFieldSmall
          fullWidth
          label="REMARKS"
          textColor="#676666"
          variant="standard"
          value={searchArgs.remarks}
          onChange={e => handlesearchArgs(e.target.value, 'remarks')}
          InputProps={{
            endAdornment: filterIcon(),
          }}
        />
      </Grid>)}
      {role !== "consignee" && <Grid item xs={null} width={'125px'} padding="0 5px 0 0">
        <TextFieldSmall
          label="INVOICE NUMBER"
          textColor="#026DF2"
          variant="standard"
          value={searchArgs.invoiceNumber}
          onChange={e => handlesearchArgs(e.target.value, 'invoiceNumber')}
          InputProps={{
            endAdornment: filterIcon(),
          }}
        />
      </Grid>}
      {xs && role !== "consignee" && <Grid item xs={1} padding="0 10px 0 0">
        <DatePicker
          textColor="#026DF2"
          defaultIcon
          emptyLabel="INVOICE DATE"
          value={searchArgs.invoiceDate}
          onChange={e => {
            handlesearchArgs(e.target.value, 'invoiceDate', 'date');
          }}
        />
      </Grid>}
      {sm && role !== "consignee" &&  <Grid item xs={1} padding="0 10px 0 0">
        <TextFieldSmall
          label="INVOICE TOTAL"
          textColor="#026DF2"
          variant="standard"
          value={searchArgs.invoiceTotal}
          onChange={e => handlesearchArgs(e.target.value, 'invoiceTotal')}
          InputProps={{
            endAdornment: filterIcon(),
          }}
        />
      </Grid>}
      {role !== "consignee" && <Grid item xs={1} padding="0 5px 0 0">
        <DatePicker
          textColor="#026DF2"
          emptyLabel="DUE DATE"
          defaultIcon
          value={searchArgs.dueDate}
          onChange={e => {
            handlesearchArgs(e.target.value, 'dueDate', 'date');
          }}
        />
        </Grid>}
        <Grid item width={'20px'} xs={null}/>
    </Grid>  
    </div>
  );
}

