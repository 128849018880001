import { Grid } from '@mui/material';
import React from 'react';
import { IconArchivo1, IconArchivo2, IconButton, TextBoxDoc2, DownloadIcon} from '../../../styled';
import { get } from 'lodash';
import moment from 'moment';
import Tooltip from '@mui/material/Tooltip';
import { saveAs } from 'file-saver';
import JSZip from 'jszip';
import {
    CheckCircle,
    Report,
    ArrowCircleRight,
    AccessTimeFilled,
    CheckBoxOutlineBlank,
} from '@mui/icons-material';

function Header({ handleOpenDetail, canOpenDetail = false, cp, invoice, index}) {

    if(index === "0") {

        const handleDownload = () => {
            Promise.all([
                fetch(get(invoice, "paper.documents[0].url")).then(res => res.blob()),
                fetch(get(invoice, "paper.xml_document[0].url")).then(res => res.blob())
            ]).then(([pdfBlob, xmlBlob]) => {
                const zip = JSZip();
                zip.file(`${get(invoice, 'prefix')}${get(invoice, 'folio')}.pdf`, pdfBlob);
                zip.file(`${get(invoice, 'prefix')}${get(invoice, 'folio')}.xml`, xmlBlob);
                zip.generateAsync({ type: 'blob' }).then(content => {
                saveAs(content, `${get(invoice, 'prefix')}${get(invoice, 'folio')}.zip`);
                });
            });
        };

       if(get(invoice, "prefix") !== "INV" && get(invoice, "prefix") !== "BES") {
           return (<div></div>);
       }

        return (<Grid item style={{
            marginBottom: '8px'
        }}>
            <Grid container spacing={1} style={{ width: '810px' }} alignItems={'flex-end'}>
                {
                    get(invoice, 'paper.status.name') === "Paid" ? (
                        <CheckCircle style={{
                            color: '#02B521',
                            fontSize: "24px",
                            marginBottom: '4px'
                        }} />
                    ) : get(invoice, 'paper.status.name') === "Billed" ? (
                        <AccessTimeFilled style={{
                            color: '#036df2',
                            fontSize: "24px",
                            marginBottom: '4px'
                        }} />
                    ) : (
                        <Report style={{
                            color: '#FF0100',
                            fontSize: "24px",
                            marginBottom: '4px'
                        }} />
                    )
                }
                <Grid item xs>
                    <TextBoxDoc2
                        label="NUMBER:"
                        defaultValue={`${get(invoice, 'prefix')}${get(invoice, 'folio')}`}
                        InputProps={{ readOnly: true }}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={2}>
                    <TextBoxDoc2
                        label="DATE:"
                        defaultValue={moment(get(invoice, 'paper.paper_date', '---')).format('MM/DD/YYYY')}
                        InputProps={{ readOnly: true }}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={2}>
                    <TextBoxDoc2
                        label="DUE DATE:"
                        defaultValue={moment(get(invoice, 'paper.due_date', '---')).format('MM/DD/YYYY')}
                        InputProps={{ readOnly: true }}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={2}>
                    <TextBoxDoc2
                        label="TOTAL:"
                        defaultValue={get(invoice, 'paper.total', '---')}
                        InputProps={{ readOnly: true }}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={2}>
                    <TextBoxDoc2
                        label="STATUS:"
                        defaultValue={get(invoice, 'paper.status.name', '---')}
                        InputProps={{ readOnly: true }}
                        fullWidth
                    />
                </Grid>
                <Grid item style={{ width: '75px' }}>
                    <Grid container justifyContent={'space-between'} alignItems={'flex-end'}>
                        {get(invoice, 'prefix') === "BES" &&<Grid item>
                            <Tooltip title="Download PDF and XML">
                                <IconButton onClick={() => handleDownload()}>
                                    <DownloadIcon />
                                </IconButton>
                            </Tooltip>
                        </Grid>}
                        <Grid item>
                            <IconButton
                                disabled={!canOpenDetail}
                                onClick={() => handleOpenDetail()}>
                                <IconArchivo2 />
                            </IconButton>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>)
    }
    
    if(index === "1") {
        return (
            cp.map((inv) => {

                const handleDownload = () => {
                    Promise.all([
                        fetch(get(inv, "documents[0].url")).then(res => res.blob()),
                        fetch(get(inv, "xml_document[0].url")).then(res => res.blob())
                    ]).then(([pdfBlob, xmlBlob]) => {
                        const zip = JSZip();
                        zip.file(`${get(inv, 'folio.folio_prefix')}.pdf`, pdfBlob);
                        zip.file(`${get(inv, 'folio.folio_prefix')}.xml`, xmlBlob);
                        zip.generateAsync({ type: 'blob' }).then(content => {
                        saveAs(content, `${get(inv, 'folio.folio_prefix')}.zip`);
                        });
                    });
                };

                return <Grid item style={{
                    marginBottom: '8px'
                }}>
                    <Grid container spacing={1} style={{ width: '810px' }} alignItems={'flex-end'}>
                        {
                            get(inv, 'paper.status.name') === "Paid" ? (
                                <CheckCircle style={{
                                    color: '#02B521',
                                    fontSize: "24px",
                                    marginBottom: '4px'
                                }} />
                            ) : get(inv, 'paper.status.name') === "Billed" ? (
                                <AccessTimeFilled style={{
                                    color: '#036df2',
                                    fontSize: "24px",
                                    marginBottom: '4px'
                                }} />
                            ) : (
                                <Report style={{
                                    color: '#FF0100',
                                    fontSize: "24px",
                                    marginBottom: '4px'
                                }} />
                            )
                        }
                        
                        <Grid item xs>
                            <TextBoxDoc2
                                label="NUMBER:"
                                defaultValue={get(inv, 'folio.folio_prefix')}
                                InputProps={{ readOnly: true }}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={2}>
                            <TextBoxDoc2
                                label="DATE:"
                                defaultValue={moment(get(inv, 'paper.paper_date', '---')).format('MM/DD/YYYY')}
                                InputProps={{ readOnly: true }}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={2}>
                            <TextBoxDoc2
                                label="DUE DATE:"
                                defaultValue={moment(get(inv, 'paper.due_date', '---')).format('MM/DD/YYYY')}
                                InputProps={{ readOnly: true }}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={2}>
                            <TextBoxDoc2
                                label="TOTAL:"
                                defaultValue={get(inv, 'paper.total', '---')}
                                InputProps={{ readOnly: true }}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={2}>
                            <TextBoxDoc2
                                label="STATUS:"
                                defaultValue={get(inv, 'paper.status.name', '---')}
                                InputProps={{ readOnly: true }}
                                fullWidth
                            />
                        </Grid>
                        <Grid item style={{ width: '75px' }}>
                            <Grid container justifyContent={'space-between'} alignItems={'flex-end'}>
                                <Grid item>
                                    <Tooltip title="Download PDF and XML">
                                        <IconButton onClick={() => handleDownload()}>
                                            <DownloadIcon />
                                        </IconButton>
                                    </Tooltip>
                                </Grid>
                                <Grid item>
                                    <IconButton
                                        disabled={!canOpenDetail}
                                        onClick={() => handleOpenDetail()}>
                                        <IconArchivo2 />
                                    </IconButton>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            })
        );
    }
}

export default Header;