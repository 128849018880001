
import styled from 'styled-components';
import Typography from '@mui/material/Typography';


export const Image = styled.img`
    width: 200px;
    left: 27px;
    top: 21px;
    z-index: 1;
`;

export const ProgressContainer = styled.div`
  width: 100%;
  margin-top: 10px;
`;

export const Text = styled(Typography)`
  margin-top: 30px;
`;
