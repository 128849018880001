import React from 'react';
import Table from './containers/table/index';
import { DriversCurrentProvider } from './hooks';

export default function Drivers() {
  return (
    <>
      <DriversCurrentProvider>
        <Table/>
      </DriversCurrentProvider>
    </>
  );
}
