import { get } from 'lodash';
import moment from 'moment';

export const helperMapData = item => {
    return ({
        wo: {
            wo_id: get(item, 'id', ''),
            billTo: get(item, 'customer.name', ''),
            moveType: get(item, 'move_type.name', ''),
            loadType: get(item, 'load_type.name', ''),
            container: get(item, 'container', ''),
            move_type_code_name: get(item, 'move_type_code_name', ''),
            eta: get(item, 'eta', null),
            request_delivery_date: get(item, 'request_delivery_date', null),
            mxc_dispatch: get(item, 'mxc_dispatch', null),
            inbond_via_id: get(item, 'inbond.name', ''),
            customer_reference: get(item, 'customer_reference', ''),
            te: get(item, 'te', ''),
            te_expiration: get(item, 'te_expiration', null),
            last_free_day: get(item, 'last_free_day') ? moment(get(item, 'last_free_day')).format('YYYY-MM-DD') : "IN PROCESS",
            available_date: get(item, 'available_date') ? moment(get(item, 'available_date', null)).format('YYYY-MM-DD') : "IN PROCESS",
            free_empty_return_until: get(item, 'free_empty_return_until', null),
            chassis_lfd: get(item, 'chassis_lfd', null),
            do_received: get(item, 'do_received', null),
            delivery_date: get(item, 'delivery_date') ? moment(get(item, 'delivery_date')).format('YYYY-MM-DD') : "IN  PROCESS",
            empty_notify: get(item, 'empty_notify', null),
            mbl: get(item, 'mbl', ''),
            scale: get(item, 'scale', ''),
            rfd: get(item, 'rfd', null),
            weight: get(item, 'weight', ''),
            unit_type_name: get(item, 'unit_type.shortname', null),
            remark: get(item, 'remark', ''),
            seal: get(item, 'equipment.seals[0].folio', ''),
            delivery_required_date: get(item, 'delivery_required_date', null),
            customer_id: get(item, 'customer.name', ''),
            vessel_name: get(item, 'vessel_name', ''),
            chassis_line_code_name: get(item, 'chassis_line.name', ''),
            chassis_sat_type_code_name: get(item, 'chassis_sat_type.name', ''),
            route_charge: `${get(item, 'from_zone.zone_name', '') ||
                get(item, 'address_from.zones[0].zone_name', '') ||
                get(item, 'address_from.formatted_address', '')} - ${get(
                    item,
                    'to_zone.zone_name',
                    '',
                ) ||
                get(item, 'address_to.zones[0].zone_name', '') ||
                get(item, 'address_to.formatted_address', '')}`,
            chassis_plate: get(item, 'chassis_plate', ''),
            chassis_plate_state_code_name: get(
                item,
                'chassis_plate_state.iso_name',
                null,
            ),
            chassis_number: get(item, 'chassis_number', ''),
            load_type_id: get(item, 'load_type.id', null),
        },
        equipment: {
            equipment_size: get(item, 'equipment.equipment_type.size', ''),
            equipment_type_id: get(item, 'equipment.equipment_type.id', ''),
        },
    });
};