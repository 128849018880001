import { ArrowForward, LocationOn } from '@mui/icons-material';
import React, { useContext } from 'react';
import { Grid, Typography } from '../../../styled';
import { StatusLabel } from '../styled';
import { Button } from '../../../styled';
import { DriversCurrentContext } from '../../../hooks';

function RowContent({ data }) {
    const {
        setOpenLocation,
        open
    } = useContext(DriversCurrentContext);

    const buttonStyle =  {
        fontWeight: '600', 
        fontSize: '12px', 
        lineHeight: '12px', 
        color: '#FFFFFF', 
        textAlign: 'start', 
        position: 'relative',
        width: '200px'
    };

    const lineComponent = () => <div item xs={2} style={{ position: 'absolute', top: 5, left: 30, borderLeft: '2px solid #fff', height: '20px' }} />

    return (
        <Grid container justifyContent="center" alignItems="start" padding="0 10px" style={{ minHeight: 150 }}>
            <Grid item>
                <Grid container spacing={1} justifyContent="start" style={{width: 'min-content'}}>
                    <Grid item>
                       <StatusLabel backgroundColor={data.details.status.color}>{data.details.status.name}</StatusLabel>
                    </Grid>
                        
                    <Grid item >
                        <Typography font="14px" noWrap>
                            CONTAINER: {data.container}
                        </Typography>
                    </Grid>

                    <Grid item>
                        <Typography font="12px">
                            From: {data.details.from}
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Typography font="12px">
                            To: {data.details.to}
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Grid container justifyContent="start" alignItems="center">
                            <Grid item>
                                <Typography font="12px" color="primary" titleText noWrap>
                                    {data.details.currentFrom}
                                </Typography>
                            </Grid>
                            {
                                data.details.currentTo !== "Not found" && (
                                    <Grid item>
                                        <ArrowForward style={{ color: "#8602b5", fontSize: 14, margin: '0 5px' }} />
                                    </Grid>
                                )
                            }
                            {
                                data.details.currentTo !== "Not found" && (
                                    <Grid item>
                                        <Typography font="12px" color="primary" titleText noWrap>
                                            {data.details.currentTo}
                                        </Typography>
                                    </Grid>
                                )
                            }
                        </Grid>
                    </Grid>
                    <Grid item>
                        <Button 
                            bgColor={(!open.vehicle_unit || !open.driver_user) ? '#C2C2C2' : "#8602B5"} 
                            style={buttonStyle}
                            disabled={!open.vehicle_unit || !open.driver_user}
                            onClick={() => {
                                setOpenLocation(true)
                            }}
                            fontSize={"11px"}
                        >
                            <Grid container justifyContent="flex-start">
                                <Grid item xs={2}>
                                    <LocationOn />
                                </Grid>
                                {lineComponent()}
                                <Grid className="buttonText" item xs style={{ alignSelf: 'center', paddingLeft: '10px' }}>
                                    {"LOCATION IN REAL TIME"}
                                </Grid>
                            </Grid>
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
}

export default RowContent;