import React from 'react';
import AppBar from './AppBar';
import { SideBar } from './SideBar';
import { AppContext } from '../../AppContext';

function Layout({ children }) {

  const {
    auth,
    anchorEl,
    handleMenu,
    handleClose,
    sidebar,
    showSidebar
  } = React.useContext(AppContext);

  return (
    <React.Fragment>
      <AppBar
        auth={auth}
        anchorEl={anchorEl}
        showSidebar={showSidebar}
        handleMenu={handleMenu}
        handleClose={handleClose}
      />
      <SideBar
        sidebar={sidebar}
        showSidebar={showSidebar}
      />
      <div style={{ height: '60px', width: '100%' }} />
      <div style={{ padding: '20px'}}>
        {children}
      </div>
    </React.Fragment>
  );
}

export { Layout };