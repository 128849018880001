import React, { useContext } from 'react';
import { DriverRecordsContext } from '../../hooks';
import { useMediaQuery } from '@mui/material';
import PropTypes from 'prop-types';
import NoData from '../../../../../components/NoData';
import Loading from '../../../../../components/Loading';
import { Grid, IconButton, Typography } from '../../styled';
import { Summarize, Textsms, Info, KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';
import RowContent from '../row-content';
import { useLocation } from 'react-router-dom';
import TypographyMUI from '@mui/material/Typography';
export default function Table() {
  const {
    data,
    open,
    handleOpen,
    loading,
    handleOpenDialog,
    columns,
  } = useContext(DriverRecordsContext);

  const xs = useMediaQuery('(min-width:1300px)');
  const sm = useMediaQuery('(min-width:1500px)');
  const role = localStorage.getItem('role')
  if (!data) {
    return <NoData title="No results."></NoData>;
  }
  if (loading) {
    return <Loading />;
  }
  const variantLabel = 'caption';
  return (
    <>
      {data.map(e => {
        const labelColor = open.id === e.id ? '#026df2' : '#757575';
        return (
          <Grid
            container
            alignItems="center"
            borderbottom="true"
            card
          >
            <Grid item xs={12}>
              <Grid container
                columns={13}
                justifyContent="space-between"
                padding={xs ? '8px 22px 8px 24px' : '5px 14px 5px 15px'}>
                {columns.reference && (<Grid padding="0 0 0 0"  item xs={1}>
                  <TypographyMUI style={{
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    color: labelColor,
                    fontSize: '12px',
                  }} >
                    {e.reference}
                  </TypographyMUI>
                </Grid>)}
                {columns.status && (<Grid padding="0 0 0 0"  item xs={1}>
                  <Typography variant={variantLabel} color={e.status_color} noWrap style={{overflow:"hidden", whiteSpace: "nowrap", textOverflow: "ellipsis"}} >
                    {e.status}
                  </Typography>
                </Grid>)}
                {columns.container && (<Grid padding="0 0 0 0" item xs={1} >
                  <Typography variant={variantLabel} color={labelColor} noWrap style={{overflow:"hidden", whiteSpace: "nowrap", textOverflow: "ellipsis"}} >
                    {e.container}
                  </Typography>
                </Grid>)}
                {columns.type && (<Grid padding="0 0 0 0" item xs={1}>
                  <Typography variant={variantLabel} color={labelColor} noWrap style={{overflow:"hidden", whiteSpace: "nowrap", textOverflow: "ellipsis"}} >
                    {e.type}
                  </Typography>
                </Grid>)}
                {columns.moveType && (<Grid padding="0 0 0 0"  item xs={1}>
                  <Typography variant={variantLabel} color={labelColor} noWrap style={{overflow:"hidden", whiteSpace: "nowrap", textOverflow: "ellipsis"}} >
                    {e.moveType}
                  </Typography>
                </Grid>)}
                {columns.routeStatus && (<Grid padding="0 0 0 0"  item xs={1}>
                  <Typography variant={variantLabel} color={labelColor} noWrap style={{overflow:"hidden", whiteSpace: "nowrap", textOverflow: "ellipsis"}} >
                    {e.routeStatus}
                  </Typography>
                </Grid>)}
                {columns.consignee && (<Grid padding="0 0 0 0" item xs={2} style={{overflow:"hidden", whiteSpace: "nowrap", textOverflow: "ellipsis"}} >
                  <Typography variant={variantLabel} color={labelColor} noWrap style={{overflow:"hidden", whiteSpace: "nowrap", textOverflow: "ellipsis"}} >
                    {e.consignee}
                  </Typography>
                </Grid>)}
                {columns.te && (<Grid padding="0 0 0 0" item xs={1} style={{overflow:"hidden", whiteSpace: "nowrap", textOverflow: "ellipsis"}} >
                  <Typography variant={variantLabel} color={labelColor} noWrap style={{overflow:"hidden", whiteSpace: "nowrap", textOverflow: "ellipsis"}} >
                    {e.te}
                  </Typography>
                </Grid>)}
                {columns.from && (<Grid padding="0 0 0 0" item xs={1} style={{overflow:"hidden", whiteSpace: "nowrap", textOverflow: "ellipsis"}} >
                  <Typography variant={variantLabel} color={labelColor} noWrap style={{overflow:"hidden", whiteSpace: "nowrap", textOverflow: "ellipsis"}} >
                    {e.from}
                  </Typography>
                </Grid>)}
                {columns.to && (<Grid padding="0 0 0 0" item xs={1} style={{overflow:"hidden", whiteSpace: "nowrap", textOverflow: "ellipsis"}} >
                  <Typography variant={variantLabel} color={labelColor} noWrap style={{overflow:"hidden", whiteSpace: "nowrap", textOverflow: "ellipsis"}} >
                    {e.to}
                  </Typography>
                </Grid>)}
                {columns.size && (<Grid padding="0 0 0 0" item xs={1} style={{overflow:"hidden", whiteSpace: "nowrap", textOverflow: "ellipsis"}} >
                  <Typography variant={variantLabel} color={labelColor} noWrap style={{overflow:"hidden", whiteSpace: "nowrap", textOverflow: "ellipsis"}} >
                    {e.size}
                  </Typography>
                </Grid>)}
                {columns.lfd && (<Grid padding="0 0 0 0" item xs={1} style={{overflow:"hidden", whiteSpace: "nowrap", textOverflow: "ellipsis"}} >
                  <Typography variant={variantLabel} color={labelColor} noWrap style={{overflow:"hidden", whiteSpace: "nowrap", textOverflow: "ellipsis"}} >
                    {e.lfd}
                  </Typography>
                </Grid>)}
                {columns.emptyNotify && (<Grid padding="0 0 0 0" item xs={1} style={{overflow:"hidden", whiteSpace: "nowrap", textOverflow: "ellipsis"}} >
                  <Typography variant={variantLabel} color={labelColor} noWrap style={{overflow:"hidden", whiteSpace: "nowrap", textOverflow: "ellipsis"}} >
                    {e.emptyNotify}
                  </Typography>
                </Grid>)}
                {columns.inbondVia && (<Grid padding="0 0 0 0" item xs={1} style={{overflow:"hidden", whiteSpace: "nowrap", textOverflow: "ellipsis"}} >
                  <Typography variant={variantLabel} color={labelColor} noWrap style={{overflow:"hidden", whiteSpace: "nowrap", textOverflow: "ellipsis"}} >
                    {e.inbondVia}
                  </Typography>
                </Grid>)}
                {columns.mbl && (<Grid padding="0 0 0 0" item xs={1} style={{overflow:"hidden", whiteSpace: "nowrap", textOverflow: "ellipsis"}} >
                  <Typography variant={variantLabel} color={labelColor} noWrap style={{overflow:"hidden", whiteSpace: "nowrap", textOverflow: "ellipsis"}} >
                    {e.mbl}
                  </Typography>
                </Grid>)}
                {columns.apptDate && (<Grid padding="0 0 0 0" item xs={1} style={{overflow:"hidden", whiteSpace: "nowrap", textOverflow: "ellipsis"}} >
                  <Typography variant={variantLabel} color={labelColor} noWrap style={{overflow:"hidden", whiteSpace: "nowrap", textOverflow: "ellipsis"}} >
                    {e.apptDate}
                  </Typography>
                </Grid>)}
                {columns.apptNumber && (<Grid padding="0 0 0 0" item xs={1} style={{overflow:"hidden", whiteSpace: "nowrap", textOverflow: "ellipsis"}} >
                  <Typography variant={variantLabel} color={labelColor} noWrap style={{overflow:"hidden", whiteSpace: "nowrap", textOverflow: "ellipsis"}} >
                    {e.apptNumber}
                  </Typography>
                </Grid>)}
                {columns.driver && (<Grid padding="0 0 0 0" item xs={2} style={{overflow:"hidden", whiteSpace: "nowrap", textOverflow: "ellipsis"}} >
                  <Typography variant={variantLabel} color={labelColor} noWrap style={{overflow:"hidden", whiteSpace: "nowrap", textOverflow: "ellipsis"}} >
                    {e.driver}
                  </Typography>
                </Grid>)}
                {columns.plate && (<Grid padding="0 0 0 0" item xs={1} style={{overflow:"hidden", whiteSpace: "nowrap", textOverflow: "ellipsis"}} >
                  <Typography variant={variantLabel} color={labelColor} noWrap style={{overflow:"hidden", whiteSpace: "nowrap", textOverflow: "ellipsis"}} >
                    {e.plate}
                  </Typography>
                </Grid>)}
                {columns.folio && (<Grid padding="0 0 0 0" item xs={1} style={{overflow:"hidden", whiteSpace: "nowrap", textOverflow: "ellipsis"}} >
                  <Typography variant={variantLabel} color={labelColor} noWrap style={{overflow:"hidden", whiteSpace: "nowrap", textOverflow: "ellipsis"}} >
                    {e.folio}
                  </Typography>
                </Grid>)}
                {columns.scale && (<Grid padding="0 0 0 0" item xs={1} style={{overflow:"hidden", whiteSpace: "nowrap", textOverflow: "ellipsis"}} >
                  <Typography variant={variantLabel} color={labelColor} noWrap style={{overflow:"hidden", whiteSpace: "nowrap", textOverflow: "ellipsis"}} >
                    {e.scale}
                  </Typography>
                </Grid>)}
                {columns.eta && (<Grid padding="0 0 0 0" item xs={1} style={{overflow:"hidden", whiteSpace: "nowrap", textOverflow: "ellipsis"}} >
                  <Typography variant={variantLabel} color={labelColor} noWrap style={{overflow:"hidden", whiteSpace: "nowrap", textOverflow: "ellipsis"}} >
                    {e.eta}
                  </Typography>
                </Grid>)}
                {columns.days && (<Grid padding="0 0 0 0" item xs={1} style={{overflow:"hidden", whiteSpace: "nowrap", textOverflow: "ellipsis"}} >
                  <Typography variant={variantLabel} color={labelColor} noWrap style={{overflow:"hidden", whiteSpace: "nowrap", textOverflow: "ellipsis"}} >
                    {e.days}
                  </Typography>
                </Grid>)}
                {columns.remarks && (<Grid padding="0 0 0 0" item xs={1} style={{overflow:"hidden", whiteSpace: "nowrap", textOverflow: "ellipsis"}} >
                  <Typography variant={variantLabel} color={labelColor} noWrap style={{overflow:"hidden", whiteSpace: "nowrap", textOverflow: "ellipsis"}} >
                    {e.remarks}
                  </Typography>
                </Grid>)}
                {role !== "consignee" && <Grid item xs={null} width={'125px'} padding="0 10px 0 0" >
                  <Typography variant={variantLabel} color={labelColor} noWrap clickable onClick={() => handleOpen(e)}>
                    {e.invoiceNumber}
                  </Typography>
                </Grid>}
                {xs && role !== "consignee" &&  <Grid item xs={1} padding="0 10px 0 0" >
                  <Typography variant={variantLabel} color={labelColor} noWrap clickable onClick={() => handleOpen(e)}>
                    {e.invoiceDate}
                  </Typography>
                </Grid>}
                {sm && role !== "consignee" && (<Grid item xs={1} padding="0 10px 0 0" >
                  <Typography variant={variantLabel} color={labelColor} noWrap clickable onClick={() => handleOpen(e)}>
                    {e.invoiceTotal}
                  </Typography>
                </Grid>)}
                {role !== "consignee" && <Grid item xs={1} padding="0 10px 0 0" style={{ justifyContent: "space-between" }}>
                  <Typography variant={variantLabel} color={labelColor} noWrap clickable onClick={() => handleOpen(e)}>
                    {e.dueDate}
                  </Typography>
                </Grid>}
                <Grid item width={'20px'} xs={null}>
                  <IconButton small color='#026df2' onClick={() => handleOpen(e)}>
                    {open.id === e.id ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                  </IconButton>
                </Grid>
                
                {/*<Grid
                  item
                  xs={sm ? 1 : null}
                  width={!sm && '120px'}
                >
                  <Grid container justifyContent="space-between">
                    <IconButton small onClick={() => {
                      if (open.id !== e.id) handleOpen(e);
                      handleOpenDialog('docs')
                    }}>
                      <Summarize />
                    </IconButton>
                    <IconButton small onClick={() => {
                      handleOpenDialog('ask')
                    }}>
                      <Textsms />
                    </IconButton>
                    <IconButton small onClick={() => {
                      if (open.id !== e.id) handleOpen(e);
                      handleOpenDialog('info')
                    }}>
                      <Info />
                    </IconButton>
                    <IconButton small color='#026df2' onClick={() => handleOpen(e)}>
                      {open.id === e.id ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                    </IconButton>
                  </Grid>
                </Grid>*/}
              </Grid>
            </Grid>
            {
              open.id === e.id && 
              <Grid item xs={12} padding={'8px 22px 8px 24px'} borderTop>
                <RowContent data={open} />
              </Grid>
            }
          </Grid>
        );
      })}
    </>
  );
}

Table.propTypes = {
  data: PropTypes.array,
  handleGoTo: PropTypes.func,
};
