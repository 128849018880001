import React from 'react';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContentText,
    DialogTitle,
} from "@mui/material";
import PropTypes from 'prop-types';



function AskDialog({ openDialog, label, handleClose }) {
    return (
        <Dialog
            open={openDialog === label}
            onClose={handleClose}
            maxWidth="md"
            fullWidth>
            <DialogTitle
                style={{
                    background: "#026DF2",
                    color: "white",
                    fontWeight: "900",
                    fontSize: '14'
                }}>
                CUSTOMER SUPPORT
                <Button
                    onClick={handleClose}
                    style={{
                        fontWeight: "900",
                        height: "30px",
                        borderRadius: "5px",
                        right: "-490px"
                    }}
                >
                    Close
                </Button>
            </DialogTitle>
            <DialogContentText>

            </DialogContentText>
            <DialogActions>

            </DialogActions>
        </Dialog>);
}

AskDialog.propTypes = {
    openDialog: PropTypes.string,
    label: PropTypes.string,
    handleClose: PropTypes.func,

};
export default AskDialog;