import React, { memo } from 'react';
import { Button, Grid, Grow, Typography } from '@mui/material';
import PropTypes from 'prop-types';

function NoData({ image, title, subtitle, buttonname, onClick }) {
  return (
    <Grow in style={{ transformOrigin: '0 0 0' }} {...{ timeout: 1000 }}>
      <Grid style={{ minHeight: '200px' }} container>
        <Grid container justify="center" style={{ marginBottom: '32px' }}>
          {image}
        </Grid>
        <Grid container justify="center">
          <Typography variant="h4" style={{ color: '#616161' }}>
            <strong>{title}</strong>
          </Typography>
        </Grid>
        <Grid container justify="center">
          <Typography variant="h6" style={{ color: '#7F96A1' }}>
            {subtitle}
          </Typography>
        </Grid>
        {buttonname ? (
          <Grid container justify="center" style={{ marginTop: '16px' }}>
            <Button variant="contained" color="primary" onClick={onClick}>
              {buttonname}
            </Button>
          </Grid>
        ) : null}
      </Grid>
    </Grow>
  );
}

NoData.propTypes = {
  image: PropTypes.any,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  buttonname: PropTypes.string,
  onClick: () => {},
};

export default memo(NoData);
