/* eslint-disable no-nested-ternary */
import React from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  IconButton,
  Popover,
  MenuItem,
  useMediaQuery,
} from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { options } from './constants';
import { Card, Grid, Typography } from './styledComponents';
import useHooks from './hooks';
export function Paginator({
  setoffset,
  setlimit,
  offset,
  limit,
  length,
  children,
  limitSelect,
  setlimitSelect,
  background,
}) {
  const xs = useMediaQuery('(min-width:700px)');
  const {
    openFilas,
    anchorEl,
    handleCloseFilas,
    handleSelectNumero,
    handleClick,
    handleBack,
    handleNext,
  } = useHooks(setoffset, setlimit, offset, limit, limitSelect, setlimitSelect);
  return (
    <>
      <Card 
      container 
      background={background} 
      justify="flex-end"
      >
        {children}
        <Grid item xs={12}>
          <Grid container justify="flex-end" style={{ padding: '3px 0 5px 0', placeContent: 'end' }} alignItems="center">
            <Grid item justify="center" font="14px">
              <Typography variant={xs ? 'subtitle1' : 'caption'}>
                Rows per page
              </Typography>
            </Grid>
            <Grid item>
              <Button style={{ width: '90%' }} onClick={handleClick}>
                <Grid container xs={6} justify="center">
                  <Typography>{limitSelect}</Typography>
                </Grid>
                <Grid container xs={6} justify="center">
                  <ArrowDropDownIcon />
                </Grid>
              </Button>
            </Grid>
          <Grid
            item
            justify="center"
            alignItems="center"
          >
            <Typography>
              {`${offset + 1}-${limit > length ? length : limit} de ${length} `}
            </Typography>
          </Grid>
          <Grid item>
            <Grid container >
            <Grid item xs={6}>
              <IconButton disabled={offset === 0} onClick={handleBack}>
                <ChevronLeftIcon />
              </IconButton>
            </Grid>
            <Grid item xs={6}>
              <IconButton disabled={limit >= length} onClick={handleNext}>
                <ChevronRightIcon />
              </IconButton>
            </Grid>
            </Grid>
          </Grid>
          
          </Grid>
        </Grid>
      </Card>
      <Popover
        open={openFilas}
        anchorEl={anchorEl}
        onClose={handleCloseFilas}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        {options.map(value => (
          <MenuItem key={value} onClick={() => handleSelectNumero(value)}>
            {value}
          </MenuItem>
        ))}
      </Popover>
    </>
  );
}
Paginator.propTypes = {
  setoffset: PropTypes.func,
  setlimit: PropTypes.func,
  offset: PropTypes.func,
  length: PropTypes.number,
  limit: PropTypes.func,
  children: PropTypes.any,
  limitSelect: PropTypes.any,
  setlimitSelect: PropTypes.any,
  background: PropTypes.any,
};
export default Paginator;
