import './App.css';
import React from 'react';

import { AppProvider } from './AppContext';
import AppUI from './Main';

function App() {
  return (
    <React.StrictMode>
        <AppProvider>
          <div style={{ backgroundColor: '#f5f5f5', minHeight: '100vh' }}>
            <AppUI />
          </div>
        </AppProvider>
    </React.StrictMode>
  );
}

export default App;
