import { gql } from "@apollo/client";


export const GET_LIST_DATA_RECORDS = gql`query WorkOrders($offset: Int!, $limit: Int!, $whereClause: work_orders_bool_exp!) {
  rows: work_orders(
    offset: $offset, 
    limit: $limit, 
    where: $whereClause, order_by: {created_at: desc}
  ) {
    folio {
      id
      formatted_folio
    }
    chassis_plate
    paper_folio {
      prefix
      folio
      paper {
        paper_date
        due_date
        total
      }
    }
    id
    address_from {
      city_string
    }
    address_to {
      city_string
    }
    consignment_notes{
      id
      documents(where: {paper_type: {_eq: "PDF"}}, limit: 1) {
        id
        url
        paper_type
      }
      xml_document: documents(where: {paper_type: {_eq: "XML"}}, limit: 1) {
        id
        url
        paper_type
      }
      folio {
        id
        folio_prefix
        formatted_folio
      }
    }
    status_code_name
    status {
      id
      code_name
      name
    }
    folio {
      id
      folio
    }
    inbond {
      id
      name
    }
    amount
    appointment_number
    carrier {
      id
      name
    }
    consignee {
      id
      name
    }
    move_type_code_name
    load_type {
      name
      code_name
    }
    container
    created_at
    created_by
    customer {
      id
      name
    }
    customer_reference
    equipment {
      id
      code
      equipment_type {
        id
        name
        size
        model
      }
    }
    delivery_required_date
    eta
    last_free_day
    mbl
    remark
    scale
    seal
    shipper {
      id
      name
    }
    parent_routes_code {
      id
      name
    }
    te
    te_expiration
    bill_id
    weight
    to_zone {
      id
      timezone
      zone_name
      address {
        city_string
      }
    }
    from_zone {
      id
      timezone
      zone_name
      address {
        city_string
      }
    }
    rfd
    driver_moves: driver_moves(order_by: {order :desc}) {
      id
      scale
      eta
      remarks
      appointment_date
      appointment_number
      order
      load_type {
        name
      }
      priority_code_name
      parent_routes_code {
        name
      }
      to_zone {
        id
        timezone
        zone_name
      }
      from_zone {
        id
        timezone
        zone_name
      }
      driver {
        id
        driver_license
        vehicle {
          id
          status_code_name
          unit_number
          insurance_carrier_id
          insurance_policy
          sct_permission_number
          sct_permission_type
          sat_transport_config_code
        }
        person {
          id
          full_name
          sat_zip_code
          street_address
          sat_country_code
          sat_state_code
          sat_city_code
          rfc
          sat_suburb_code
          sat_municipality_code
          sat_city_id
          sat_municipality_id
          sat_suburb_id
        }
      }
      appointment_time
      appointment_number
      schedule_date
      schedule_time
      created_at
      departure_date
      driver_move_type {
        name
      }
      equipment {
        id
        code
        equipment_type {
          id
          name
          size
        }
      }
      parent_routes_code {
        name
      }
      status {
        name
        color
        code_name
      }
      consignee {
        id
        name
      }
      load_type {
        id
        name
      }
      yard_arrival_id
      yard_departure_id
      finished_from
      finished_to
      last_free_day
    }
  }
  count: work_orders_aggregate(where: $whereClause) {
    aggregate {
      count
    }
  }
}`;

//TAB CURRENT MAIN LIST
export const GET_LIST_DATA = (role) => role === "consignee" ? gql `query DriverMoves($offset: Int!, $limit: Int!, $whereClause: driver_moves_bool_exp!) {
  rows: driver_moves(offset: $offset, limit: $limit, where: $whereClause) {
    id
    address_from {
      city_string
      __typename
    }
    address_to {
      city_string
      __typename
    }
    parent_routes_code {
      id
      name
      __typename
    }
    folio {
      id
      prefix
      folio
      __typename
    }
    chat_id
    empty_notify
    request_for_delivery
    schedule_date
    appointment_date
    appointment_number
    free_empty_return_until
    created_at
    bg_color
    remarks
    from_zone {
      id
      timezone
      zone_name
      formatted_address
      sat_country_code
      sat_state_code
      sat_zip_code
      sat_city_code
      sat_municipality_code
      sat_suburb_code
      street_name
      __typename
    }
    to_zone {
      id
      timezone
      zone_name
      formatted_address
      sat_country_code
      sat_state_code
      sat_zip_code
      sat_city_code
      sat_municipality_code
      sat_suburb_code
      street_name
      __typename
    }
    driver {
      id
      username
      driver_license
      vehicle {
        id
        status_code_name
        unit_number
        insurance_carrier_id
        insurance_policy
        sct_permission_number
        sct_permission_type
        sat_transport_config_code
        __typename
      }
      person {
        id
        full_name
        sat_zip_code
        street_address
        sat_country_code
        sat_state_code
        sat_city_code
        rfc
        sat_suburb_code
        sat_municipality_code
        sat_city_id
        sat_municipality_id
        sat_suburb_id
        __typename
      }
      __typename
    }
    distance_text
    distance_value
    duration_text
    duration_value
    arrival_date
    eta_string
    order
    id
    status {
      id
      name
      color
      code_name
      __typename
    }
    load_type {
      id
      name
      __typename
    }
    equipment {
      id
      equipment_type {
        id
        model
        __typename
      }
      __typename
    }
    formatted_address
    priority_code_name
    priority {
      name
      code_name
      __typename
    }
    consignment_notes {
      id
      folio {
        id
        folio_prefix
        __typename
      }
      pdf_document: documents {
        id
        url
        __typename
      }
      xml_document: documents {
        id
        url
        __typename
      }
      __typename
    }
    order_type_codename
    work_order {
      eta
      inbond {
        id
        name
        __typename
      }
      from_zone {
        zone_name
        address {
          city_string
          __typename
        }
        __typename
      }
      to_zone {
        zone_name
        address {
          city_string
          __typename
        }
        __typename
      }
      driver_moves(order_by: {order: desc}, where: {
        status_code: {
          _neq: "CANCELED"
        }
      }) {
        parent_route_code_name
        schedule_date
        load_type {
          name
          __typename
        }
        status {
          name
          code_name
          color
          __typename
        }
        created_at
        parent_routes_code {
          name
          __typename
        }
        from_zone {
          zone_name
          __typename
        }
        to_zone {
          zone_name
          __typename
        }
        __typename
      }
      type_code_name
      id
      te
      mbl
      scale
      last_free_day
      lfd_api
      chassis_number
      chassis_plate
      empty_notify
      equipment {
        id
        code
        equipment_type {
          id
          name
          size
          __typename
        }
        __typename
      }
      consignment_notes {
        id
        folio {
          id
          folio_prefix
          __typename
        }
        pdf_document: documents {
          id
          url
          __typename
        }
        xml_document: documents {
          id
          url
          __typename
        }
        __typename
      }
      move_type {
        id
        name
        __typename
      }
      inbond {
        id
        name
        __typename
      }
      from_zone {
        id
        zone_name
        formatted_address
        __typename
      }
      to_zone {
        id
        zone_name
        formatted_address
        __typename
      }
      folio {
        id
        formatted_folio
        __typename
      }
      ware {
        id
        __typename
      }
      request_delivery_date
      container
      from_id
      to_id
      customer_id
      customer {
        id
        name
        __typename
      }
      customer_reference
      shipper_id
      carrier_id
      created_at
      updated_at
      created_by
      move_type_code_name
      paper_folio {
        prefix
        folio
      }
      address_from {
        city_string
        __typename
      }
      address_to {
        city_string
        __typename
      }
      status {
        id
        name
        color
        code_name
        __typename
      }
      __typename
    }
    __typename
  }
  count: driver_moves_aggregate(where: $whereClause) {
    aggregate {
      count
      __typename
    }
    __typename
  }
}`:gql`query DriverMoves($offset: Int!, $limit: Int!, $whereClause: driver_moves_bool_exp!) {
  rows: driver_moves(offset: $offset, limit: $limit, where: $whereClause) {
    id
    address_from {
      city_string
    }
    address_to {
      city_string
    }
    parent_routes_code {
      id
      name
    }
    folio {
      id
      prefix
      folio
    }
    chat_id
    empty_notify
    request_for_delivery
    schedule_date
    appointment_date
    appointment_number
    free_empty_return_until
    created_at
    bg_color
    remarks
    from_zone {
      id
      timezone
      zone_name
      formatted_address
      sat_country_code
      sat_state_code
      sat_zip_code
      sat_city_code
      sat_municipality_code
      sat_suburb_code
      street_name
    }
    to_zone {
      id
      timezone
      zone_name
      formatted_address
      sat_country_code
      sat_state_code
      sat_zip_code
      sat_city_code
      sat_municipality_code
      sat_suburb_code
      street_name
    }
    driver {
      id
      username
      driver_license
      vehicle {
        id
        status_code_name
        unit_number
        insurance_carrier_id
        insurance_policy
        sct_permission_number
        sct_permission_type
        sat_transport_config_code
      }
      person {
        id
        full_name
        sat_zip_code
        street_address
        sat_country_code
        sat_state_code
        sat_city_code
        rfc
        sat_suburb_code
        sat_municipality_code
        sat_city_id
        sat_municipality_id
        sat_suburb_id
      }
    }
    distance_text
    distance_value
    duration_text
    duration_value
    arrival_date
    eta_string
    order
    id
    status {
      id
      name
      color
      code_name
    }
    load_type {
      id
      name
    }
    equipment {
      id
      equipment_type {
        id
        model
      }
    }
    formatted_address
    priority_code_name
    priority {
      name
      code_name
    }
    consignment_notes {
      id
      total
      total_with_tax
      sat_translate_tax
      sat_retention_tax
      bill_to
      folio {
        id
        folio_prefix
      }
      pdf_document: documents {
        id
        url
      }
      xml_document: documents {
        id
        url
      }
    }
    order_type_codename
    work_order {
      eta
      inbond{
        id
        name
      }
      from_zone{
        zone_name
        address {
          city_string
        }
      }
      to_zone{
        zone_name
        address {
          city_string
        }
      }
      driver_moves(order_by: {order: desc}, where: {
        status_code: {
          _neq: "CANCELED"
        }
      }) {
        parent_route_code_name
        schedule_date
        load_type {
          name
        }
        status {
          name
          code_name
          color
        }
        created_at
        parent_routes_code {
          name
        }
        from_zone {
          zone_name
        }
        to_zone {
          zone_name
        }
      }
      type_code_name
      id
      te
      mbl
      scale
      last_free_day
      lfd_api
      chassis_number
      chassis_plate
      empty_notify
      equipment {
        id
        code
        equipment_type {
          id
          name
          size
        }
      }
      consignment_notes {
        id
        total
        total_with_tax
        sat_translate_tax
        sat_retention_tax
        bill_to
        folio {
          id
          folio_prefix
        }
        pdf_document: documents {
          id
          url
        }
        xml_document: documents {
          id
          url
        }
      }
      move_type {
        id
        name
      }
      inbond {
        id
        name
      }
      from_zone {
        id
        zone_name
        formatted_address
      }
      to_zone {
        id
        zone_name
        formatted_address
      }
      folio {
        id
        formatted_folio
      }
      ware {
        id
      }
      request_delivery_date
      container
      from_id
      to_id
      amount
      price_to_bill
      customer_id
      customer {
        id
        name
      }
      customer_reference
      shipper_id
      carrier_id
      created_at
      updated_at
      created_by
      updated_by
      move_type_code_name
      paper_folio {
        prefix
        folio
        paper {
          paper_date
          due_date
          total
        }
      }
      address_from {
        city_string
      }
      address_to {
        city_string
      }
      status {
        id
        name
        color
        code_name
      }
    }
  }
  count: driver_moves_aggregate(where: $whereClause) {
    aggregate {
      count
    }
  }
}
`;

//TAB CURRENT -> INFO DIALOG
export const WORK_ORDER_BY_ID = gql`query get_work_order($id: uuid!) {
  item: work_orders_by_pk(id: $id) {
    id
    customer_reference
    customer {
      name
    }
    move_type {
      name
    }
    load_type{
      name
    }
    status_code_name
    type_code_name
    available_date
    do_received
    free_empty_return_until
    chassis_lfd
    delivery_date
    empty_notify
    request_delivery_date
    amount
    move_type_code_name
    mxc_dispatch
    vessel_name
    from_id
    from_zone_id
    customer_id
    chassis_line {
      id
      code_name
      name
    }
    chassis_sat_type {
      id
      code_name
      sat_code
      name
    }
    carrier {
      id
      name
    }
    chassis_plate
    chassis_plate_state {
      name
      iso_name
    }
    chassis_number
    container
    created_at
    created_by
    customer {
      id
      name
    }
    customer_reference
    equipment {
      id
      code
      seals(order_by: { created_at: desc }) {
        id
        folio
      }
      equipment_type {
        id
        name
        size
      }
    }
    load_type {
      id
      code_name
      name
    }
    delivery_required_date
    eta
    last_free_day
    mbl
    remark
    scale
    seal
    shipper {
      id
      name
    }
    te
    te_expiration
    bill_id
    weight
    unit_type_id
    unit_type {
      id
      name
      shortname
    }
    inbond {
      id
      name
    }
    from_zone {
      id
      timezone
      zone_name
    }
    to_zone {
      id
      timezone
      zone_name
    }
    rfd
    driver_moves(order_by: { order: asc }, where: {
      status_code: {
        _neq: "CANCELED"
      }
    }) {
      id
      order
      status_code
      from_zone {
        id
        name: zone_name
      }
      to_zone {
        id
        timezone
        zone_name
      }
      folio {
        id
        prefix
        folio
      }
      schedule_date
      departure_date
      arrival_date
      remarks
      week_of_payment
      payroll_id
      created_at
      driver {
        id
        person {
          id
          full_name
        }
      }
      work_order_id
      parent_routes_code_id
      parent_routes_code {
        id
        name
      }
      status {
        id
        name
        color
      }
      payroll_status {
        id
        name
        color
      }
      order_type_codename
      workflow_step_codename
      empty_notify
      equipment_id
      customer_id
      free_empty_return_until
      load_type {
        id
        code_name
        name
      }
      yard_arrival {
        name: zone_name
      }
      yard_departure {
        name: zone_name
      }
    }
  }
}
`;
//TAB CURRENT -> DOCUMENTS DIALOG
export const WORK_ORDER_DOCUMENTS_BY_ID = gql`
query customerPortalWorkOrder(
  $container: String!
  $guest: Boolean
) {
  info: customerPortalWorkOrder(container: $container, guest: $guest) {
    result
  }
}`;

export const WO_DOCUMENTS = gql`query WorkOrders($id: uuid) {
  result: work_orders(where: {id: {_eq: $id}}) {
    chassis_plate
    chassis_plate_state {
      name
    }
    status_code_name
    delivered
    status {
      id
      code_name
      name
      color
    }
    order_type {
      name
    }
    folio {
      id
      folio
    }
    delivery_order {
      id
      do_bucket_url
      delivery_order_type {
        id
        name
        color
      }
    }
    unit_type {
      shortname
    }
    inbond {
      id
      name
    }
    id
    amount
    appointment_number
    carrier {
      id
      name
    }
    consignee {
      id
      name
    }
    container
    created_at
    created_by
    customer {
      id
      name
    }
    customer_reference
    equipment {
      id
      code
      equipment_type {
        id
        name
        size
        model
      }
    }
    bill {
      id
      amount
      bill_reference
    }
    delivery_required_date
    eta
    last_free_day
    mbl
    remark
    scale
    seal
    shipper {
      id
      name
    }
    parent_routes_code {
      id
      name
    }
    te
    te_expiration
    user_created_by {
      id
    }
    bill_id
    weight
    to: address_to {
      id
      formatted_address
      city_string
      zones {
        id
        zone_name
      }
    }
    from: address_from {
      id
      formatted_address
      city_string
      zones {
        id
        timezone
        zone_name
      }
    }
    rfd
    events: work_order_events(order_by: {created_at: desc}) {
      id
      value: accomplished_at
      event {
        id
        name
      }
    }
    history: driver_moves(order_by: {order: asc}) {
      id
      order
      from_zone {
        zone_name
      }
      to_zone {
        zone_name
      }
      priority_code_name
      parent_routes_code {
        name
      }
      to: address_to {
        formatted_address
        zones {
          id
          zone_name
        }
      }
      from: address_from {
        formatted_address
        city_string
        zones {
          id
          zone_name
        }
      }
      document_logs(where: {document: {id: {_neq: null}}}, order_by: {document: {created_at: asc}}) {
        id
        driver_move {
          from_zone {
            zone_name
          }
          to_zone {
            zone_name
          }
        }
        status {
          id
          name
          code_name
          color
        }
        document {
          id
          name
          code_name
          signature
        }
        file {
          id
          name
          created_at
          files_details {
            id
            description
            url
            file_location
            created_at
          }
        }
      }
      appointment_date
      appointment_time
      appointment_number
      schedule_date
      schedule_time
      created_at
      departure_date
      driver_move_type {
        name
      }
      equipment {
        id
        code
        equipment_type {
          id
          name
          size
        }
      }
      parent_routes_code {
        name
      }
      status_code
      status {
        name
        color
      }
      consignee {
        id
        name
      }
      load_type {
        id
        name
      }
      yard_arrival_id
      yard_departure_id
      finished_from
      finished_to
      last_free_day
    }
    from_zone {
      zone_name
    }
    to_zone {
      zone_name
    }
  }
}
`