import React from 'react';
import {
  Dashboard,
  AccountBox,
  DirectionsCar,
  SupportAgent
} from '@mui/icons-material';
export const SidebarData = [
  {
    title: 'Dashboard',
    path: '/',
    icon: <Dashboard style={{color: 'white', fontSize: '30px'}}/>,
    cName: 'nav-text'
  },
  {
    title: 'Drivers',
    path: '/drivers',
    icon: <DirectionsCar style={{color: 'white', fontSize: '30px'}}/>,
    cName: 'nav-text'
  },
  {
    title: 'Profile',
    path: '/profile',
    icon: <AccountBox style={{color: 'white', fontSize: '30px'}}/>,
    cName: 'nav-text'
  },
  /*
  {
    title: 'Mensajes',
    path: '/mensajes',
    icon: <LocalGroceryStoreIcon />,
    cName: 'nav-text'
  },
  */
  {
    title: 'Support',
    path: '/support',
    icon: <SupportAgent style={{color: 'white', fontSize: '30px'}}/>,
    cName: 'nav-text'
  }
];
