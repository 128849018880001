import { useState, createContext, useEffect } from 'react';
import { useQuery } from '@apollo/client';
import { GET_LIST_DATA } from '../../../../graphql/queries/drivers';
import { helperMapData, helperWhereClause } from './helpers';
import { get } from 'lodash';

// CREATE WHOLE COMPONENT STATE
const DriversCurrentContext = createContext();

function DriversCurrentProvider(props) {
 
  const [openSettings, setOpenSettings] = useState(false);

  const [loadingMap, setLoadingMap] = useState(true);

  const [openLocation, setOpenLocation] = useState(false);

  const [dataLocation, setDataLocation] = useState(null)

  // HEADER FILTER COLUMNS
  const [searchArgs, setsearchArgs] = useState({
    reference: '',
    status: '',
    container: '',
    type: '',
    moveType: '',
    routeStatus: '',
    consignee: '',
    te: '',
    invoiceNumber: '',
    invoiceDate: '',
    invoiceTotal: '',
    dueDate: '',
    from: '',
    to: '',
    size: '',
    lfd: '',
    emptyNotify: '',
    inbondVia: '',
    mbl: '',
    apptDate: '',
    apptNumber: '',
    driver: '',
    plate: '',
    folio: '',
    scale: '',
    eta: '',
    days: '',
    remarks: '',
  });

  const role = localStorage.getItem('role');

  const [columns, setColumns] = useState({
    reference: true,
    status: false,
    container: true,
    type: true,
    moveType: true,
    routeStatus: true,
    consignee: role === "consignee" ? false : true,
    te: false,
    from: true,
    to: role === "consignee" ? true : false,
    size: false,
    lfd: false,
    emptyNotify: false,
    inbondVia: false,
    mbl: false,
    apptDate: false,
    apptNumber: false,
    driver: false,
    plate: false,
    folio: false,
    scale: false,
    eta: false,
    days: false,
    remarks: false,
  });

  const [dataColums, setDataColums] = useState([
    {
      codeName: "reference",
      name: "REFERENCE",
      width: 'calc(12vw - 35px)',
      date: false,
    },
    {
      codeName: "status",
      name: "STATUS",
      width: '125px',
      date: false,
    },
    {
      codeName: "container",
      name: "CONTAINER",
      width: 'calc(16vw - 30px)',
      date: false,
    },
    {
      codeName: "type",
      name: "TYPE",
      width: 'calc(12vw - 40px)',
      date: false,
    },
    {
      codeName: "moveType",
      name: "MOVE TYPE",
      width: 'calc(12vw - 40px)',
      date: false,
    },
    {
      codeName: "routeStatus",
      name: "ROUTE STATUS",
      width: 'calc(12vw - 30px)',
      date: false,
    },
    {
      codeName: "consignee",
      name: "CONSIGNEE",
      width: "calc(20vw - 20px)",
      date: false,
    },
    {
      codeName: "te",
      name: "T&E",
      width: '125px',
      date: false,
    },
    {
      codeName: "from",
      name: "FROM",
      width: role === "consignee" ? 'calc(18vw - 24px)' : 'calc(16vw - 20px)',
      date: false,
    },
    {
      codeName: "to",
      name: "TO",
      width: role === "consignee" ? 'calc(18vw - 24px)' : '125px',
      date: false,
    },
    {
      codeName: "size",
      name: "SIZE",
      width: '125px',
      date: false,
    },
    {
      codeName: "lfd",
      name: "LFD",
      width: '125px',
      date: true,
    },
    {
      codeName: "emptyNotify",
      name: "EMPTY NOTIFY",
      width: '125px',
      date: true,
    },
    {
      codeName: "inbondVia",
      name: "INBOND VIA",
      width: '125px',
      date: false,
    },
    {
      codeName: "mbl",
      name: "MBL",
      width: '125px',
      date: false,
    },
    {
      codeName: "apptDate",
      name: "APPT. DATE",
      width: '125px',
      date: true,
    },
    {
      codeName: "apptNumber",
      name: "APPT. NUMBER",
      width: '125px',
      date: false,
    },
    {
      codeName: "driver",
      name: "DRIVER",
      width: '125px',
      date: false,
    },
    {
      codeName: "plate",
      name: "PLATE",
      width: '125px',
      date: false,
    },
    {
      codeName: "folio",
      name: "FOLIO",
      width: '125px',
      date: false,
    },
    {
      codeName: "scale",
      name: "SCALE",
      width: '125px',
      date: false,
    },
    {
      codeName: "eta",
      name: "ETA",
      width: '125px',
      date: true,
    },
    {
      codeName: "days",
      name: "DAYS",
      width: '125px',
      date: false,
    },
    {
      codeName: "remarks",
      name: "REMARKS",
      width: '125px',
      date: false,
    },
  ])

  const handleColumnsArgs = (data, label) => {
    setoffset(0);
    const aux = { ...columns };
    aux[label] = data;
    setColumns(aux);
  };

  const handlesearchArgs = (data, label, type) => {
    setoffset(0);
    const aux = { ...searchArgs };
    if(type === 'date'){
      aux[label] = data;
    }else{
      aux[label] = data;
    }
    setsearchArgs(aux);
  };

  // NAVIGATION
  function handleGoTo(option, id) {
    switch (option) {
      case 1:
        // history.push(`drivers/`);
        break;
      case 2:
        // history.push(`driver/${id}`);
        break;
      default:
        break;
    }
  }

  // PAGINATION
  const [offset, setoffset] = useState(0);
  const [limitSelect, setlimitSelect] = useState(12);
  const [limit, setlimit] = useState(12);

  
  // QUERY VARIABLES
  const whereClause = helperWhereClause(searchArgs);
  const variables = {
    whereClause,
    limit: limitSelect,
    offset,
  };
  // QUERY DATA
  const { data: queryList, loading: loadingList } = useQuery(
    GET_LIST_DATA(role),
    {
      variables,
    },
  );
  
  // MAP DATA
  const dataList = loadingList ? [] : helperMapData(get(queryList, "rows", []));
  const dataLength = loadingList ? 0 : get(queryList, 'count.aggregate.count', 0);

  // HANDLE ROW ON OPEN
  const [open, setOpen] = useState({id: ''});
  const handleOpen = e => setOpen(e.id === open.id ? {id: ''} : e);

  //HANDLE DIALOGS
  const [openDialog, setOpenDialog] = useState('');
  const handleOpenDialog = type => setOpenDialog(type);
  const handleCloseDialog = type => setOpenDialog('');

  const [activeButtons, setActiveButtons] = useState({
    infoDo: true,
    documents: true,
    billing: true,
    ask: true
  })

  const handleActiveButtons = (data, label) => {
    const aux = { ...activeButtons };
    
    aux[label] = data;

    setActiveButtons(aux);
  };

  return (
    <DriversCurrentContext.Provider value={{
      searchArgs,
      handlesearchArgs,
      handleGoTo,
      offset,
      setoffset,
      limit,
      setlimit,
      data: dataList,
      loading: loadingList,
      length: dataLength,
      limitSelect,
      setlimitSelect,
      open,
      handleOpen,
      openDialog,
      handleOpenDialog,
      handleCloseDialog,
      columns,
      handleColumnsArgs,
      activeButtons,
      handleActiveButtons,
      openSettings,
      setOpenSettings,
      dataColums,
      openLocation, 
      setOpenLocation,
      dataLocation, 
      setDataLocation,
      loadingMap,
      setLoadingMap
    }}>
    {props.children}
  </DriversCurrentContext.Provider>
  );
}

export {DriversCurrentContext, DriversCurrentProvider}