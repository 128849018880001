import { useQuery } from '@apollo/client';
import { helperMapData } from './helpers';
import { WO_DOCUMENTS } from '../../../../../../../graphql/queries/drivers';
import { useState } from 'react';
import { get } from 'lodash';

export default function useDialogHooks(container, id) {
    
    //GET MAIN DATA 
    const { data, loading } = useQuery(
        WO_DOCUMENTS,
        {
            variables: {
                id: id,
                //container: container,
                //guest: false
            },
        },
    );
    
    //MAP DATA
    const dataDialog = helperMapData(get(data, "result[0]"));

    //HANDLE ROW OPEN
    const rowReset = { index: null, view: null }
    const [fileOpen, setFileOpen] = useState(rowReset);
    const handleFileOpen = (view, index) => {
        if (index || index === 0) {
            if (index === fileOpen.index) {
                setFileOpen(rowReset);
            }else{
                setFileOpen({ index, view });
            }
        }else{
            setFileOpen({ index: fileOpen.index, view });
        }

    };
    const handleFileClose = () => setFileOpen(rowReset);

    return {
        dataDialog,
        loading,
        fileOpen,
        handleFileOpen,
        handleFileClose
    };
}