import React, { useContext } from 'react';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
} from "@mui/material";
import get from 'lodash/get';
import PropTypes from 'prop-types';
import { TextBoxInfo, DialogTitle } from '../styled';
import { formFields } from './hooks/constants';
import useDialogHooks from './hooks';
import Loading from '../../../../../../components/Loading';
import { DriverRecordsContext } from '../../../hooks';

function InfoDialog({ openDialog, label, handleClose }) {
    const {
        // Open is row open
        open,
    } = useContext(DriverRecordsContext);
    
    const {
        dataDialog,
        loading,
    } = useDialogHooks(open.id);

    return (<Dialog
        open={openDialog === label}
        onClose={handleClose}
        maxWidth="xl"
        fullWidth
    >
        <DialogTitle>
            INFORMATION / DELIVERY ORDER
        </DialogTitle>
        <DialogContent style={{borderBottom: '1px solid #676666'}}>
            {loading && <Loading />}
            {!loading && formFields.map(field =>
                <TextBoxInfo
                    label={field.label}
                    defaultValue={get(dataDialog, field.name) || '---'}
                    InputProps={{ readOnly: true }}
                    style={{ width: field.width }}
                />
                )}
        </DialogContent>
        <DialogActions>
            <Button
                onClick={handleClose}
                variant="contained"
                color="primary"
                style={{
                    fontWeight: "900",
                    height: "30px",
                    borderRadius: "5px",
                }}
            >
                Close
            </Button>
        </DialogActions>
    </Dialog>);
}

InfoDialog.propTypes = {
    openDialog: PropTypes.string,
    label: PropTypes.string,
    handleClose: PropTypes.func,

};
export default InfoDialog;