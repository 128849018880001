import React from 'react';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    Grid,
} from "@mui/material";
import PropTypes from 'prop-types';
import { DialogTitle, DialogTabButton } from '../styled';
import useDialogHooks from './hooks';
import Header from './containers/header';
import FileNoteRequest from '../../../../../../components/FileNoteRequest';
import LoadingIndicator from '../../../../../../components/Loading';


function BillingDialog({ openDialog, label, handleClose }) {
    const {
        // TABS
        dialogTabOptions,
        dialogTab,
        handleDialogTab,
        openDetail,
        handleOpenDetail,
        detailTab,
        handleDetailTab,
        handleCloseDialog,
        // DATA
        loading,
        open
    } = useDialogHooks({ handleClose });

    console.log(open)
    
    return (
        <Dialog
            open={openDialog === label}
            onClose={handleClose}
            maxWidth="md"
            fullWidth>
            <DialogTitle padding={'0 0 0 10px'}>
                {dialogTabOptions.map(element => <DialogTabButton
                    key={element.id}
                    active={element.id === dialogTab}
                    onClick={() => handleDialogTab(element.id)}
                >{element.name}</DialogTabButton>)}
            </DialogTitle>
            {loading && <DialogContent style={{ padding: '0 0 10px' }}>
                <LoadingIndicator />
            </DialogContent>}
            {!loading && <DialogContent>
                <Grid container justifyContent={'center'} style={{ marginTop: '20px' }}>
                    <Header handleOpenDetail={handleOpenDetail} canOpenDetail data={open}/>
                    {openDetail && <FileNoteRequest 
                        file
                        note
                        request
                        detailTab={detailTab}
                        handleDetailTab={handleDetailTab}
                    />}
                </Grid>
            </DialogContent>}
            <DialogActions style={{borderTop: '1px solid #676666'}}>
                <Button
                    onClick={handleCloseDialog}
                    variant={'contained'}
                    style={{ fontWeight: "900" }}
                >
                    Close
                </Button>
            </DialogActions>
        </Dialog>);
}

BillingDialog.propTypes = {
    openDialog: PropTypes.string,
    label: PropTypes.string,
    handleClose: PropTypes.func,

};
export default BillingDialog;