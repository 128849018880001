import { ArrowForward } from '@mui/icons-material';
import React from 'react';
import { Grid, Typography } from '../../../styled';
import { StatusLabel } from '../styled';

function RowContent({ data }) {
    return (
        <Grid container justifyContent="center" alignItems="start" padding="0 10px" style={{ minHeight: 150 }}>
            <Grid item>
                <Grid container spacing={1} justifyContent="start" style={{width: 'min-content'}}>
                    <Grid item>
                       <StatusLabel backgroundColor={data.details.status.color}>{data.details.status.name}</StatusLabel>
                    </Grid>
                        
                    <Grid item >
                        <Typography font="14px" noWrap>
                            CONTAINER: {data.container}
                        </Typography>
                    </Grid>

                    <Grid item>
                        <Typography font="12px">
                            From: {data.details.from}
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Typography font="12px">
                            To: {data.details.to}
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Grid container justifyContent="start" alignItems="center">
                            <Grid item>
                                <Typography font="12px" color="primary" titleText noWrap>
                                    {data.details.currentFrom}
                                </Typography>
                            </Grid>
                            {
                                data.details.currentTo !== "Not found" && (
                                    <Grid item>
                                        <ArrowForward style={{ color: "#8602b5", fontSize: 14, margin: '0 5px' }} />
                                    </Grid>
                                )
                            }
                            {
                                data.details.currentTo !== "Not found" && (
                                    <Grid item>
                                        <Typography font="12px" color="primary" titleText noWrap>
                                            {data.details.currentTo}
                                        </Typography>
                                    </Grid>
                                )
                            }
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
}

export default RowContent;