import { get } from 'lodash';
import moment from 'moment';

// GET COLOR BASED ON CODE NAME
const getColor = (color) => {
    switch (color) {
        case 'STARTED':
            return 'green';
        case 'closed_work_order': 
            return 'blue';
        case 'CANCELED': 
            return 'red';
        case 'AT_VESSEL':
            return 'grey';
        case 'AT_RAIL':
            return 'grey';
        case 'BILLED':
            return '';
        case 'DELIVERED':
            return 'green';
        case 'CLOSED':
            return 'green';
        case 'ON_HOLD':
            return '#ff6224';
        case 'RELEASED':
            return 'green';
        case 'IN_TRANSIT_WO':
            return 'purple';
        case 'AVAILABLE_WO':
            return '#666699';
        case 'AVAILABLE':
            return '#989595';
        case 'IN_YARD_WO':
            return 'green';
        case 'EMPTY_NOTIFIED_WO':
            return 'green';
        case 'APPT_AT_TERMINAL':
            return '#666699';
        case 'DELIVERY_TO_ARRIVED':
            return '#FF0080';
        case 'DISPATCHED':
                return 'green';
        default:    
            return '#026df2';
    }
};
// TO ADD COLUMN: CONSOLE LOG LIST AND ADD TO PUSH STATEMENT
export const helperMapData = list => {
    const aux = [];
    
    list.forEach(element => {
        const auxDriverMoves = [];

        const driverMoveData = get(element, 'driver_moves', []);

        if (driverMoveData.length > 0) {
            driverMoveData.forEach(driverMove => {
                auxDriverMoves.push({
                    name: get(driverMove, 'status.name', "Not found"),
                    color: getColor(get(driverMove, 'status.code_name', "Not found")),
                    date: get(driverMove, 'schedule_date') ? moment(get(driverMove, 'schedule_date')).format('MM/DD/YYYY HH:mm') : moment(get(driverMove, 'created_at')).format('MM/DD/YYYY HH:mm'),
                    description: `${get(driverMove, 'parent_routes_code.name', 'Not found')} / ${get(driverMove, 'load_type.name', 'Not found')}`,
                    from:  get(driverMove, 'from_zone.zone_name', "Not found"),
                    to: get(driverMove, 'to_zone.zone_name'),
                    statusCodeName: get(driverMove, 'status.code_name', "Not found"),
                })
            });
        }
        aux.push({
            id: get(element, 'id'),
            consignmentNotes: get(element, 'consignment_notes', []),
            invoice: get(element, "paper_folio"),
            status: get(element, 'status.name', ""),
            status_color: getColor(get(element, 'status.code_name', "")),
            reference: get(element, 'customer_reference', ""),
            container: get(element, 'container', ""),
            type: get(element, 'status.name') === "Canceled" && !get(element, 'driver_moves[0].load_type.name') ? "CANCELED" : get(element, 'driver_moves[0].load_type.name', ''),
            moveType: get(element, 'move_type_code_name',''),
            routeStatus: get(element, 'status.name') === "Canceled" && !get(element, 'driver_moves[0].parent_routes_code.name') ? "CANCELED" : get(element, 'driver_moves[0].parent_routes_code.name', "IN PROCESS"),
            consignee: get(element, 'status.name') === "Canceled" ? "CANCELED" : get(element, 'to_zone.zone_name', ""),
            invoiceNumber: get(element, 'status.name') === "Canceled" ? "CANCELED" : get(element, 'paper_folio.prefix', '') + get(element, 'paper_folio.folio', 'PENDING'),
            invoiceDate: get(element, 'status.name') === "Canceled" ? "CANCELED" : get(element, 'paper_folio.paper.paper_date', '') ? moment(get(element, 'paper_folio.paper.paper_date', '')).format('MM/DD/YYYY'): 'PENDING',
            invoiceTotal: get(element, 'status.name') === "Canceled" ? "CANCELED" : get(element,'paper_folio.paper.total', 'PENDING'),
            dueDate: get(element, 'status.name') === "Canceled" ? "CANCELED" : get(element, 'paper_folio.paper.due_date', '') ? moment(get(element, 'paper_folio.paper.due_date', '')).format('MM/DD/YYYY'):'PENDING', 
            te: get(element, 'te'),
            from: get(element, 'from_zone.zone_name'),
            to: get(element, 'to_zone.zone_name'),
            size: get(element, 'equipment.equipment_type.size'),
            lfd: get(element, 'last_free_day') ? moment(get(element, 'last_free_day')).format('MM/DD/YYYY') : "",
            emptyNotify: get(element, 'empty_notify') ? moment(get(element, 'empty_notify')).format('MM/DD/YYYY') : "",
            inbondVia: get(element, 'inbond.name'),
            mbl: get(element, 'mbl'),
            apptDate: get(element, 'work_order.schedule_date') ? moment(get(element, 'driver_moves[0].schedule_date')).format('MM/DD/YYYY') : "", //
            apptNumber: get(element, 'driver_moves[0].appointment_number'),
            driver: get(element, 'driver_moves[0].driver.person.full_name'),
            plate: get(element, 'chassis_plate'),
            folio: get(element, 'folio.formatted_folio'),
            scale: get(element, 'driver_moves[0].scale'),
            eta: get(element, 'eta') ? moment(get(element, 'eta')).format('MM/DD/YYYY')  : "",
            days: "",
            remarks: get(element, 'driver_moves[0].remarks'), 
            details: {
                id: get(element, 'id', "Not found"),
                status: {   
                    name: get(element, 'status.name', "Not found"),
                    color: getColor(get(element, 'status.code_name', "Not found")),
                },
                from: get(element, 'from_zone.zone_name', "Not found"),
                to: get(element, 'to_zone.zone_name', "Not found"),
                currentFrom: get(element, 'from_zone.address.city_string', "Not found"),
                currentTo: get(element, 'to_zone.address.city_string', "Not found"),
                steps: auxDriverMoves,
            },
            raw: element
        })
    })
    return aux;
};

//TO CONNECT NEW COLUMNS ADD IF AND WHERE STATEMENT
export const helperWhereClause = (args) => {

    const customerId = localStorage.getItem("company_id");

    const aux = [
        { customer_id: { _eq: customerId } },
        {
            _or: [
                { status_code_name: { _eq: "BILLED" } },
                { status_code_name: { _eq: "closed_work_order" } },
                { status_code_name: { _eq: "CANCELED" } },
                { status_code_name: { _eq: "active_work_order" } },
                { status_code_name: { _eq: "AT_RAIL" } },
                { status_code_name: { _eq: "AT_VESSEL" } },
            ]
        }
    ];

    const role = localStorage.getItem('role');

    if(role === "consignee") {
        aux.push({ to_zone: { id: { _eq: localStorage.getItem('sub_company_id') } }})
    }
    
    if (args.reference) aux.push({ customer_reference: { _ilike: `%${args.reference}%` } });
    if (args.container) aux.push({ container: { _ilike: `%${args.container}%` } });
    if (args.type) aux.push({ driver_moves: { load_type: { name: { _ilike: `%${args.type}%` } } }});
    if (args.moveType) aux.push({ move_type_code_name: { _ilike: `%${args.moveType}%` } });
    if (args.routeStatus) aux.push({driver_moves:{ parent_routes_code: { name: { _ilike: `%${args.routeStatus}%` } }}});
    if (args.consignee) aux.push({  to_zone: { zone_name: { _ilike: `%${args.consignee}%` }}  });
    if (args.status) aux.push({ status: { name: { _ilike: `%${args.status}%` } } });
    if (args.te) aux.push({ te: { _ilike: `%${args.te}%` } });
    if (args.from) aux.push({ from_zone: { zone_name:{ _ilike: `%${args.from}%` } } });
    if (args.to) aux.push({ to_zone: { zone_name: { _ilike: `%${args.to}%` } } });
    if (args.size) aux.push({ equipment: { equipment_type: { size: { _eq: args.size } } } });
    if (args.lfd) aux.push({ _and: [
        { last_free_day: { _gte: `${args.lfd}T00:00:00+00:00` } }, 
        { last_free_day: { _lte: `${args.lfd}T23:59:59+00:00` } }
    ] });
    if (args.emptyNotify) aux.push({ _and: [
        { empty_notify: { _gte: `${args.emptyNotify}T00:00:00+00:00` }}, 
        { empty_notify: { _lte: `${args.emptyNotify}T23:59:59+00:00` }}
    ] });
    if (args.inbondVia) aux.push({ inbond: { name: { _ilike: `%${args.to}%` } } });
    if (args.mbl) aux.push({ mbl: { _ilike: `%${args.mbl}%` } });
    if (args.mbl) aux.push({ mbl: { _ilike: `%${args.mbl}%` } });
    if (args.apptDate) aux.push({ _and: [
        { appointment_date: { _gte: `${args.apptDate}T00:00:00+00:00` } }, 
        { appointment_date: { _lte: `${args.apptDate}T23:59:59+00:00` } }
    ] });
    if (args.apptNumber) aux.push({ appointment_number: { _ilike: `%${args.apptNumber}%` } });
    if (args.driver) aux.push({ driver: { person: { full_name:{ _ilike: `%${args.driver}%` }}} });
    if (args.plate) aux.push({ chassis_plate:{ _ilike: `%${args.plate}%` }} );
    if (args.folio) aux.push({ folio: { formatted_folio: { _ilike: `%${args.folio}%` }}} );
    if (args.scale) aux.push({ scale:{ _ilike: `%${args.scale}%` }} );
    if (args.eta) aux.push({ _and: [
        { eta: { _gte: `${args.eta}T00:00:00+00:00` } }, 
        { eta: { _lte: `${args.eta}T23:59:59+00:00` } }
    ] });
    if (args.remarks) aux.push({ remarks: { _ilike: `%${args.remarks}%` } });


    if(args.invoiceNumber)aux.push({paper_folio: { folio_prefix: {_ilike: `%${args.invoiceNumber}%`}}});
    if(args.invoiceDate) aux.push({ _and: [
        { paper_folio: { paper: { paper_date: { _gte: `${args.invoiceDate}T00:00:00+00:00` }}} }, 
        { paper_folio: { paper: { paper_date: { _lte: `${args.invoiceDate}T23:59:59+00:00` }}} }
    ] });
    // get(element, 'paper_folio.paper.paper_date', '') paper_folio.paper.total
    if(args.invoiceTotal)aux.push({ paper_folio: { paper: { total: {_eq: args.invoiceTotal }}}});
    if (args.dueDate) aux.push({ _and: [
        { paper_folio: { paper: { due_date: { _gte: `${args.dueDate}T00:00:00+00:00` }}} }, 
        { paper_folio: { paper: { due_date: { _lte: `${args.dueDate}T23:59:59+00:00` }}} }
    ] });

    //paper_folio.paper.due_date
    const clause = { _and: aux };
    return clause;
};