import { createContext, useEffect, useState } from "react";
import { auth } from '../service/Firebase';
import { 
    onAuthStateChanged,
    signInWithPopup,
    GoogleAuthProvider,
    signOut
} from "firebase/auth";
import { useNavigate } from "react-router-dom";
import { get } from "lodash";
import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
} from "@apollo/client";
import 'firebase/auth';

export const AuthContext = createContext();

export function AuthProvider({ children }) {
    const navigate = useNavigate()
    const [userLogin, setUserLogin] = useState(null)
    const [token, setToken] = useState(null)

    // useEffect(() => {
    //   onAuthStateChanged(auth, (currentuser) => {
    //     console.log("Auth", currentuser);
    //     if(currentuser) {
    //       requestLogin(currentuser)
    //     } else {
    //       setUserLogin(null);
    //     }
    //   });
    // }, []);

    useEffect(() => {
      const unsubscribe = onAuthStateChanged(auth, (user) => {
        setUserLogin(user);
      });
  
      return unsubscribe;
    }, []);

    useEffect(() => {
      if(userLogin) {
        requestLogin(userLogin)
      }
    }, [userLogin])

    const requestLogin = async (dataResult) => {
        console.log("DATA: ", dataResult)
        const requestOptions = {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              Accept: 'application/json',
            },
            body: JSON.stringify({
              email: dataResult.email,
            }),
          };
            
        const response = await fetch(
          "https://us-central1-bali-lbth.cloudfunctions.net/google_jwt_20",
          // "https://us-central1-dexone.cloudfunctions.net/google_jwt_20",
          requestOptions,
        );
        const letData = await response.json();
        console.log(letData)

        if(get(letData, 'token')) {
            console.log("LOGIN")
            setUserLogin(dataResult)
            setToken(get(letData, 'token'))

            localStorage.setItem('token', get(letData, 'token'));
            localStorage.setItem('fullname', get(letData, 'user.fullname'));
            localStorage.setItem('role', get(letData, 'user.role.code_name'))
            
            if(get(letData, 'user.role.code_name') === "customer") {
              localStorage.setItem('company_id', get(letData, 'user.company.id'));
              localStorage.setItem('company_name', get(letData, 'user.company.name'));
            } else if(get(letData, 'user.role.code_name') === "consignee"){
              localStorage.setItem('company_id', get(letData, 'user.consignee.customer_id'));
              localStorage.setItem('company_name', get(letData, 'user.consignee.name'));
              localStorage.setItem('sub_company_id', get(letData, 'user.consignee.to_zone_id'))
            }

            navigate ('/current')
        } else {
            setUserLogin(null)
        }
    }
    
    const login = () => {
      signOut(auth).then(() => {
        localStorage.clear()
        const provider = new GoogleAuthProvider();
        provider.setCustomParameters({
          prompt: 'select_account',
        });
        signInWithPopup(auth, provider);
      }).catch((error) => {
          console.log("Signed out successfully")
      });
    };

    const cerrarSesion = () => {
      signOut(auth).then(() => {
          localStorage.clear()
          navigate('/sign-in')
      }).catch((error) => {
          console.log("Signed out successfully")
      });
    }

    const client = new ApolloClient({
      uri: 'https://has.balidexone.io/v1/graphql',
      headers: {
        "content-type": "application/json",
        "Hasura-Client-Name": "hasura-console",
        "Authorization": `Bearer ${token || localStorage.getItem('token')}`
        //"x-hasura-admin-secret": "BestUygdBhjbjc677346tg"
      },
      cache: new InMemoryCache()
    });

    return (
        <ApolloProvider client={client}>
            <AuthContext.Provider value={{ login, userLogin, cerrarSesion }}> 
                {children}
            </AuthContext.Provider>
        </ApolloProvider>
    );
}