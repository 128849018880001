/* eslint-disable no-nested-ternary */
/* eslint-disable no-underscore-dangle */
import { useState } from 'react';
export default function useHooks(
  setoffset,
  setlimit,
  offset,
  limit,
  limitSelect,
  setlimitSelect,
) {
  const [openFilas, setOpenFilas] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  function handleCloseFilas() {
    setOpenFilas(false);
  }
  function handleSelectNumero(params) {
    setoffset(0);
    setlimit(params);
    if (setlimitSelect) setlimitSelect(params);
    setOpenFilas(false);
  }
  const handleClick = event => {
    setOpenFilas(true);
    setAnchorEl(event.currentTarget);
  };

  function handleBack() {
    let aux = 0;
    let aux2 = 0;
    aux = offset - limitSelect;
    aux2 = limit - limitSelect;
    setoffset(aux);
    setlimit(aux2);
  }

  function handleNext() {
    const aux = offset + limitSelect;
    const aux2 = limit + limitSelect;
    setoffset(aux);
    setlimit(aux2);
  }
  return {
    openFilas,
    anchorEl,
    handleCloseFilas,
    handleSelectNumero,
    handleClick,
    limitSelect,
    handleBack,
    handleNext,
  };
}
