import React, { useContext } from 'react';
import {
  Toolbar,
  IconButton,
  Menu,
  MenuItem,
} from '@mui/material';
import { AppBar,/* Button */ } from './styled';
import ArrowDropDown from '@mui/icons-material/ArrowDropDown';
import Typography from '@mui/material/Typography';
import { AuthContext } from '../../../AppContext/AuthContext';

function AppBarLayout({
  auth,
  anchorEl,
  handleMenu,
  handleClose,
  showSidebar,
}) {

  const { cerrarSesion } = useContext(AuthContext);

  return (
    <AppBar position="fixed">
      <Toolbar style={{placeContent: 'space-between'}}>
        <div>
          <img alt='Logo' src='https://balidexonecom.web.app/e03969781dabaff5da5baa2bc8a7f312.svg' 
          style={{ width: '144px',  height: '42px', left: '27px', top: '21px'}} />
        </div>
          
        {/*<Button>AGENDAR VISITA</Button>*/}
        {auth && (
          <div style={{
            display: 'inline-flex'
          }}>
            <div>            
               <div>  
            <Typography style={{fontFamily:'sans-serif', fontWeight:'700', fontSize:'12px', color:'#026DF2', textAlign:'end' }}>
            {localStorage.getItem("fullname", "")}</Typography>
            </div>
              <div>
              <Typography style={{fontFamily:'sans-serif' ,fontWeight:'400' ,fontSize:'9px', color:'#6A6767', textAlign:'end'}}>
              {localStorage.getItem("company_name", "")}</Typography>
              </div>
              </div>
             <div>
            <IconButton
              size="small"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleMenu}
              color="inherit"
            >
              <ArrowDropDown  style={{ left: '8.34', rigth: '8.33', top: '20.83', bottom:'16.66', color: '#026DF2'}} />
            </IconButton>
             </div>
            <Menu
              id="menu-appbar"
              anchorEl={anchorEl}
              anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
              keepMounted
              transformOrigin={{ vertical: 'top', horizontal: 'right' }}
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              <MenuItem dense={true} onClick={async () => {
                cerrarSesion();
                handleClose();
              }}>Sign Out</MenuItem>
            </Menu>
          </div>
        )}
      </Toolbar>
    </AppBar>
  );
}

export default AppBarLayout;