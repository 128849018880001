import React from 'react';
import PropTypes from 'prop-types';
import 'moment/locale/es';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import CalendarToday from '@mui/icons-material/CalendarToday';
import { TextFieldSmall } from './styledComponents';
export function DatePicker({
  value,
  onChange,
  disabled,
  error,
  helperText,
  emptyLabel,
  defaultIcon,
  textColor,
}) {
  return (
    <LocalizationProvider dateAdapter={AdapterMoment} locale="es">
      <TextFieldSmall
        fullWidth
        textColor={textColor}
        type="date"
        value={value}
        onChange={onChange}
        disabled={disabled}
        error={error}
        helperText={helperText}
        label={emptyLabel}
        variant="standard"
        InputProps={{
          endAdornment: defaultIcon ? undefined : <CalendarToday style={{color: textColor || '#000', fontSize: '12px'}} />,
        }}
      />
    </LocalizationProvider>
  );
}

DatePicker.propTypes = {
  value: PropTypes.number,
  onChange: PropTypes.func,
  error: PropTypes.bool,
  disabled: PropTypes.bool,
  helperText: PropTypes.string,
  defaultIcon: PropTypes.bool,
  emptyLabel: PropTypes.string,
  defaultValue: PropTypes.string,
  textColor: PropTypes.string,
};

export default DatePicker;
