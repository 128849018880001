import React from 'react';
import { Grid } from '@mui/material';
import { Container, ColoredStepConnector, StepLabel, StatusLabel, StatusCard, GridScroll } from '../styled';
import { Typography } from '../../../styled';
import { Stepper, Step } from '@mui/material';
import { ArrowForward, CheckCircle } from '@mui/icons-material';

export default function Content({ data }) {
    return (
        <Container>
            <GridScroll
                container
                spacing={3}
                style={{
                    display: 'flex',
                    flexWrap: 'nowrap',
                    overflowX: 'auto',
                    WebkitOverflowScrolling: 'touch',
                    scrollBehavior: 'smooth',
                    minHeight: 200,
                    maxWidth: window.innerWidth - 585,
                    paddingBottom: '20px',
                    justifyContent: data.details.steps.length > 4 ? 'start' : 'center'
                }}>
                <Stepper alternativeLabel style={{ alignSelf: 'center' }}>
                    {data.details.steps.slice(0).reverse().map((label, index) => {
                        return !(index === data.details.steps.length-1 && label.statusCodeName === "CANCELED") && (
                        <Step key={label.id} style={{ marginRight: '150px', marginLeft: index > 0 ? '0' : '50px', position: 'relative' }}>
                            <StepLabel color={label.color} StepIconComponent={() =>
                                label.statusCodeName === 'CLOSED' ? (
                                    <CheckCircle style={{color: label.color}}/>
                                ) : (
                                    <CheckCircle style={{color: label.color}}/>
                                )
                            }></StepLabel>
                            {index > 0 && <ColoredStepConnector color={label.color} />}
                            <StatusLabel small positionTop={index % 2 === 0} backgroundColor={label.color}>
                                {label.name}
                            </StatusLabel>
                            <StatusCard container color={label.color} positionTop={index % 2 === 0} alignItems="start">
                                <Grid item xs={12}>
                                    <Typography font="12px" noWrap color="#000">
                                        {label.date}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography font="12px" noWrap color="#000">
                                        {label.description}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Grid container justifyContent='start' alignItems="center">
                                        <Typography font="12px" color="#676666" noWrap>
                                            {label.from}
                                            {
                                                label.to && (
                                                <ArrowForward style={{ color: "#747474", fontSize: '12px', margin: '0 5px' }} />
                                                )
                                            }
                                            {label.to}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </StatusCard>
                        </Step>
                    )})}
                    </Stepper>
            </GridScroll>
        </Container>
    );
}
