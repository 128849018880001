import { useQuery } from '@apollo/client';
import { helperMapData } from './helpers';
import { WORK_ORDER_BY_ID } from '../../../../../../../graphql/queries/drivers';

export default function useDialogHooks(id) {

    const { data = { item: null }, loading } = useQuery(
        WORK_ORDER_BY_ID,
        {
            variables: { id },
        },
    );

    const dataDialog = helperMapData(data.item);


    return {
        dataDialog,
        loading,
    };
}