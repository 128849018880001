import React, { useState } from 'react';
// import { useLocalStorage } from './useLocalStorage';

const AppContext = React.createContext();

function AppProvider(props) {

  const [sidebar, setSidebar] = useState(false);

  const showSidebar = () => setSidebar(!sidebar);
  
  const auth = true;
  const [anchorEl, setAnchorEl] = useState(null);

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <AppContext.Provider value={{
        auth,
        anchorEl,
        handleMenu,
        handleClose,
        sidebar,
        showSidebar,
    }}>
      {props.children}
    </AppContext.Provider>
  );
}

export { AppContext, AppProvider };