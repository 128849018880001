import React, { Fragment } from 'react';
import { Outlet, useNavigate, useLocation } from 'react-router-dom';
import { Grid, Typography } from '@mui/material';
import { TabButton } from './styled';
import { Button } from './current/styled';


function Drivers() {
    const routes = [
        { name: 'Current', linkTo: 'current' },
        { name: 'Records', linkTo: 'records' },
        // { name: 'Create delivery order', linkTo: 'create' },
        //{ name: 'Settings', linkTo: 'settings' }
    ];

    const navigate = useNavigate();
    const location = useLocation();
    const pathParts = location.pathname.split('/');
    const path = pathParts[pathParts.length - 1];

    const handleTabClick = (tab) => {
        console.log(location);
        navigate(`/${tab}`);
    };
    
    return (
        <Fragment>
            <Grid 
            container 
            spacing={2} 
            style={{
                padding: '0 0 5px', 
                position: 'sticky', 
                top: 64,
                backgroundColor: '#f5f5f5',
                zIndex: 1098
                }}>
                {routes.map(e => <Grid item>
                    <TabButton active={e.linkTo === path} onClick={() => handleTabClick(e.linkTo)}>
                        {e.name}
                    </TabButton>
                </Grid>)}
                <Grid item xs></Grid>
                {/*<Button style={{
                    width: "175px",
                    height: "25px",
                    marginTop: "12px"
                }}>
                    <Typography style={{
                        fontSize: "12px"
                    }}>
                        CREATE DELIVERY ORDER
                    </Typography>
                </Button>*/}
            </Grid>
            <Outlet />
        </Fragment>
    );
}

export default Drivers;