import React, { useContext } from 'react';
import { DriverRecordsContext } from '../../hooks';
// import PropTypes from 'prop-types';
import Paginator from '../../../../../components/Paginator';
import { Grid, Typography, Card, CardTop } from '../../styled';
import Rows from '../rows';
import Header from '../header';
import InfoDialog from '../dialogs/info';
import DocumentsDialog from '../dialogs/documents';
import BillingDialog from '../dialogs/billing';
import AskDialog from '../dialogs/ask';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Switch } from '@mui/material';
import { Settings } from '@mui/icons-material';
import styled from 'styled-components';

export default function Table() {
  const {
    offset,
    setoffset,
    limit,
    setlimit,
    data,
    limitSelect,
    setlimitSelect,
    length,
    openDialog,
    handleCloseDialog,
    handleColumnsArgs,
    columns,
    openSettings,
    setOpenSettings,
  } = useContext(DriverRecordsContext);

  const handleClickOpenSe = () => {
    setOpenSettings(true);
  };

  const handleCloseSe = () => {
    setOpenSettings(false);
  };

  if (data === undefined) {
    return <Grid padding="50px" bgcolor="#FFFFFF"></Grid>;
  }

  const Vypographycol1 = styled(Typography)({
    fontFamily:'roboto',
    fontSize:'15px',
    marginLeft:'15px',
  });

  const Switch1 = styled(Switch)({
     marginRight: '30px',    
     top:'-8px' 
  });

  const Vypographycol2 = styled(Typography)({
    fontFamily:'roboto',
    fontSize:'15px',
        
  });

  const Switch2 = styled(Switch)({
     marginRight: '30px',
     marginLeft:'5px',    
     top:'-8px' 
  });

  const Vypographycol3 = styled(Typography)({
    fontFamily:'roboto',
    fontSize:'15px',
  });

  const Switch3 = styled(Switch)({
     marginRight: '10px',   
     marginLeft:'5px',     
     top:'-8px' 
  });

  return (
    <>
      <Dialog open={openSettings} 
        onClose={handleCloseSe}
        maxWidth='md'
      >
        <DialogTitle>Settings</DialogTitle>
        <DialogContent>
          <div>
            <Typography>
            Check the columns you want to be visible
            </Typography>
          </div>
        <div style={{marginTop:'30px'}}></div>
        <Grid container spacing={2} columns={16}>
        <Grid style={{marginTop:'3px'}}>
          <div>
            <div style={{justifyContent:'space-between', display:'flex'}}>
              <Vypographycol1>REFERENCE</Vypographycol1>
              <Switch1 color="primary" checked={columns.reference}
                onChange={(event) => handleColumnsArgs(event.target.checked, 'reference')}
              />
            </div>
            <div style={{justifyContent:'space-between', display:'flex'}}>
              <Vypographycol1>STATUS</Vypographycol1>
              <Switch1 color="primary" checked={columns.status}
                onChange={(event) => handleColumnsArgs(event.target.checked, 'status')}
              />
            </div>
            <div style={{justifyContent:'space-between', display:'flex'}}>
              <Vypographycol1>CONTAINER</Vypographycol1>
              <Switch1 color="primary" checked={columns.container}
                onChange={(event) => handleColumnsArgs(event.target.checked, 'container')}
              />
            </div>
            <div style={{justifyContent:'space-between', display:'flex'}}>
              <Vypographycol1>TYPE</Vypographycol1>
              <Switch1 color="primary" checked={columns.type}
                onChange={(event) => handleColumnsArgs(event.target.checked, 'type')}
              />
            </div>
            <div style={{justifyContent:'space-between', display:'flex'}}>
              <Vypographycol1>MOVE TYPE</Vypographycol1>
              <Switch1 color="primary" checked={columns.moveType}
                onChange={(event) => handleColumnsArgs(event.target.checked, 'moveType')}
              />
            </div> 
            <div style={{justifyContent:'space-between', display:'flex'}}>
              <Vypographycol1>ROUTE STATUS</Vypographycol1>
              <Switch1 color="primary" checked={columns.routeStatus}
                onChange={(event) => handleColumnsArgs(event.target.checked, 'routeStatus')}
              />
            </div>
            <div style={{justifyContent:'space-between', display:'flex'}}>
              <Vypographycol1>CONSIGNEE</Vypographycol1>
              <Switch1 color="primary" checked={columns.consignee}
                onChange={(event) => handleColumnsArgs(event.target.checked, 'consignee')}
              />
            </div>
            <div style={{justifyContent:'space-between', display:'flex'}}>
              <Vypographycol1>T&E</Vypographycol1>
              <Switch1 color="primary" checked={columns.te}
                onChange={(event) => handleColumnsArgs(event.target.checked, 'te')}
              />
            </div>
            <div style={{justifyContent:'space-between', display:'flex'}}>
              <Vypographycol1>FROM</Vypographycol1>
              <Switch1 color="primary" checked={columns.from}
                onChange={(event) => handleColumnsArgs(event.target.checked, 'from')}
              />
            </div>
            <div style={{justifyContent:'space-between', display:'flex'}}>
              <Vypographycol1>TO</Vypographycol1>
              <Switch1 color="primary" checked={columns.to}
                onChange={(event) => handleColumnsArgs(event.target.checked, 'to')}
              />
            </div>  
          </div> 
        </Grid>
        <Grid style={{marginTop:'3px'}}>
          <div>
            <div style={{justifyContent:'space-between', display:'flex'}}>
              <Vypographycol2>SIZE</Vypographycol2>
              <Switch2 color="primary" checked={columns.size}
                onChange={(event) => handleColumnsArgs(event.target.checked, 'size')}
              />
            </div>
            <div style={{justifyContent:'space-between', display:'flex'}}>
              <Vypographycol2>LFD</Vypographycol2>
              <Switch2 color="primary" checked={columns.lfd}
                onChange={(event) => handleColumnsArgs(event.target.checked, 'lfd')}
              />
            </div>
            <div style={{justifyContent:'space-between', display:'flex'}}>
              <Vypographycol2>EMPTY NOTIFY</Vypographycol2>
              <Switch2 color="primary" checked={columns.emptyNotify}
                onChange={(event) => handleColumnsArgs(event.target.checked, 'emptyNotify')}
              />
            </div> 
            <div style={{justifyContent:'space-between', display:'flex'}}>
              <Vypographycol2>INBOND VIA</Vypographycol2>
              <Switch2 color="primary" checked={columns.inbondVia}
                onChange={(event) => handleColumnsArgs(event.target.checked, 'inbondVia')}
              />
            </div>
            <div style={{justifyContent:'space-between', display:'flex'}}>
              <Vypographycol2>MBL</Vypographycol2>
              <Switch2 color="primary" checked={columns.mbl}
                onChange={(event) => handleColumnsArgs(event.target.checked, 'mbl')}
              />
            </div>
            <div style={{justifyContent:'space-between', display:'flex'}}>
              <Vypographycol2>APPT. DATE</Vypographycol2>
              <Switch2 color="primary" checked={columns.apptDate}
                onChange={(event) => handleColumnsArgs(event.target.checked, 'apptDate')}
              />
            </div>  
            <div style={{justifyContent:'space-between', display:'flex'}}>
              <Vypographycol2>APPT. NUMBER</Vypographycol2>
              <Switch2 color="primary" checked={columns.apptNumber}
                onChange={(event) => handleColumnsArgs(event.target.checked, 'apptNumber')}
              />
            </div>
            <div style={{justifyContent:'space-between', display:'flex'}}>
              <Vypographycol2>DRIVER</Vypographycol2>
              <Switch2 color="primary" checked={columns.driver}
                onChange={(event) => handleColumnsArgs(event.target.checked, 'driver')}
              />
            </div>
            <div style={{justifyContent:'space-between', display:'flex'}}>
              <Vypographycol2>PLATE</Vypographycol2>
              <Switch2 color="primary" checked={columns.plate}
                onChange={(event) => handleColumnsArgs(event.target.checked, 'plate')}
              />
            </div>
            <div style={{justifyContent:'space-between', display:'flex'}}>
              <Vypographycol2>FOLIO</Vypographycol2>
              <Switch2 color="primary" checked={columns.folio}
                onChange={(event) => handleColumnsArgs(event.target.checked, 'folio')}
              />
            </div>
          </div> 
        </Grid>
        <Grid style={{marginTop:'3px'}}>
          <div>
            <div style={{justifyContent:'space-between', display:'flex'}}>
              <Vypographycol3>SCALE</Vypographycol3>
              <Switch3 color="primary" checked={columns.scale}
                onChange={(event) => handleColumnsArgs(event.target.checked, 'scale')}
              />
            </div>
            <div style={{justifyContent:'space-between', display:'flex'}}>
              <Vypographycol3>ETA</Vypographycol3>
              <Switch3 color="primary" checked={columns.eta}
                onChange={(event) => handleColumnsArgs(event.target.checked, 'eta')}
              />
            </div>
            <div style={{justifyContent:'space-between', display:'flex'}}>
              <Vypographycol3>DAYS</Vypographycol3>
              <Switch3 color="primary" checked={columns.days}
                onChange={(event) => handleColumnsArgs(event.target.checked, 'days')}
              />
            </div>
            <div style={{justifyContent:'space-between', display:'flex'}}>
              <Vypographycol3>REMARKS</Vypographycol3>
              <Switch3 color="primary" checked={columns.remarks}
                onChange={(event) => handleColumnsArgs(event.target.checked, 'remarks')}
              />
            </div>
          </div> 
        </Grid>
        </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseSe}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
        <CardTop style={{ position: 'sticky', top: 110, zIndex: 1098 }}>
        <Grid
          container
          justifyContent="space-between"
          style={{ position: 'sticky',}}
        >
          <Grid item style={{ marginTop: "8px" }}>
            <Typography variant='subtitle1' font="14px" color="#757575">
              RESULTS: {length}
            </Typography>
          </Grid>
            {/*<IconButton onClick={handleClickOpenSe}>
              <Settings style={{
                color:'#005BFF',
                width:'20px'
              }}></Settings>
            </IconButton>*/}
        </Grid >
          <Header />
        </CardTop>
        <Card bottom>
          <Rows />
          <Paginator
            offset={offset}
            setoffset={setoffset}
            limit={limit}
            setlimit={setlimit}
            length={length}
            limitSelect={limitSelect}
            setlimitSelect={setlimitSelect}
          />
        </Card>
        <InfoDialog
          label="info"
          openDialog={openDialog}
          handleClose={handleCloseDialog}
        />
        <DocumentsDialog
          label="docs"
          openDialog={openDialog}
          handleClose={handleCloseDialog}
        />
        <BillingDialog
          label="bill"
          openDialog={openDialog}
          handleClose={handleCloseDialog}
        />
        <AskDialog
          label="ask"
          openDialog={openDialog}
          handleClose={handleCloseDialog}
        />
    </>
  );
}

Table.propTypes = {
};
