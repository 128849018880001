export default function useHooks({ file, note, request }) {
    // TAB CONSTANTS
    const options = [
        { id: '0', name: 'FILE VIEWER', active: file },
        // { id: '1', name: 'NOTES', active: note },
        // { id: '2', name: 'REQUESTS', active: request }
    ];

    // IF ACTIVE IS FALSE TAB WILL NOT SHOW
    const tabOptions = options.filter(option => option.active);

    return {
        // TABS
        tabOptions,
    };
}