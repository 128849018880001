import React from 'react';
import Table from './containers/table/index';
import { DriverRecordsProvider } from './hooks';

export default function Drivers() {
  return (
    <>
      <DriverRecordsProvider>
        <Table/>
      </DriverRecordsProvider>
    </>
  );
}
