import React from 'react';
import { Grid } from '../../styled';
import ContentOne from './containers/content-one';
import ContentTwo from './containers/content-two';
import ContentThree from './containers/content-three';


function RowContent({ data }) {
    return (
        <Grid container padding="10px 0 0" justifyContent="start">
            <Grid item style={{ paddingTop: 10, width: 410 }}>
                <Grid container>
                    <Grid item width='165px' style={{ borderRight: '1px solid #757575' }}>
                        <ContentOne data={data} />
                    </Grid>
                    <Grid item width='245px' style={{ borderRight: '1px solid #757575' }}>
                        <ContentTwo data={data} />
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs>
                <ContentThree data={data} />
            </Grid>
        </Grid>
    );
}

export default RowContent;