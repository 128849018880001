import styled from 'styled-components';
import GridMUI from '@mui/material/Grid';
import TypographyMUI from '@mui/material/Typography';
import CardMediaMUI from '@mui/material/CardMedia';
import IconButtonMUI from '@mui/material/IconButton';
import ButtonMUI from '@mui/material/Button';
import TextFieldMUI from '@mui/material/TextField';
import PaperMUI from '@mui/material/Paper';

export const TextFieldSmall = styled(TextFieldMUI)`
  && {
    input {
      font-size: 12px;
      color: ${props => props.value ? '#000' : 'transparent'};
    }
    label {
      font-size: 12px;
      color: ${props => props.textColor || '#000'};
    }
    & .MuiInput-underline:before {
      border-bottom: none;
    }
    & .MuiInput-underline:after {
      border-bottom: 1px solid #026DF2;
    }
    & .MuiInput-underline:hover:not(.Mui-disabled):before {
      border-bottom: 1px solid #026DF2 !important;
    }
    @media (max-width: 1499px) {
      input {
        font-size: 11px;
      }
      label {
        font-size: 11px;
      }
    }
    @media (max-width: 1299px) {
      input {
        font-size: 10px;
      }
      label {
        font-size: 10px;
      }
    }
  }
`;

export const IconButton = styled(IconButtonMUI)`
  ${props => (props.small ? `padding: 2px !important;` : null)}
  & .MuiButtonBase-root-MuiIconButton-root {
    ${props => (props.small ? `padding: 2px !important;` : null)}
  }
  svg {
    font-size: 20px;
    color: ${props => (props.color ? props.color : '#02b120')};
  }
`;

export const TypographyRow = styled(TypographyMUI)`
  ${props => props.clickable && 'cursor: pointer;'}
  color: ${props => props.color || "#000000"} !importants;
  font-size: ${props => (props.font ? `${props.font} !important` : null)};
  padding: ${props => props.padding};
  white-space: ${props => (props.preline ? 'pre-line' : null)};
  ${props =>
    props.texttransform
      ? `text-transform: ${props.texttransform};`
      : ''}
  ${props => (props.ellipsis ? `text-overflow: ellipsis;` : null)}
  ${props => (props.titleText && `
    font-weight: 700 !important;
    text-transform: uppercase;
  `)}
  span {
    ${props => (props.titleText && `font-weight: 500;`)}
  }
`;

export const Grid = styled(GridMUI)`
  min-width: ${props => props.minwidth};
  ${props =>
    props.marginleft ? `margin-left: ${props.marginleft};` : null}
  min-height: ${props => props.minheight};
  ${props => (props.width ? `width: ${props.width};` : '')}
  ${props => (props.margin ? `margin: ${props.margin};` : '')}
  ${props => (props.padding ? `padding: ${props.padding};` : '')}
  border-radius: ${props => props.borderradius};
  ${props => (props.height ? `height: ${props.height};` : '')}
  ${props =>
    props.maxheight ? `max-height: ${props.maxheight};` : ''}
  background-color: ${props => props.bgcolor};
  ${props => (props.borderBottom ? 'border-bottom: 1px solid #DBDEDF;' : '')}
  ${props => (props.borderTop ? 'border-top: 1px solid #DBDEDF;' : '')}

  border: ${props => props.border};
  display: ${props => (props.titulo === 'true' ? 'inline-block' : null)};
  width: ${props => (props.titulo === 'true' ? '10px' : null)};
  ${props => props.card && `
    border-radius: 5px;
    margin-bottom: 3px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 1px 4px;
  `}
`;

export const IconButtonRow = styled(IconButtonMUI)`
  ${props => (props.small ? `padding: 2px !important;` : null)}
  & .MuiButtonBase-root-MuiIconButton-root {
    ${props => (props.small ? `padding: 2px !important;` : null)}
  }
  svg {
    font-size: 20px;
    color: ${props => (props.color ? props.color : '#02b120')};
  }
`;


export const Card = styled(GridMUI)`
  background-color: #fff;
  padding: ${props => props.top ? '20px 20px 0 20px' : props.bottom ? '4px 20px 20px 20px' : '20px'};
  border-radius: ${props => props.top ? '5px 5px 0 0' : props.bottom ? '0 0 5px 5px' : '5px'};
`;

export const Paper = styled(PaperMUI)`
  background-color: #fff;
  border-radius: 5px;
  width: 100%;
  height: calc(100vh - 140px)
`;

export const CardTop = styled(GridMUI)`
  background-color: #fff;
  padding: 0px 20px 0px 20px;
  border-radius: 5px 5px 0px 00px;
`;
export const Typography = styled(TypographyMUI)`
  ${props => props.clickable && 'cursor: pointer;'}
  color: ${props => props.color || "#000000"} !importants;
  font-size: ${props => (props.font ? `${props.font} !important` : null)};
  padding: ${props => props.padding};
  white-space: ${props => (props.preline ? 'pre-line' : null)};
  ${props =>
    props.texttransform
      ? `text-transform: ${props.texttransform};`
      : ''}
  ${props => (props.ellipsis ? `text-overflow: ellipsis;` : null)}
  ${props => (props.titleText && `
    font-weight: 700 !important;
    text-transform: uppercase;
  `)}
  span {
    ${props => (props.titleText && `font-weight: 500;`)}
  }
`;

export const CardMedia = styled(CardMediaMUI)`
  width: 38px;
  height: 38px;
`;

export const Button = styled(ButtonMUI)`
  width: 140px;
  height: 30px;
  background-color: ${props => props.bgColor ? props.bgColor : '#026df2'} !important;
  color: ${props => (props.color ? props.color : 'white')} !important;
  border-radius: 5px;
  svg {
    font-size: 16px;
    color: ${props => (props.color ? props.color : 'white')} !important;
  }
  .buttonText {
    font-weight: 900;
    font-size:  ${props => (props.fontSize ? props.fontSize : '12px')};
  }
`;

