import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';

// const firebaseConfig = {
//   apiKey: "AIzaSyAKtCJQhI-AmIA-npNHJ01U5Dffn5sgs_s",
//   authDomain: "balidexonecom.firebaseapp.com",
//   projectId: "balidexonecom",
//   storageBucket: "balidexonecom.appspot.com",
//   messagingSenderId: "910047560405",
//   appId: "1:910047560405:web:335a48e53186c029f1d85c"
// }

const firebaseConfig = {
  apiKey: "AIzaSyDZXermAiBJXyJ7ksRjS_TKh9PPht-cbjc",
  authDomain: "customer-dexone.firebaseapp.com",
  projectId: "customer-dexone",
  storageBucket: "customer-dexone.appspot.com",
  messagingSenderId: "392564859335",
  appId: "1:392564859335:web:54314ef93e300ab20a79e2"
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);

export { auth };