import { get } from 'lodash';
import moment from 'moment';

// GET COLOR BASED ON CODE NAME
const getColor = (color) => {
    switch (color) {
        case 'STARTED':
            return 'green';
        case 'closed_work_order': 
            return 'blue';
        case 'CANCELED': 
            return 'red';
        case 'AT_VESSEL':
            return 'grey';
        case 'AT_RAIL':
            return 'grey';
        case 'BILLED':
            return '';
        case 'DELIVERED':
            return 'green';
        case 'CLOSED':
            return 'green';
        case 'ON_HOLD':
            return '#ff6224';
        case 'RELEASED':
            return 'green';
        case 'IN_TRANSIT_WO':
            return 'purple';
        case 'AVAILABLE_WO':
            return '#666699';
        case 'AVAILABLE':
            return '#989595';
        case 'IN_YARD_WO':
            return 'green';
        case 'EMPTY_NOTIFIED_WO':
            return 'green';
        case 'APPT_AT_TERMINAL':
            return '#666699';
        case 'DELIVERY_TO_ARRIVED':
            return '#FF0080';
        case 'DISPATCHED':
                return 'green';
        default:    
            return '#026df2';
    }
};
// TO ADD COLUMN: CONSOLE LOG LIST AND ADD TO PUSH STATEMENT
export const helperMapData = list => {
    const aux = [];
    
    list.forEach(element => {
        const auxDriverMoves = [];

        const driverMoveData = get(element, 'work_order.driver_moves', []);

        if (driverMoveData.length > 0) {
            driverMoveData.forEach(driverMove => {
                auxDriverMoves.push({
                    name: get(driverMove, 'status.name', "Not found"),
                    color: getColor(get(driverMove, 'status.code_name', "Not found")),
                    date: get(driverMove, 'schedule_date') ? moment(get(driverMove, 'schedule_date')).format('MM/DD/YYYY HH:mm') : moment(get(driverMove, 'created_at')).format('MM/DD/YYYY HH:mm'),
                    description: `${get(driverMove, 'parent_routes_code.name', 'Not found')} / ${get(driverMove, 'load_type.name', 'Not found')}`,
                    from:  get(driverMove, 'from_zone.zone_name', ""),
                    to: get(driverMove, 'to_zone.zone_name', ""),
                    statusCodeName: get(driverMove, 'status.code_name', "Not found"),
                })
            });
        }
        aux.push({
            id: get(element, 'work_order.id'),
            status: get(element, 'work_order.status.name', "Not found"),
            status_color: getColor(get(element, 'work_order.status.code_name', "Not found")),
            reference: get(element, 'work_order.customer_reference', "Not found"),
            container: get(element, 'work_order.container', "Not found"),
            type: get(element, 'load_type.name', 'Not Found'),
            moveType: get(element, 'work_order.move_type_code_name','Not found'),
            routeStatus: get(element, 'work_order.driver_moves[0].parent_routes_code.name', "IN PROCESS"),
            //doReceived: moment(get(element, 'created_at', '01/01/1999')).format('MM/DD/YYYY'),
            consignee: get(element, 'work_order.to_zone.zone_name', "Not found"),
            invoiceNumber: get(element, 'work_order.paper_folio.prefix', '') + get(element, 'work_order.paper_folio.folio', ''),
            invoiceDate: get(element, 'work_order.paper_folio.paper.paper_date', '') ? moment(get(element, 'work_order.paper_folio.paper.paper_date', '')).format('MM/DD/YYYY'): 'Pending',
            invoiceTotal: get(element,'work_order.paper_folio.paper.total', ''),
            dueDate: get(element, 'work_order.paper_folio.paper.due_date', '') ? moment(get(element, 'work_order.paper_folio.paper.due_date', '')).format('MM/DD/YYYY'):'Pending', 
            te: get(element, 'work_order.te'),
            from: get(element, 'from_zone.zone_name'),
            to: get(element, 'to_zone.zone_name'),
            size: get(element, 'work_order.equipment.equipment_type.size'),
            lfd: get(element, 'work_order.last_free_day') ? moment(get(element, 'work_order.last_free_day')).format('MM/DD/YYYY') : "",
            emptyNotify: get(element, 'work_order.empty_notify') ? moment(get(element, 'work_order.empty_notify')).format('MM/DD/YYYY') : "",
            inbondVia: get(element, 'work_order.inbond.name'),
            mbl: get(element, 'work_order.mbl'),
            apptDate: get(element, 'schedule_date') ? moment(get(element, 'schedule_date')).format('MM/DD/YYYY') : "",
            apptNumber: get(element, 'appointment_number'),
            driver: get(element, 'driver.person.full_name'),
            driver_user: get(element, 'driver.username'),
            vehicle_unit: get(element, 'driver.vehicle.unit_number'),
            plate: get(element, 'work_order.chassis_plate'),
            folio: get(element, 'work_order.folio.formatted_folio'),
            scale: get(element, 'work_order.scale'),
            eta: get(element, 'work_order.eta') ? moment(get(element, 'work_order.eta')).format('MM/DD/YYYY')  : "",
            days: "",//get(element, 'work_order.eta') ? moment().diff(get(element, 'work_order.eta'), 'days') + 1 : 0,
            remarks: get(element, 'remarks'),
            details: {
                id: get(element, 'id', "Not found"),
                status: {   
                    name: get(element, 'work_order.status.name', "Not found"),
                    color: getColor(get(element, 'work_order.status.code_name', "Not found")),
                },
                from: get(element, 'work_order.from_zone.zone_name', "Not found"),
                to: get(element, 'work_order.to_zone.zone_name', "Not found"),
                currentFrom: get(element, 'work_order.from_zone.address.city_string', "Not found"),
                currentTo: get(element, 'work_order.to_zone.address.city_string', "Not found"),
                steps: auxDriverMoves,
            },
            raw: element
        })
    })
    return aux;
};

//TO CONNECT NEW COLUMNS ADD IF AND WHERE STATEMENT
export const helperWhereClause = (args) => {

    const customerId = localStorage.getItem("company_id");

    const aux = [
        { work_order: { customer_id: { _eq: customerId } } },
        { work_order: { status_code_name: { _neq: "BILLED" } } },
        { work_order: { status_code_name: { _neq: "closed_work_order" } } },
        { work_order: { status_code_name: { _neq: "CANCELED" } } },
        { work_order: { status_code_name: { _neq: "active_work_order" } } },
        { work_order: { status_code_name: { _neq: "AT_RAIL" } } },
        { work_order: { status_code_name: { _neq: "AT_VESSEL" } } },
        { status_code: { _neq: "CLOSED" } },
        { status_code: { _neq: "CANCELED" } }
    ];

    const role = localStorage.getItem('role');

    if(role === "consignee") {
        aux.push({ work_order: { to_zone: { id: { _eq: localStorage.getItem('sub_company_id') } } }})
    }
    
    if (args.reference) aux.push({ work_order: { customer_reference: { _ilike: `%${args.reference}%` }} });
    if (args.container) aux.push({ work_order: { container: { _ilike: `%${args.container}%` } }});
    if (args.type) aux.push({ load_type: { name: { _ilike: `%${args.type}%` } } });
    if (args.moveType) aux.push({ work_order: {move_type_code_name: { _ilike: `%${args.moveType}%` }} });
    if (args.routeStatus) aux.push({ parent_routes_code: { name: { _ilike: `%${args.routeStatus}%` } }});
    if (args.consignee) aux.push({ work_order: { to_zone: { zone_name: { _ilike: `%${args.consignee}%` } } }});
    if (args.status) aux.push({ work_order: { status: { name: { _ilike: `%${args.status}%` } } }});
    if (args.te) aux.push({ work_order: { te: { _ilike: `%${args.te}%` } }});
    if (args.from) aux.push({ from_zone: { zone_name:{ _ilike: `%${args.from}%` } } });
    if (args.to) aux.push({ to_zone: { zone_name: { _ilike: `%${args.to}%` } } });
    if (args.size) aux.push({ work_order: { equipment: { equipment_type: { size: { _eq: args.size } } } } });
    if (args.lfd) aux.push({ _and: [
        { work_order: { last_free_day: { _gte: `${args.lfd}T00:00:00+00:00` }} }, 
        { work_order: { last_free_day: { _lte: `${args.lfd}T23:59:59+00:00` }} }
    ] });
    if (args.emptyNotify) aux.push({ _and: [
        { work_order: { empty_notify: { _gte: `${args.emptyNotify}T00:00:00+00:00` }} }, 
        { work_order: { empty_notify: { _lte: `${args.emptyNotify}T23:59:59+00:00` }} }
    ] });
    if (args.inbondVia) aux.push({ work_order: { inbond: { name: { _ilike: `%${args.to}%` } } } });
    if (args.mbl) aux.push({ work_order: { mbl: { _ilike: `%${args.mbl}%` } } });
    if (args.mbl) aux.push({ work_order: { mbl: { _ilike: `%${args.mbl}%` } } });
    if (args.apptDate) aux.push({ _and: [
        { schedule_date: { _gte: `${args.apptDate}T00:00:00+00:00` } }, 
        { schedule_date: { _lte: `${args.apptDate}T23:59:59+00:00` } }
    ] });
    if (args.apptNumber) aux.push({ appointment_number: { _ilike: `%${args.apptNumber}%` } });
    if (args.driver) aux.push({ driver: { person: { full_name:{ _ilike: `%${args.driver}%` }}} });
    if (args.plate) aux.push({ work_order: { chassis_plate:{ _ilike: `%${args.plate}%` }} });
    if (args.folio) aux.push({ work_order: { folio: { formatted_folio: { _ilike: `%${args.folio}%` }}} });
    if (args.scale) aux.push({ work_order: { scale:{ _ilike: `%${args.scale}%` }} });
    if (args.eta) aux.push({ _and: [
        { work_order: { eta: { _gte: `${args.eta}T00:00:00+00:00` }} }, 
        { work_order: { eta: { _lte: `${args.eta}T23:59:59+00:00` }} }
    ] });
    if (args.remarks) aux.push({ remarks: { _ilike: `%${args.remarks}%` } });

    const clause = { _and: aux };
    return clause;
};