import React from 'react';
import LinearProgress from '@mui/material/LinearProgress';
import { ProgressContainer, Image } from './styled';
import { Grid } from '@mui/material';

function LoadingIndicator({defaultHeight}) {
    return (
        <Grid style={{height: defaultHeight || '200px'}} container justifyContent={'center'} alignItems={'center'}>
            <Grid item>
                <Grid container justifyContent={'center'} alignItems={'center'}>
                    <Grid item>
                        <Image
                            alt='Logo'
                            src='https://balidexonecom.web.app/e03969781dabaff5da5baa2bc8a7f312.svg'
                        />
                    </Grid>
                    <Grid container />
                    <Grid item style={{width: '200px'}}>
                        <ProgressContainer>
                            <LinearProgress />
                        </ProgressContainer>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
}

export default LoadingIndicator;
