import React, { useContext, useEffect, useState } from 'react';
import { DriversCurrentContext } from '../../hooks';
// import PropTypes from 'prop-types';
import Paginator from '../../../../../components/Paginator';
import { Grid, Typography, Paper, TextFieldSmall, IconButtonRow } from '../../styled';
import Rows from '../rows';
import Header from '../header';
import InfoDialog from '../dialogs/info';
import DocumentsDialog from '../dialogs/documents';
import BillingDialog from '../dialogs/billing';
import AskDialog from '../dialogs/ask';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Switch, useMediaQuery } from '@mui/material';
import { Info, KeyboardArrowDown, KeyboardArrowUp, Settings, Summarize, Textsms } from '@mui/icons-material';
import styled from 'styled-components';
import FilterListIcon from '@mui/icons-material/FilterList';
import DatePicker from '../../../../../components/DatePicker';
import NoData from '../../../../../components/NoData';
import LoadingIndicator from '../../../../../components/Loading';
import TypographyMUI from '@mui/material/Typography';
import RowContent from '../row-content';
import { Loader } from '@googlemaps/js-api-loader';
import { get } from 'lodash';

function Map() {
  const [map, setMap] = useState(null);
  const [marker, setMarker] = useState(null);
  const [loading, setLoading] = useState(false);
  const [location, setLocation] = useState(null);

  const {
    open,
    setDataLocation,
    loadingMap,
    setLoadingMap
  } = useContext(DriversCurrentContext);

  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
    },
    body: JSON.stringify({
      "userDriver": open.driver_user,
      "deviceNumber": open.vehicle_unit
    }),
  };

  const getDataFetch = () => {
    console.log(open)
    fetch(
      "https://us-central1-bali-lbth.cloudfunctions.net/geotab_gps_vehicle",
      requestOptions,
    ).then(async (data) => {
      const letData = await data.json();
      setDataLocation(letData);
      setLocation({
        lat: letData.status.latitude,
        lon: letData.status.longitude
      })
      setLoadingMap(false);
    });
  }
    
  useEffect(() => {
    if(loadingMap) getDataFetch()
    const loader = new Loader({
      apiKey: 'AIzaSyCEAwgxN9ekioHXFjqOEhKw7vtyHCZ5ogI',
      version: 'weekly',
    });

    loader.load().then(() => {
      const map = new window.google.maps.Map(document.getElementById('map'), {
        center: { lat: location.lat, lng: location.lon },
        zoom: 16,
        styles: [
          {
            featureType: "poi",
            elementType: "labels",
            stylers: [{ visibility: "off" }],
          },
        ],
      });

      setMap(map);

      // const icon = {
      //   url: '/src/Assets/Icons/delivery-truck.png',
      //   scaledSize: new window.google.maps.Size(128, 50),
      // };

      const marker = new window.google.maps.Marker({
        position: { lat: location.lat, lng: location.lon },
        map: map,
        title: 'Marker'
      });

      setMarker(marker);
    });
  }, [location]);

   if(loadingMap) return <LoadingIndicator/>;
   return <div id="map" style={{ height: '500px', width: '100%' }} />;
}

export default function Table() {
  const {
    offset,
    setoffset,
    limit,
    setlimit,
    data,
    limitSelect,
    setlimitSelect,
    length,
    openDialog,
    handleCloseDialog,
    handleColumnsArgs,
    columns,
    openSettings,
    setOpenSettings,
    searchArgs,
    handlesearchArgs,
    dataColums,
    loading,
    open,
    handleOpenDialog,
    handleOpen,
    openLocation, 
    setOpenLocation,
    dataLocation,
    loadingMap,
    setLoadingMap
  } = useContext(DriversCurrentContext);

  const handleClickOpenSe = () => {
    setOpenSettings(true);
  };

  const handleCloseSe = () => {
    setOpenSettings(false);
  };

  if (data === undefined) {
    return <Grid padding="50px" bgcolor="#FFFFFF"></Grid>;
  }

  const role = localStorage.getItem('role');

  const Vypographycol1 = styled(Typography)({
    fontFamily: 'Nunito',
    fontSize: '14px',
    fontWeight: '700',
    color: '#000000'
  });

  const Switch1 = styled(Switch)({
    top: '-8px'
  });

  const Vypographycol2 = styled(Typography)({
    fontFamily: 'Nunito',
    fontSize: '14px',
    fontWeight: '700',
    color: '#000000'
  });

  const Switch2 = styled(Switch)({
    top: '-8px'
  });

  const Vypographycol3 = styled(Typography)({
    fontFamily: 'Nunito',
    fontSize: '14px',
    fontWeight: '700',
    color: '#000000'
  });

  const Switch3 = styled(Switch)({
    top: '-8px'
  });

  const variantLabel = 'caption';
  const filterIcon = color => <FilterListIcon style={{ color: color || '#676666', fontSize: '16px' }} />;
  return (
    <>
      <Paper>
        <Grid container style={{
          padding: "0 10px 10px 10px",
        }}>
          <Grid
            container
            justifyContent="space-between"
            style={{ marginLeft: '10px' }}
          >
            <Grid item style={{ marginTop: "8px" }}>
              <Typography variant='subtitle1' font="14px" color="#757575">
                RESULTS: {length}
              </Typography>
            </Grid>
            <IconButton onClick={handleClickOpenSe}>
              <Settings style={{
                color: '#005BFF',
                width: '20px'
              }}></Settings>
            </IconButton>
          </Grid >
          <div style={{
            width: "100%",
            overflow: 'auto',
          }}>
            <div style={{
              display: 'inline-flex',
              padding: '0px 20px',
              marginBottom: '8px',
              borderBottom: '1px solid #AFAFAF',
              position: 'sticky',
              top: 0,
              zIndex: 1,
              background: '#FFFFFF'
            }}>
              {
                dataColums.map(col => {
                  return columns[col.codeName] && <div style={{
                    margin: '0 10px 0 0',
                    width: col.width
                  }}>
                    {
                      col.date ?
                        <DatePicker
                          textColor="#026DF2"
                          defaultIcon
                          emptyLabel={col.name}
                          value={searchArgs[col.codeName]}
                          onChange={e => {
                            handlesearchArgs(e.target.value, col.codeName, 'date');
                          }}
                          style={{
                            width: col.width
                          }}
                        /> :
                        <TextFieldSmall
                          label={col.name}
                          textColor="#676666"
                          variant="standard"
                          value={searchArgs[col.codeName]}
                          onChange={e => handlesearchArgs(e.target.value, col.codeName)}
                          InputProps={{
                            endAdornment: filterIcon(),
                          }}
                          style={{
                            width: col.width
                          }}
                        />
                    }
                  </div>
                })
              }
              <div
                style={{
                  width: '20px',
                  overflow: 'hidden',
                  marginTop: '16px',
                }}
              >
                {/*<Typography align="center" font="12px" >
                  NOTIFICATIONS
                </Typography>*/}
              </div>
            </div>
            {!data ? (<NoData title="No results."></NoData>) :
              loading ? <div style={{
                height: 'calc(100vh - 280px)'
              }}><LoadingIndicator /></div> :
                <div style={{
                  padding: '0px 8px',
                  height: 'calc(100vh - 280px)',
                }}>
                  {data.map(e => {
                    return <div 
                    style={{
                      display: 'inline-block',
                      padding: '8px 14px 0px 14px',
                      borderRadius: '5px',
                      marginBottom: '8px',
                      boxShadow: 'rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 1px 4px'
                    }}>
                      <div onClick={() => handleOpen(e)} style={{
                        display: 'inline-flex',
                        cursor: 'pointer',
                      }}>
                        {
                          dataColums.map(col => {
                            {
                              const labelColor = open.id === e.id ? '#026df2' : '#757575';
                              return columns[col.codeName] && <div style={{
                                margin: '0 10px 0 0',
                                width: col.width
                              }}>
                                <TypographyMUI
                                  noWrap
                                  style={{
                                    whiteSpace: 'nowrap',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                    color: labelColor,
                                    fontSize: '12px',
                                    width: col.width
                                  }}
                                >
                                  {e[col.codeName]}
                                </TypographyMUI>
                              </div>
                            }
                          })
                        }
                        <div
                          style={{
                            width: '20px',
                            paddingBottom: '6px',
                          }}
                        >
                          <Grid container justifyContent="end">
                            {/*<IconButtonRow small onClick={() => {
                              if (open.id !== e.id) handleOpen(e);
                              handleOpenDialog('docs')
                            }}>
                              <Summarize />
                            </IconButtonRow>
                            <IconButtonRow small onClick={() => {
                              handleOpenDialog('ask')
                            }}>
                              <Textsms />
                            </IconButtonRow>
                            <IconButtonRow small onClick={() => {
                              if (open.id !== e.id) handleOpen(e);
                              handleOpenDialog('info')
                            }}>
                              <Info />
                            </IconButtonRow>*/}
                            <IconButtonRow small color='#026df2' onClick={() => handleOpen(e)}>
                              {open.id === e.id ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                            </IconButtonRow>
                          </Grid>
                        </div>
                      </div>
                      {
                        open.id === e.id &&
                        <Grid item xs={12} padding={'0px 0px 16px 0px'} borderTop style={{
                          display: 'block'
                        }}>
                          <RowContent data={open} />
                        </Grid>
                      }
                    </div>
                  })}
                </div>}
          </div>
          <Paginator
            offset={offset}
            setoffset={setoffset}
            limit={limit}
            setlimit={setlimit}
            length={length}
            limitSelect={limitSelect}
            setlimitSelect={setlimitSelect}
          />
        </Grid>
      </Paper>
      <InfoDialog
        label="info"
        openDialog={openDialog}
        handleClose={handleCloseDialog}
      />
      <DocumentsDialog
        label="docs"
        openDialog={openDialog}
        handleClose={handleCloseDialog}
      />
      <BillingDialog
        label="bill"
        openDialog={openDialog}
        handleClose={handleCloseDialog}
      />
      <AskDialog
        label="ask"
        openDialog={openDialog}
        handleClose={handleCloseDialog}
      />
      <Dialog open={openSettings}
        onClose={handleCloseSe}
        maxWidth='lg'
      >
        <DialogTitle
          style={{
            background: "#026DF2",
            color: "white",
            fontFamily: 'Nunito',
            fontWeight: "700",
            fontSize: '16px'
          }}>
          SETTINGS / COLUMNS
        </DialogTitle>
        <DialogContent>
          <div>
            <Typography style={{
              fontFamily: 'Nunito',
              color: '#676666',
              fontSize: '14px',
              fontWeight: '700',
              marginTop: '20px',
              borderBottom: '1px solid #C5C3C3',
              paddingBottom: '6px'
            }}>
              Check the columns you want to be visible
            </Typography>
          </div>
          <div style={{ marginTop: '30px' }}></div>
          {role === "consignee" ?
            <Grid container spacing={2} columns={5} style={{
              borderBottom: '1px solid #C5C3C3',
              paddingTop: '45px',
              paddingBottom: '50px',
              paddingLeft: '40px',
              paddingRight: '40px'
            }}>
              <Grid item width={'200px'} style={{ borderRight: '1px solid #C5C3C3' }}>
                <div style={{ justifyContent: 'space-between', display: 'flex' }}>
                  <TypographyMUI style={{
                    fontFamily: 'Nunito',
                    fontSize: '14px',
                    fontWeight: '700',
                    color: '#000000'
                  }}>REFERENCE</TypographyMUI>
                  <Switch1 color="primary" checked={columns.reference}
                    onChange={(event) => handleColumnsArgs(event.target.checked, 'reference')}
                  />
                </div>
                <div style={{ justifyContent: 'space-between', display: 'flex' }}>
                  <TypographyMUI style={{
                    fontFamily: 'Nunito',
                    fontSize: '14px',
                    fontWeight: '700',
                    color: '#000000'
                  }}>STATUS</TypographyMUI>
                  <Switch1 color="primary" checked={columns.status}
                    onChange={(event) => handleColumnsArgs(event.target.checked, 'status')}
                  />
                </div>
                <div style={{ justifyContent: 'space-between', display: 'flex' }}>
                  <TypographyMUI style={{
                    fontFamily: 'Nunito',
                    fontSize: '14px',
                    fontWeight: '700',
                    color: '#000000'
                  }}>CONTAINER</TypographyMUI>
                  <Switch1 color="primary" checked={columns.container}
                    onChange={(event) => handleColumnsArgs(event.target.checked, 'container')}
                  />
                </div>
                <div style={{ justifyContent: 'space-between', display: 'flex' }}>
                  <TypographyMUI style={{
                    fontFamily: 'Nunito',
                    fontSize: '14px',
                    fontWeight: '700',
                    color: '#000000'
                  }}>TYPE</TypographyMUI>
                  <Switch1 color="primary" checked={columns.type}
                    onChange={(event) => handleColumnsArgs(event.target.checked, 'type')}
                  />
                </div>
                <div style={{ justifyContent: 'space-between', display: 'flex' }}>
                  <TypographyMUI style={{
                    fontFamily: 'Nunito',
                    fontSize: '14px',
                    fontWeight: '700',
                    color: '#000000'
                  }}>MOVE</TypographyMUI>
                  <Switch1 color="primary" checked={columns.moveType}
                    onChange={(event) => handleColumnsArgs(event.target.checked, 'moveType')}
                  />
                </div>
              </Grid>
              <Grid item width={'200px'} style={{ borderRight: '1px solid #C5C3C3' }}>
                <div style={{ justifyContent: 'space-between', display: 'flex' }}>
                  <TypographyMUI style={{
                    fontFamily: 'Nunito',
                    fontSize: '14px',
                    fontWeight: '700',
                    color: '#000000'
                  }}>ROUTE STATUS</TypographyMUI>
                  <Switch1 color="primary" checked={columns.routeStatus}
                    onChange={(event) => handleColumnsArgs(event.target.checked, 'routeStatus')}
                  />
                </div>
                <div style={{ justifyContent: 'space-between', display: 'flex' }}>
                  <TypographyMUI style={{
                    fontFamily: 'Nunito',
                    fontSize: '14px',
                    fontWeight: '700',
                    color: '#000000'
                  }}>FROM</TypographyMUI>
                  <Switch1 color="primary" checked={columns.from}
                    onChange={(event) => handleColumnsArgs(event.target.checked, 'from')}
                  />
                </div>
                <div style={{ justifyContent: 'space-between', display: 'flex' }}>
                  <TypographyMUI style={{
                    fontFamily: 'Nunito',
                    fontSize: '14px',
                    fontWeight: '700',
                    color: '#000000'
                  }}>TO</TypographyMUI>
                  <Switch1 color="primary" checked={columns.to}
                    onChange={(event) => handleColumnsArgs(event.target.checked, 'to')}
                  />
                </div>
                <div style={{ justifyContent: 'space-between', display: 'flex' }}>
                  <TypographyMUI style={{
                    fontFamily: 'Nunito',
                    fontSize: '14px',
                    fontWeight: '700',
                    color: '#000000'
                  }}>T&E</TypographyMUI>
                  <Switch1 color="primary" checked={columns.te}
                    onChange={(event) => handleColumnsArgs(event.target.checked, 'te')}
                  />
                </div>
                <div style={{ justifyContent: 'space-between', display: 'flex' }}>
                  <TypographyMUI style={{
                    fontFamily: 'Nunito',
                    fontSize: '14px',
                    fontWeight: '700',
                    color: '#000000'
                  }}>SCALE</TypographyMUI>
                  <Switch3 color="primary" checked={columns.scale}
                    onChange={(event) => handleColumnsArgs(event.target.checked, 'scale')}
                  />
                </div>
              </Grid>
              <Grid item width={'200px'} style={{ borderRight: '1px solid #C5C3C3' }}>
                <div style={{ justifyContent: 'space-between', display: 'flex' }}>
                  <TypographyMUI style={{
                    fontFamily: 'Nunito',
                    fontSize: '14px',
                    fontWeight: '700',
                    color: '#000000'
                  }}>SIZE</TypographyMUI>
                  <Switch2 color="primary" checked={columns.size}
                    onChange={(event) => handleColumnsArgs(event.target.checked, 'size')}
                  />
                </div>
                <div style={{ justifyContent: 'space-between', display: 'flex' }}>
                  <TypographyMUI style={{
                    fontFamily: 'Nunito',
                    fontSize: '14px',
                    fontWeight: '700',
                    color: '#000000'
                  }}>LFD</TypographyMUI>
                  <Switch2 color="primary" checked={columns.lfd}
                    onChange={(event) => handleColumnsArgs(event.target.checked, 'lfd')}
                  />
                </div>
                <div style={{ justifyContent: 'space-between', display: 'flex' }}>
                  <TypographyMUI style={{
                    fontFamily: 'Nunito',
                    fontSize: '14px',
                    fontWeight: '700',
                    color: '#000000'
                  }}>EMPTY NOTIFY</TypographyMUI>
                  <Switch2 color="primary" checked={columns.emptyNotify}
                    onChange={(event) => handleColumnsArgs(event.target.checked, 'emptyNotify')}
                  />
                </div>
                <div style={{ justifyContent: 'space-between', display: 'flex' }}>
                  <TypographyMUI style={{
                    fontFamily: 'Nunito',
                    fontSize: '14px',
                    fontWeight: '700',
                    color: '#000000'
                  }}>INBOND VIA</TypographyMUI>
                  <Switch2 color="primary" checked={columns.inbondVia}
                    onChange={(event) => handleColumnsArgs(event.target.checked, 'inbondVia')}
                  />
                </div>
                <div style={{ justifyContent: 'space-between', display: 'flex' }}>
                  <TypographyMUI style={{
                    fontFamily: 'Nunito',
                    fontSize: '14px',
                    fontWeight: '700',
                    color: '#000000'
                  }}>MBL</TypographyMUI>
                  <Switch2 color="primary" checked={columns.mbl}
                    onChange={(event) => handleColumnsArgs(event.target.checked, 'mbl')}
                  />
                </div>
              </Grid>
              <Grid item width={'200px'}>
                <div style={{ justifyContent: 'space-between', display: 'flex' }}>
                  <TypographyMUI style={{
                    fontFamily: 'Nunito',
                    fontSize: '14px',
                    fontWeight: '700',
                    color: '#000000'
                  }}>APPT. DATE</TypographyMUI>
                  <Switch2 color="primary" checked={columns.apptDate}
                    onChange={(event) => handleColumnsArgs(event.target.checked, 'apptDate')}
                  />
                </div>
                {/*<div style={{ justifyContent: 'space-between', display: 'flex' }}>
                  <TypographyMUI style={{
                    fontFamily: 'Nunito',
                    fontSize: '14px',
                    fontWeight: '700',
                    color: '#000000'
                  }}>APPT. NUMBER</TypographyMUI>
                  <Switch2 color="primary" checked={columns.apptNumber}
                    onChange={(event) => handleColumnsArgs(event.target.checked, 'apptNumber')}
                  />
                </div>*/}
                <div style={{ justifyContent: 'space-between', display: 'flex' }}>
                  <TypographyMUI style={{
                    fontFamily: 'Nunito',
                    fontSize: '14px',
                    fontWeight: '700',
                    color: '#000000'
                  }}>DRIVER</TypographyMUI>
                  <Switch2 color="primary" checked={columns.driver}
                    onChange={(event) => handleColumnsArgs(event.target.checked, 'driver')}
                  />
                </div>
                <div style={{ justifyContent: 'space-between', display: 'flex' }}>
                  <TypographyMUI style={{
                    fontFamily: 'Nunito',
                    fontSize: '14px',
                    fontWeight: '700',
                    color: '#000000'
                  }}>PLATE</TypographyMUI>
                  <Switch2 color="primary" checked={columns.plate}
                    onChange={(event) => handleColumnsArgs(event.target.checked, 'plate')}
                  />
                </div>
                <div style={{ justifyContent: 'space-between', display: 'flex' }}>
                  <TypographyMUI style={{
                    fontFamily: 'Nunito',
                    fontSize: '14px',
                    fontWeight: '700',
                    color: '#000000'
                  }}>ETA</TypographyMUI>
                  <Switch3 color="primary" checked={columns.eta}
                    onChange={(event) => handleColumnsArgs(event.target.checked, 'eta')}
                  />
                </div>
                <div style={{ justifyContent: 'space-between', display: 'flex' }}>
                  <TypographyMUI style={{
                    fontFamily: 'Nunito',
                    fontSize: '14px',
                    fontWeight: '700',
                    color: '#000000'
                  }}>REMARKS</TypographyMUI>
                  <Switch3 color="primary" checked={columns.remarks}
                    onChange={(event) => handleColumnsArgs(event.target.checked, 'remarks')}
                  />
                </div>
              </Grid>
            </Grid> : <Grid container spacing={2} columns={5} style={{
              borderBottom: '1px solid #C5C3C3',
              paddingTop: '45px',
              paddingBottom: '50px',
              paddingLeft: '40px',
              paddingRight: '40px'
            }}>
              <Grid item width={'200px'} style={{ borderRight: '1px solid #C5C3C3' }}>
                <div style={{ justifyContent: 'space-between', display: 'flex' }}>
                  <TypographyMUI style={{
                    fontFamily: 'Nunito',
                    fontSize: '14px',
                    fontWeight: '700',
                    color: '#000000'
                  }}>REFERENCE</TypographyMUI>
                  <Switch1 color="primary" checked={columns.reference}
                    onChange={(event) => handleColumnsArgs(event.target.checked, 'reference')}
                  />
                </div>
                <div style={{ justifyContent: 'space-between', display: 'flex' }}>
                  <TypographyMUI style={{
                    fontFamily: 'Nunito',
                    fontSize: '14px',
                    fontWeight: '700',
                    color: '#000000'
                  }}>STATUS</TypographyMUI>
                  <Switch1 color="primary" checked={columns.status}
                    onChange={(event) => handleColumnsArgs(event.target.checked, 'status')}
                  />
                </div>
                <div style={{ justifyContent: 'space-between', display: 'flex' }}>
                  <TypographyMUI style={{
                    fontFamily: 'Nunito',
                    fontSize: '14px',
                    fontWeight: '700',
                    color: '#000000'
                  }}>CONTAINER</TypographyMUI>
                  <Switch1 color="primary" checked={columns.container}
                    onChange={(event) => handleColumnsArgs(event.target.checked, 'container')}
                  />
                </div>
                <div style={{ justifyContent: 'space-between', display: 'flex' }}>
                  <TypographyMUI style={{
                    fontFamily: 'Nunito',
                    fontSize: '14px',
                    fontWeight: '700',
                    color: '#000000'
                  }}>TYPE</TypographyMUI>
                  <Switch1 color="primary" checked={columns.type}
                    onChange={(event) => handleColumnsArgs(event.target.checked, 'type')}
                  />
                </div>
                <div style={{ justifyContent: 'space-between', display: 'flex' }}>
                  <TypographyMUI style={{
                    fontFamily: 'Nunito',
                    fontSize: '14px',
                    fontWeight: '700',
                    color: '#000000'
                  }}>MOVE</TypographyMUI>
                  <Switch1 color="primary" checked={columns.moveType}
                    onChange={(event) => handleColumnsArgs(event.target.checked, 'moveType')}
                  />
                </div>
              </Grid>
              <Grid item width={'200px'} style={{ borderRight: '1px solid #C5C3C3' }}>
                <div style={{ justifyContent: 'space-between', display: 'flex' }}>
                  <TypographyMUI style={{
                    fontFamily: 'Nunito',
                    fontSize: '14px',
                    fontWeight: '700',
                    color: '#000000'
                  }}>ROUTE STATUS</TypographyMUI>
                  <Switch1 color="primary" checked={columns.routeStatus}
                    onChange={(event) => handleColumnsArgs(event.target.checked, 'routeStatus')}
                  />
                </div>
                <div style={{ justifyContent: 'space-between', display: 'flex' }}>
                  <TypographyMUI style={{
                    fontFamily: 'Nunito',
                    fontSize: '14px',
                    fontWeight: '700',
                    color: '#000000'
                  }}>CONSIGNEE</TypographyMUI>
                  <Switch1 color="primary" checked={columns.consignee}
                    onChange={(event) => handleColumnsArgs(event.target.checked, 'consignee')}
                  />
                </div>
                <div style={{ justifyContent: 'space-between', display: 'flex' }}>
                  <TypographyMUI style={{
                    fontFamily: 'Nunito',
                    fontSize: '14px',
                    fontWeight: '700',
                    color: '#000000'
                  }}>T&E</TypographyMUI>
                  <Switch1 color="primary" checked={columns.te}
                    onChange={(event) => handleColumnsArgs(event.target.checked, 'te')}
                  />
                </div>
                <div style={{ justifyContent: 'space-between', display: 'flex' }}>
                  <TypographyMUI style={{
                    fontFamily: 'Nunito',
                    fontSize: '14px',
                    fontWeight: '700',
                    color: '#000000'
                  }}>FROM</TypographyMUI>
                  <Switch1 color="primary" checked={columns.from}
                    onChange={(event) => handleColumnsArgs(event.target.checked, 'from')}
                  />
                </div>
                <div style={{ justifyContent: 'space-between', display: 'flex' }}>
                  <TypographyMUI style={{
                    fontFamily: 'Nunito',
                    fontSize: '14px',
                    fontWeight: '700',
                    color: '#000000'
                  }}>TO</TypographyMUI>
                  <Switch1 color="primary" checked={columns.to}
                    onChange={(event) => handleColumnsArgs(event.target.checked, 'to')}
                  />
                </div>
              </Grid>
              <Grid item width={'200px'} style={{ borderRight: '1px solid #C5C3C3' }}>
                <div style={{ justifyContent: 'space-between', display: 'flex' }}>
                  <TypographyMUI style={{
                    fontFamily: 'Nunito',
                    fontSize: '14px',
                    fontWeight: '700',
                    color: '#000000'
                  }}>SIZE</TypographyMUI>
                  <Switch2 color="primary" checked={columns.size}
                    onChange={(event) => handleColumnsArgs(event.target.checked, 'size')}
                  />
                </div>
                <div style={{ justifyContent: 'space-between', display: 'flex' }}>
                  <TypographyMUI style={{
                    fontFamily: 'Nunito',
                    fontSize: '14px',
                    fontWeight: '700',
                    color: '#000000'
                  }}>LFD</TypographyMUI>
                  <Switch2 color="primary" checked={columns.lfd}
                    onChange={(event) => handleColumnsArgs(event.target.checked, 'lfd')}
                  />
                </div>
                <div style={{ justifyContent: 'space-between', display: 'flex' }}>
                  <TypographyMUI style={{
                    fontFamily: 'Nunito',
                    fontSize: '14px',
                    fontWeight: '700',
                    color: '#000000'
                  }}>EMPTY NOTIFY</TypographyMUI>
                  <Switch2 color="primary" checked={columns.emptyNotify}
                    onChange={(event) => handleColumnsArgs(event.target.checked, 'emptyNotify')}
                  />
                </div>
                <div style={{ justifyContent: 'space-between', display: 'flex' }}>
                  <TypographyMUI style={{
                    fontFamily: 'Nunito',
                    fontSize: '14px',
                    fontWeight: '700',
                    color: '#000000'
                  }}>INBOND VIA</TypographyMUI>
                  <Switch2 color="primary" checked={columns.inbondVia}
                    onChange={(event) => handleColumnsArgs(event.target.checked, 'inbondVia')}
                  />
                </div>
                <div style={{ justifyContent: 'space-between', display: 'flex' }}>
                  <TypographyMUI style={{
                    fontFamily: 'Nunito',
                    fontSize: '14px',
                    fontWeight: '700',
                    color: '#000000'
                  }}>MBL</TypographyMUI>
                  <Switch2 color="primary" checked={columns.mbl}
                    onChange={(event) => handleColumnsArgs(event.target.checked, 'mbl')}
                  />
                </div>
              </Grid>
              <Grid item width={'200px'} style={{ borderRight: '1px solid #C5C3C3' }}>
                <div style={{ justifyContent: 'space-between', display: 'flex' }}>
                  <TypographyMUI style={{
                    fontFamily: 'Nunito',
                    fontSize: '14px',
                    fontWeight: '700',
                    color: '#000000'
                  }}>APPT. DATE</TypographyMUI>
                  <Switch2 color="primary" checked={columns.apptDate}
                    onChange={(event) => handleColumnsArgs(event.target.checked, 'apptDate')}
                  />
                </div>
                {/*<div style={{ justifyContent: 'space-between', display: 'flex' }}>
                  <TypographyMUI style={{
                    fontFamily: 'Nunito',
                    fontSize: '14px',
                    fontWeight: '700',
                    color: '#000000'
                  }}>APPT. NUMBER</TypographyMUI>
                  <Switch2 color="primary" checked={columns.apptNumber}
                    onChange={(event) => handleColumnsArgs(event.target.checked, 'apptNumber')}
                  />
                </div>*/}
                <div style={{ justifyContent: 'space-between', display: 'flex' }}>
                  <TypographyMUI style={{
                    fontFamily: 'Nunito',
                    fontSize: '14px',
                    fontWeight: '700',
                    color: '#000000'
                  }}>REMARKS</TypographyMUI>
                  <Switch3 color="primary" checked={columns.remarks}
                    onChange={(event) => handleColumnsArgs(event.target.checked, 'remarks')}
                  />
                </div>
                <div style={{ justifyContent: 'space-between', display: 'flex' }}>
                  <TypographyMUI style={{
                    fontFamily: 'Nunito',
                    fontSize: '14px',
                    fontWeight: '700',
                    color: '#000000'
                  }}>DRIVER</TypographyMUI>
                  <Switch2 color="primary" checked={columns.driver}
                    onChange={(event) => handleColumnsArgs(event.target.checked, 'driver')}
                  />
                </div>
                <div style={{ justifyContent: 'space-between', display: 'flex' }}>
                  <TypographyMUI style={{
                    fontFamily: 'Nunito',
                    fontSize: '14px',
                    fontWeight: '700',
                    color: '#000000'
                  }}>PLATE</TypographyMUI>
                  <Switch2 color="primary" checked={columns.plate}
                    onChange={(event) => handleColumnsArgs(event.target.checked, 'plate')}
                  />
                </div>
                <div style={{ justifyContent: 'space-between', display: 'flex' }}>
                  <TypographyMUI style={{
                    fontFamily: 'Nunito',
                    fontSize: '14px',
                    fontWeight: '700',
                    color: '#000000'
                  }}>FOLIO</TypographyMUI>
                  <Switch2 color="primary" checked={columns.folio}
                    onChange={(event) => handleColumnsArgs(event.target.checked, 'folio')}
                  />
                </div>
              </Grid>
              <Grid item width={'200px'}>
                <div style={{ justifyContent: 'space-between', display: 'flex' }}>
                  <TypographyMUI style={{
                    fontFamily: 'Nunito',
                    fontSize: '14px',
                    fontWeight: '700',
                    color: '#000000'
                  }}>SCALE</TypographyMUI>
                  <Switch3 color="primary" checked={columns.scale}
                    onChange={(event) => handleColumnsArgs(event.target.checked, 'scale')}
                  />
                </div>
                <div style={{ justifyContent: 'space-between', display: 'flex' }}>
                  <TypographyMUI style={{
                    fontFamily: 'Nunito',
                    fontSize: '14px',
                    fontWeight: '700',
                    color: '#000000'
                  }}>ETA</TypographyMUI>
                  <Switch3 color="primary" checked={columns.eta}
                    onChange={(event) => handleColumnsArgs(event.target.checked, 'eta')}
                  />
                </div>
                <div style={{ justifyContent: 'space-between', display: 'flex' }}>
                  <TypographyMUI style={{
                    fontFamily: 'Nunito',
                    fontSize: '14px',
                    fontWeight: '700',
                    color: '#000000'
                  }}>DAYS</TypographyMUI>
                  <Switch3 color="primary" checked={columns.days}
                    onChange={(event) => handleColumnsArgs(event.target.checked, 'days')}
                  />
                </div>
              </Grid>
            </Grid>}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseSe} style={{
            background: '#026DF2',
            color: '#FFFFFF',
            height: '25px'
          }}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={openLocation}
        onClose={() => {setOpenLocation(false); setLoadingMap(true);}}
        maxWidth='lg'
      >
        <DialogTitle
          style={{
            background: "#026DF2",
            color: "white",
            fontFamily: 'Nunito',
            fontWeight: "900",
            fontSize: '16px',
            display: 'inline-flex',
            justifyContent: 'space-between',
            padding: '8px 24px'
          }}>
          {!loadingMap ? (<div style={{
            textTransform: 'uppercase'
          }}>
            {`DRIVER MOVE: ${open.from} - ${open.to}`}
          </div>) : <div/>}
          <Button onClick={() => {setOpenLocation(false); setLoadingMap(true);}} style={{
            background: '#FFFFFF',
            color: '#026DF2',
            height: '25px'
          }}>
            Close
          </Button>
        </DialogTitle>
        <DialogContent>
          <div style={{
            width: loadingMap ? '250px' : '1000px',
            marginTop: '16px'
          }}>
            <Grid container>
              { !loadingMap && (<Grid item xs style={{
                marginRight: '24px',
                marginTop: '24px'
              }}>
                <div style={{
                  fontSize: '14px',
                  fontFamily: 'Nunito',
                  fontWeight: '800',
                  color: '#026DF2',
                  borderBottom: '1px solid #026DF2',
                  paddingBottom: '4px',
                  paddingLeft: '8px'
                }}>
                  VEHICLE:
                </div>

                <div style={{
                  display: 'inline-flex',
                  marginTop: '10px'
                }}>
                  <div style={{
                    width: '70px',
                    textAlign: 'end',
                    marginRight: '12px',
                    fontSize: '13px',
                    fontWeight: '700',
                    fontFamily: 'Nunito',
                    color: '#8602B5'
                  }}>
                    NAME:
                  </div>
                  <div style={{
                    width: '275PX',
                    marginRight: '12px',
                    fontSize: '13px',
                    fontWeight: '700',
                    fontFamily: 'Nunito',
                    color: '#676666'
                  }}>
                    {get(dataLocation, 'device.name')}
                  </div>
                </div>
                <div style={{
                  display: 'inline-flex',
                  marginTop: '10px'
                }}>
                  <div style={{
                    width: '70px',
                    textAlign: 'end',
                    marginRight: '12px',
                    fontSize: '13px',
                    fontWeight: '700',
                    fontFamily: 'Nunito',
                    color: '#8602B5'
                  }}>
                    LOCATION:
                  </div>
                  <div style={{
                    width: '275PX',
                    marginRight: '12px',
                    fontSize: '13px',
                    fontWeight: '700',
                    fontFamily: 'Nunito',
                    color: '#676666'
                  }}>
                    {get(dataLocation, 'address.formattedAddress')}
                  </div>
                </div>
                {/*<div style={{
                  display: 'inline-flex',
                  marginTop: '10px'
                }}>
                  <div style={{
                    width: '70px',
                    textAlign: 'end',
                    marginRight: '12px',
                    fontSize: '13px',
                    fontWeight: '700',
                    fontFamily: 'Nunito',
                    color: '#8602B5'
                  }}>
                    ZONES:
                  </div>
                  <div style={{
                    width: '275PX',
                    marginRight: '12px',
                    fontSize: '13px',
                    fontWeight: '700',
                    fontFamily: 'Nunito',
                    color: '#676666'
                  }}>
                    RUTA SAN DIEGO - LOS ANGELES
                  </div>
                </div>
                <div style={{
                  display: 'inline-flex',
                  marginTop: '10px'
                }}>
                  <div style={{
                    width: '70px',
                    textAlign: 'end',
                    marginRight: '12px',
                    fontSize: '13px',
                    fontWeight: '700',
                    fontFamily: 'Nunito',
                    color: '#8602B5'
                  }}>
                    STATUS:
                  </div>
                  <div style={{
                    width: '275PX',
                    marginRight: '12px',
                    fontSize: '13px',
                    fontWeight: '700',
                    fontFamily: 'Nunito',
                    color: '#676666'
                  }}>
                      Driving since 04/17/23 08:23:09 am
                  </div>
                </div>*/}
                <div style={{
                  display: 'inline-flex',
                  marginTop: '10px'
                }}>
                  <div style={{
                    width: '70px',
                    textAlign: 'end',
                    marginRight: '12px',
                    fontSize: '13px',
                    fontWeight: '700',
                    fontFamily: 'Nunito',
                    color: '#8602B5'
                  }}>
                    SPEED:
                  </div>
                  <div style={{
                    width: '275PX',
                    marginRight: '12px',
                    fontSize: '13px',
                    fontWeight: '700',
                    fontFamily: 'Nunito',
                    color: '#676666'
                  }}>
                    {get(dataLocation, 'status.speed')} km/h
                  </div>
                </div>
                <div style={{
                  fontSize: '14px',
                  fontFamily: 'Nunito',
                  fontWeight: '800',
                  color: '#026DF2',
                  borderBottom: '1px solid #026DF2',
                  paddingBottom: '4px',
                  paddingLeft: '8px',
                  marginTop: '32px'
                }}>
                  DRIVER:
                </div>
                <div style={{
                  display: 'inline-flex',
                  marginTop: '10px'
                }}>
                  <div style={{
                    width: '70px',
                    textAlign: 'end',
                    marginRight: '12px',
                    fontSize: '13px',
                    fontWeight: '700',
                    fontFamily: 'Nunito',
                    color: '#8602B5'
                  }}>
                    NAME:
                  </div>
                  <div style={{
                    width: '275PX',
                    marginRight: '12px',
                    fontSize: '13px',
                    fontWeight: '700',
                    fontFamily: 'Nunito',
                    color: '#676666'
                  }}>
                    {get(dataLocation, 'driver.firstName')} {' '} {get(dataLocation, 'driver.lastName')}
                  </div>
                </div>
                <div style={{
                  display: 'inline-flex',
                  marginTop: '10px'
                }}>
                  <div style={{
                    width: '70px',
                    textAlign: 'end',
                    marginRight: '12px',
                    fontSize: '13px',
                    fontWeight: '700',
                    fontFamily: 'Nunito',
                    color: '#8602B5'
                  }}>
                    CONTACT:
                  </div>
                  <div style={{
                    width: '275PX',
                    marginRight: '12px',
                    fontSize: '13px',
                    fontWeight: '700',
                    fontFamily: 'Nunito',
                    color: '#676666'
                  }}>
                    {get(dataLocation, 'driver.name')}
                  </div>
                </div>
              </Grid>)}
              <Grid item style={{width: '605px', margin: '8px 0px'}}>
                <Map/>
              </Grid>
            </Grid>

          </div>
        </DialogContent>
      </Dialog>
    </>
  );
}

Table.propTypes = {};
