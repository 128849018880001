import { Box, Button, Typography } from '@mui/material';
import React, { Fragment, useContext } from 'react';
import { AuthContext } from '../../AppContext/AuthContext';
import logo from '../../Assets/Icons/Logo.png';
import { Google } from '@mui/icons-material';
import Loginv2 from "../../Assets/Icons/Loginv2.svg"
import "@fontsource/nunito";
import "@fontsource/roboto";

function SignIn() {
    const { login } = useContext(AuthContext);

    return (
        <Fragment>
            <div style={{background:'#FFFFFF',
            height: '100vh'
            }}>
            <div style={{
                display:'flex',
                justifyContent:'center',
                alignItems: 'end',
                height: '45vh',
            }}>
            <Typography style={{
                color:"#DC132B", 
                fontWeight:'800', 
                fontStyle: 'normal',
                fontSize:'128px',
                fontFamily:'Nunito',
                lineHeight:'175px',
                top:'25px',
                position:'relative'
            }}>WELCOME!</Typography>
            </div>
            <div style={{
                display:'flex',
                background:'#989595',
                width: '100%',
                justifyContent:'center',
                alignItems:'center'
            }}>
                <Typography style={{
                color:"#FFFFFF", 
                fontFamily:'Nunito',
                fontStyle: 'italic',
                fontWeight: '400',
                fontSize: '32px',
                height:'44px',
                marginRight:'20px'
            }}>CUSTOMER PORTAL BY </Typography>
            <div style={{background:'#FFFFFF'}}>
             <img alt='Logo' src={logo} style={{
                height: '40px'
             }}/>
            </div>
            </div>

            <div style={{
                display:'flex',
                justifyContent:'center',
                alignItems:'end',
                height: '10vh'
            }}>
            <Typography style={{
                color: '#6A6767',
                fontFamily:'Roboto',
                fontStyle:'normal',
                fontSize:'12px'
            }}>
            Enter your email to identify yourself</Typography>    
            </div> 

            <Box  
            sx={{ p: 2 }}
            style={{
                display:'flex',
                justifyContent:'center',
                alignItems:'end',
                }}>
            <div style={{
                display:'flex',
                justifyContent:'center',
                alignItems:'end',
            }}>
             <Button variant="outlined" 
             style={{
                color:'#C5C3C3', 
                borderColor:'#676666'}} 
             onClick={ login } 
             >
            <Google style={{
            position:'relative',
            left:'-10px',
            color: "#DC132B",
            }}/>
            <div style={{ 
                borderLeft: '2px solid #676666', 
                height: '20px', 
                marginLeft:'1px', 
                marginRight:'10px' 
            }}/>
            <Typography
            style={{
                fontSize:'10px', 
                marginRight:'80px', 
                marginLeft:'8px',
                marginTop: '2px',
                fontFamily:'Roboto',
                fontStyle:'normal'
                }}>
             LOGIN WITH GOOGLE   
            </Typography>
            <img alt='Login' 
             src={Loginv2} 
             style={{
                position:'relative',
                right:'-10px',
                fill:'#DC132B',
                marginLeft:'10px',
             }}/>
             </Button>   
            </div>   
            </Box>
            </div>  
        </Fragment>
        
    );

    
}

export default SignIn;