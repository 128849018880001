import React, { useEffect, useState } from 'react';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    Grid,
} from "@mui/material";
import PropTypes from 'prop-types';
import { DialogTitle, DialogTabButton } from '../styled';
import useDialogHooks from './hooks';
import Header from './containers/header';
import FileNoteRequest from '../../../../../../components/FileNoteRequest';
import LoadingIndicator from '../../../../../../components/Loading';
import { get } from 'lodash';


function BillingDialog({ openDialog, label, handleClose }) {
    const {
        dialogTabOptions,
        dialogTab,
        handleDialogTab,
        openDetail,
        handleOpenDetail,
        detailTab,
        handleDetailTab,
        handleCloseDialog,
        loading,
        open,
    } = useDialogHooks({ handleClose });

    console.log(open)

    return (
        <Dialog
            open={openDialog === label}
            onClose={handleClose}
            maxWidth="md"
            fullWidth>
            <DialogTitle padding={'0 0 0 10px'}>
                {dialogTabOptions.map(element => {
                        if(element.id === "0" && (get(open.invoice, "prefix") !== "INV" && get(open.invoice, "prefix") !== "BES")) {
                            return (<div></div>);
                        }
                        if(element.id === "1" && get(open, "consignmentNotes", []).length === 0) {
                            return (<div></div>);
                        }
                        return <DialogTabButton
                            key={element.id}
                            active={element.id === dialogTab}
                            onClick={() => handleDialogTab(element.id)}
                            >{element.name}</DialogTabButton>
                        }
                    )
                }
            </DialogTitle>
            {loading && <DialogContent style={{ padding: '0 0 10px' }}>
                <LoadingIndicator />
            </DialogContent>}
            {!loading && <DialogContent>
                <Grid container justifyContent={'center'} style={{ marginTop: '20px' }}>
                    {dialogTab === "0" && <Header handleOpenDetail={handleOpenDetail} canOpenDetail cp={open.consignmentNotes} invoice={open.invoice} index={"0"} />}
                    {dialogTab === "1" && <Header handleOpenDetail={handleOpenDetail} canOpenDetail cp={open.consignmentNotes} invoice={open.invoice} index={"1"} />}
                    {openDetail && <FileNoteRequest 
                        file
                        note
                        request
                        detailTab={dialogTab}
                        handleDetailTab={handleDetailTab}
                        cp={open.consignmentNotes} 
                        invoice={open.invoice}
                    />}
                </Grid>
            </DialogContent>}
            <DialogActions style={{borderTop: '1px solid #676666'}}>
                <Button
                    onClick={handleCloseDialog}
                    variant={'contained'}
                    style={{ fontWeight: "900" }}
                >
                    Close
                </Button>
            </DialogActions>
        </Dialog>);
}

BillingDialog.propTypes = {
    openDialog: PropTypes.string,
    label: PropTypes.string,
    handleClose: PropTypes.func,

};
export default BillingDialog;